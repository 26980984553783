import './styles/license-card.css';
import { useRef, useState } from 'react';
import Button from './Button';
import Global from '../objects/Global';
import License from '../objects/License';
import UIRender from '../objects/UIRender';
import PushButton from './PushButton';
// Icons.
import * as Icons from '../assets/images';
import Estate from '../objects/Estate';

/** The License Card compo.
 * @typedef {import('../objects/License').default} License
 * @param {Object} props The props object.
 * @param {import ('../objects/License').default} [props.license] A License instance. If undefined,
 * a card in wait state will be rendered.
 * @param {boolean} [props.showGetButton]
 * @param {boolean} [props.reduced] If true, compo will be reduced and features and buttons won't
 * be displayed.
 */
const LicenseCard = props => {
  // *** useRef ***
  /** @type {React.LegacyRef<HTMLDivElement>} */
  const features = useRef();
  // *** useState ***
  const [showFeatures, setShowFeatures] = useState(false);

  /** @type {React.AnimationEventHandler<HTMLDivElement>} */
  const featuresHandleOnEndAnimation = e => {
    if (e.target === features.current) {
      if (!UIRender.isHidden(features.current)) features.current.classList.remove('init');
      else setShowFeatures(false);
    }
  }

  /** Obtains the button class name for the LicCard compo. */
  const getBtnClassName = () => {
    const className = props.license?.getClassName();

    if (className === 'lmted' || className === 'cust1') return ' dark-glass';
    else return ' glass';
  }

  const renderPrice = () => {
    let price = props.license.getPrice(), discount = false, compos = [];

    if (props.license.getClassName() === 'betas') price = 'Exclusivo';
    else if (price === 0) price = 'Gratis';
    else if (props.license.getDiscount() > 0) {
      discount = true;
      price = `$ ${Global.formatNumber(price - price * props.license.getDiscount())} MXN al mes`
    } else price = `$ ${Global.formatNumber(price)} MXN al mes`;

    if (discount)
      compos.push(<span className="p-price" key="p-price">
        {`$ ${props.license.getPrice()} MXN al mes`}
      </span>);
    else if (props.license.getClassName().substring(0, 4) === 'cust')
      compos.push(<span className='cust' key='cust'>A partir de</span>)

    compos.push(<span className="price" key="price">{price}</span>)

    return compos;
  }

  const pushBtnHandleOnClick = e => {
    if (showFeatures) UIRender.hideElement(features.current);
    else setShowFeatures(true);
  }

  const renderFeatures = () => {
    const ftrs = props.license.getFeatures();
    const keys = Object.keys(ftrs);

    return (<ul>
      {keys.map(k => {
        if (!ftrs[k] || ftrs[k] < -2) return undefined;

        switch (k) {
          case 'bidding': { // Bidding allowed?
            return <li key={k}>Subasta de propiedades</li>
          } case 'max_les': { // Max lessees per agreement
            if (ftrs[k] === License.VALUE_CUSTOM)
              return <li key={k}>Arrendatarios por contrato personalizado</li>
            else if (ftrs[k] === License.VALUE_UNLIMITED)
              return <li key={k}>Arrendatarios por contrato sin límite</li>
            else
              return <li key={k}>Hasta {ftrs[k]} arrendatario{ftrs[k] > 1 ? 's' : ''} por contrato</li>
          } case 'max_pub': { // Max publications (max agreements = max publications * max sub estates)
            if (ftrs[k] === License.VALUE_CUSTOM)
              return <li key={k}>No. de propiedades personalizado</li>
            else if (ftrs[k] === License.VALUE_UNLIMITED)
              return <li key={k}>Propiedades sin límite</li>
            else
              return <li key={k}>Hasta {ftrs[k]} propiedad{ftrs[k] > 1 ? 'es' : ''}</li>
          } case 'max_sub_acc': {
            if (ftrs[k] === License.VALUE_CUSTOM)
              return <li key={k}>Cuentas empresa personalizado</li>
            else if (ftrs[k] === License.VALUE_UNLIMITED)
              return <li key={k}>Cuentas empresa sin límite</li>
            else
              return <li key={k}>{ftrs[k]} cuenta{ftrs[k] > 1 ? 's' : ''} empresa</li>
          } case 'max_sub_div': {
            if (ftrs[k] === License.VALUE_CUSTOM)
              return <li key={k}>Subdivisiones por propiedad personalizado</li>
            else if (ftrs[k] === License.VALUE_UNLIMITED)
              return <li key={k}>Hasta {Estate.MAX_SPACES} subdivisiones por propiedad</li>
            else
              return <li key={k}>Hasta {ftrs[k]} subdivisione{ftrs[k] > 1 ? 's' : ''} por propiedad</li>
          } default: return undefined
        }
      })}
    </ul>);
  }

  if (!props.license) return (<div className={`lic-card ${props.reduced ? ' reduced' : ''} waiting`}>
    <span />
  </div>);
  else return (
    <div className={`lic-card ${props.license.getClassName()}${props.reduced ? ' reduced' : ''}`}>
      {/* Discount banner */}
      {!props.reduced && props.license.getDiscount() > 0 && props.license.getPrice() > 0 &&
        <div className="discount-banner">
          {`${props.license.getDiscount() * 100}% DTO.`}
        </div>
      }
      {/* Header info */}
      <h2 className='title'>{props.license.getTitle() || 'Licencia personalizada'}</h2>
      {!props.reduced && <div className="sub-info">
        <span className="description">{props.license.getDescription()}</span>
        <div className="prices">{renderPrice()}</div>
      </div>}
      {props.reduced && <div className="reduced-placeholder">
        <span className="card-number">R4CC H0M3 Y0UR 411Y</span>
        <span className="exp">MEMBER</span>
      </div>}
      {/* Sub features */}
      {!props.reduced && showFeatures &&
        <div ref={features} className="sub-features init" onAnimationEnd={featuresHandleOnEndAnimation}>
          {renderFeatures()}
          <span className="wrapper" />
        </div>
      }
      {!props.reduced && <PushButton
        className={`empty borderless${getBtnClassName()}`}
        img={Icons.ArrowHeadIcon}
        value='Ver más'
        onClick={pushBtnHandleOnClick}
        onPushedClassName='rotate-img-180'
        onPushedValue='Ver menos'
        stopPropagation={true} />}
      {!props.reduced && props.showGetButton && <Button
        borderless
        className={getBtnClassName()}
        empty
        icon={Icons.PayIcon}
        value='Adquirir' />}
    </div>
  );
}

export default LicenseCard;