import Global from './Global';

/** InertObject typedef
 * @typedef {Object} InertObject
 * @property {string} id The element's id or classname (unique).
 * @property {number} requests Number of times of inert requests. It must not be less than zero
 * @property {Element} [element] The element.
 */

/** A class with a collection of functions related to UI rendering. All functions are static,
 * so UIRender won't need an instance to work. 'getElement', 'getElementById' and 'getElements'
 * are located at Global class.
 */
class UIRender {
  /** Container id for inert requests */
  static get INERT_CONTAINER() { return 'container' };
  /** Footer id for inert requests */
  static get INERT_FOOT() { return 'foot' };
  /** Navbar id for inert requests */
  static get INERT_NAVBAR() { return 'navbar' };
  /** @type {string[]} */
  static globalScrollRequests = [];
  /** @type {InertObject[]} */
  static inertRequests = [];

  /** Adds inert attribute to given element.
   * @param {Element} element 
   */
  static addInert(element) {
    if (element && !element.hasAttribute('inert')) element.setAttribute('inert', '');
  }

  /** Removes inert attribute from given element.
   * @param {Element} element 
   */
  static removeInert(element) {
    if (element) element.removeAttribute('inert');
  }

  /** Removes focus from DOM. */
  static blurFocus() {
    document.activeElement.blur();
  }

  /** Disables global scroll from the web page.
   * @param {string} id - Element's id that requested global scroll to be disabled. Can be undefined.
   */
  static disableGlobalScroll(id) {
    if (id) {
      if (!UIRender.globalScrollRequests.includes(id)) {
        UIRender.globalScrollRequests.push(id);
        document.body.style.overflow = 'hidden';
      }
    } else {
      document.body.style.overflow = 'hidden';
    }
  }

  /** Disables siblins (if exists) from given element. Adds inert attribute and a
   * requests counter to found elements. 'container', 'footer' and 'navbar' wont't be inerted if they are
   * siblings from given element. They must be specified in options object to be disabled.
   * @param {Element} element An element from DOM.
   * @param {{ container?: boolean, exceptions?: Element[], footer?:boolean, navbar?:boolean }} options
   * An object of options. Every option set to true will be disabled.
   */
  static disableSiblings(element, options) {
    if (!element || !(element instanceof Element)) return;

    const childs = element.parentElement?.children;

    for (let i = 0; i < childs.length; i++) {
      const isException = options?.exceptions?.find(e => e === childs[i]);
      const isSuperParent = !isException &&
        (childs[i].classList.contains(UIRender.INERT_CONTAINER)
          || childs[i].classList.contains(UIRender.INERT_FOOT)
          || childs[i].classList.contains(UIRender.INERT_NAVBAR));

      if (!isSuperParent && !isException && childs[i] !== element) {
        const request = UIRender.inertRequests.find(iR => iR.element === childs[i]);

        if (!request) {
          UIRender.inertRequests.push({
            id: childs[i].id || childs[i].className || undefined,
            requests: 1,
            element: childs[i]
          });

          UIRender.addInert(childs[i]);
        } else request.requests++;
      }
    }

    // Container, footer and navbar disablement.
    if (options && (options.container || options.footer || options.navbar)) {
      const keys = Object.keys(options);

      for (let i = 0; i < keys.length; i++) {
        let id, className;

        if (options[keys[i]]) {
          switch (keys[i]) {
            case 'container': {
              id = UIRender.INERT_CONTAINER;
              className = 'div.container';
              break;
            } case 'footer': {
              id = UIRender.INERT_FOOT;
              className = 'footer.foot';
              break;
            } case 'navbar': {
              id = UIRender.INERT_NAVBAR;
              className = 'div.navbar';
              break;
            } default: { }
          }
        }

        if (id) {
          const request = UIRender.inertRequests.find(iR => iR.id === id);

          if (!request) {
            UIRender.inertRequests.push({ id, requests: 1 });
            UIRender.addInert(Global.getElement(className));
          } else request.requests++;
        }
      }
    }
  }

  /** A function that calls remove inert attribute for each child it has. Use this function after
   * one of its childs has called 'Global.disableSiblings() and it's now unrendered (useful to
   * call in a return function inside a useEffect).
   * @param {Element} element The parent element.
   * @param {{ container?: boolean, exceptions?: Element[], footer?: boolean, navbar?: boolean }} options
   * The options object. Every element set to true will be requested to be enabled.
   */
  static enableChilds = (element, options) => {
    if (!element || !(element instanceof Element)) return;

    const childs = element.children;

    for (let i = 0; i < childs.length; i++) {
      const isException = options?.exceptions?.find(e => e === childs[i]);
      const isSuperParent = !isException &&
        (childs[i].classList.contains(UIRender.INERT_CONTAINER)
          || childs[i].classList.contains(UIRender.INERT_FOOT)
          || childs[i].classList.contains(UIRender.INERT_NAVBAR));

      if (!isSuperParent && !isException) {
        const index = UIRender.inertRequests.findIndex(iR => iR.element === childs[i]);

        if (index !== -1) {
          UIRender.inertRequests[index].requests--;

          if (UIRender.inertRequests[index].requests <= 0) {
            UIRender.removeInert(childs[i]);
            UIRender.inertRequests.splice(index, 1);
          }
        }
      }
    }

    // Container, footer and navbar enablement.
    if (options && (options.container || options.footer || options.navbar)) {
      const keys = Object.keys(options);

      for (let i = 0; i < keys.length; i++) {
        let id = '', className = '';

        if (options[keys[i]]) {
          switch (keys[i]) {
            case 'container': {
              id = UIRender.INERT_CONTAINER;
              className = 'div.container';
              break;
            } case 'footer': {
              id = UIRender.INERT_FOOT;
              className = 'footer.foot';
              break;
            } case 'navbar': {
              id = UIRender.INERT_NAVBAR;
              className = 'div.navbar';
              break;
            } default: { }
          }
        }

        if (id) {
          const index = UIRender.inertRequests.findIndex(iR => iR.id === id);

          if (index !== -1) {
            UIRender.inertRequests[index].requests--;

            if (UIRender.inertRequests[index].requests <= 0) {
              UIRender.inertRequests.splice(index, 1);
              UIRender.removeInert(Global.getElement(className));
            }
          }
        }
      }
    }
  }

  /** Enables global scroll from the web page.
   * @param {string} id - Element's id that requested global scroll to be enabled. Can be undefined.
   * Global scroll will be enabled if UIRender.globalScrollQueue is empty.
   */
  static enableGlobalScroll(id) {
    if (id) {
      const pos = UIRender.globalScrollRequests.indexOf(id);

      if (pos !== -1) UIRender.globalScrollRequests.splice(pos, 1);
    }

    if (!UIRender.globalScrollRequests.length) document.body.style.overflow = 'auto';
  }

  /** Enables siblins (if exists) from given element. Removes inert attribute to found elements.
   * container, footer and navbar wont't be enabled if they are siblings from given element. Must be
   * specified in options object to be enabled.
   * @param {Element} element An element from DOM.
   * @param {{ container?: boolean, exceptions?: Element[], footer?: boolean, navbar?: boolean }} options
   * The options object.
   */
  static enableSiblings(element, options) {
    if (!element || !(element instanceof Element)) return;

    const childs = element.parentElement?.children;

    for (let i = 0; i < childs.length; i++) {
      const isException = options?.exceptions?.find(e => e === childs[i]);
      const isSuperParent = !isException &&
        (childs[i].classList.contains(UIRender.INERT_CONTAINER)
          || childs[i].classList.contains(UIRender.INERT_FOOT)
          || childs[i].classList.contains(UIRender.INERT_NAVBAR));

      if (!isSuperParent && !isException && childs[i] !== element) {
        const index = UIRender.inertRequests.findIndex(iR => iR.element === childs[i]);

        if (index !== -1) {
          UIRender.inertRequests[index].requests--;

          if (UIRender.inertRequests[index].requests <= 0) {
            UIRender.removeInert(childs[i]);
            UIRender.inertRequests.splice(index, 1);
          }
        }
      }
    }

    // Container, footer and navbar enablement.
    if (options && (options.container || options.footer || options.navbar)) {
      const keys = Object.keys(options);

      for (let i = 0; i < keys.length; i++) {
        let id = '', className = '';

        if (options[keys[i]]) {
          switch (keys[i]) {
            case 'container': {
              id = UIRender.INERT_CONTAINER;
              className = 'div.container';
              break;
            } case 'footer': {
              id = UIRender.INERT_FOOT;
              className = 'footer.foot';
              break;
            } case 'navbar': {
              id = UIRender.INERT_NAVBAR;
              className = 'div.navbar';
              break;
            } default: { }
          }
        }

        if (id) {
          const index = UIRender.inertRequests.findIndex(iR => iR.id === id);

          if (index !== -1) {
            UIRender.inertRequests[index].requests--;

            if (UIRender.inertRequests[index].requests <= 0) {
              UIRender.inertRequests.splice(index, 1);
              UIRender.removeInert(Global.getElement(className));
            }
          }
        }
      }
    }
  }

  /** Sets 'hide' attribute to given element.
   * @param {Element} element - An element from DOM.
   */
  static hideElement(element) {
    if (element && !element.hasAttribute('hide')) {
      element.setAttribute('hide', '');
    }
  }

  /** Verifies if an HTMLElement is hidden (has 'hide' attribute).
   * @param {Element} element 
   * @returns {boolean} true if element is hidden.
   */
  static isHidden(element) {
    return element && element.hasAttribute('hide');
  }

  /** Calls window.location.reload() function */
  static reloadPage() {
    window.location.reload();
  }

  /** Scroll to a required page place.
   * @param {number} [x] x position. 0 by default.
   * @param {number} [y] y position. 0 by default.
   * @param {Element} [e] An element wich the scroll will be applied. If undefined, scroll will
   * be applied to window.
   */
  static scrollTo(x, y, e) {
    if (e !== undefined) e.scrollTo(x || 0, y || 0);
    else window.scrollTo(x || 0, y || 0);
  }

  /** Selects an element text content (useful for input texts).
   * @param {HTMLInputElement} inputElement - A input element from DOM.
   */
  static selectElementText(inputElement) {
    if (inputElement) {
      inputElement.select();
      inputElement.setSelectionRange(0, 99999); // For mobile devices    
    }
  }

  /** Clears text selection from page. */
  static clearTextSelection() {
    window.getSelection()?.removeAllRanges();
  }

  /** Sets focus on the requested element.
   * @param {HTMLElement} element - An element from DOM.
   */
  static setFocus(element) {
    if (element?.focus) element.focus();
  }

  /** Removes 'hide' attribute from the given element.
   * @param {Element} element - An element from DOM.
   */
  static showElement(element) {
    if (element && element.hasAttribute('hide')) {
      element.removeAttribute('hide');
    }
  }
}

export default UIRender;