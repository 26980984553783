import Address from "./Address";
import Bidding from "./Bidding";
import Contract from "./Contract";
import Global from "./Global";
import Persona from "./Persona";
import PSCollection from './PSCollection';
import Rate from "./Rate";
import User from './User';

/** TypeObject typedef
 * @typedef {Object} TypeObject
 * @property {string} displayName The name to be displayed on UI.
 * @property {string} propDisplay The static property name.
 * @property {number} value The static property value,
 */

/** Estate class definition */
class Estate extends PSCollection {
  /** Max description string size, being 1000. */
  static get MAX_DESC_LENGTH() { return 1000 }
  /** Min description string size, being 20. */
  static get MIN_DESC_LENGTH() { return 20 }
  /** Max insuranse string size, being 50. */
  static get MAX_INSURANCE_LENGTH() { return 50 }
  /** Max images that can be uploaded */
  static get MAX_IMAGES() { return 10 }
  /** Max spaces, being 1000 */
  static get MAX_SPACES() { return 1000 }
  /** Min images that can be uploaded. */
  static get MIN_IMAGES() { return 3 }
  /** Max tag / requirement string size, being 30. */
  static get MAX_REQ_TAQ_LENGTH() { return 30 }
  /** Max title string size, being 100. */
  static get MAX_TITLE_LENGTH() { return 70 }
  /** Max requirements */
  static get MAX_REQUIREMENTS() { return 20 }
  /** Min title string size, being 10. */
  static get MIN_TITLE_LENGTH() { return 10 }
  /** A Sell Method. Specifies estate is for lease. */
  static get ON_LEASE() { return 1 }
  /** A Sell Method. Specifies estate is for sale. */
  static get ON_SALE() { return 2 }
  /** A sell method. Specifies estate will be on lease/sale per divisions. */
  static get ON_COMPLEX() { return 3 }
  /** A default value from 'Estate.getStatus()'. Equals to -1. */
  static get STATUS_SUS() { return -1 }
  /** A default value from 'Estate.getStatus()'. Equals to 0. */
  static get STATUS_INA() { return 0 }
  /** A default value from 'Estate.getStatus()'. Equals to 1. */
  static get STATUS_ACT() { return 1 }
  /** Estate's type is not specified or contemplated. */
  static get TYPE_OTHER() { return 1 }
  /** Specifies the estate is a house. */
  static get TYPE_HOUSE() { return 2 }
  /** Specifies the estate is a deparment. */
  static get TYPE_DEPARTMENT() { return 3 }
  /** Specifies the estate is an office, a room or a premises. */
  static get TYPE_OFFICE() { return 4 }
  /** Specifies the estate is an event hall. */
  static get TYPE_EVENT_HALL() { return 5 }
  /** Specifies the estate is a depot. */
  static get TYPE_DEPOT() { return 6 }
  /** Specifies the estate is a building. */
  static get TYPE_BUILDING() { return 7 }
  /** Specifies the estate is a hotel / motel. */
  static get TYPE_HOTEL() { return 8 }
  /** Specifies the estate is a terrain or a lot. */
  static get TYPE_TERRAIN() { return 9 }
  /** Specifies the estate is a black work or gray work. */
  static get TYPE_BLACK_WORK() { return 10 }

  /** @param {Estate} [object] An Estate instance to be copied */
  constructor(object) {
    super(object);

    const {
      bidding,
      buildDate,
      complex,
      contactInfo,
      contract,
      creator,
      description,
      freeSpaces,
      images,
      insurance,
      location,
      owner,
      rate,
      requirements,
      sellMethod,
      size,
      status,
      title,
      totalSpaces,
      type
    } = object || {};

    this.setBidding(new Bidding(bidding));
    this.setBuildDate(buildDate);
    this.setComplex(complex instanceof Estate ? new Estate(complex) : undefined);
    this.setContactInfo(new Persona(contactInfo));
    this.setContract(new Contract(contract));
    this.setCreator(creator ? new User(creator) : undefined);
    this.setDescription(description);
    this.setImages(Array.isArray(images) ? [...images] : undefined);
    this.setInsurance(insurance);
    this.setLocation(new Address(location));
    this.setOwner(owner ? new User(owner) : undefined);
    this.setRate(rate ? new Rate(rate) : undefined);
    this.setRequirements(Array.isArray(requirements) ? [...requirements] : []);
    this.setSize({ ...size });
    this.setStatus(status);
    this.setTitle(title);
    this.setTotalSpaces(totalSpaces);
    this.setType(type);
    this.setFreeSpaces(freeSpaces);
    this.setSellMethod(sellMethod);
  }

  // ************************************************************************************ STATIC FUNCTIONS *
  /** Obtains contract duration details formated in text. sellMethod must be defined. If sellMethod
   * equals to Estate.ON_LEASE, contract's term and term method must be defined. Returned string
   * will be a string that contains term and term method values. If sellMethod equals to Estate.ON_SALE,
   * 'Trato directo' will be returend. If sell method equals to Estate.ON_COMPLEX, 'Propiedades disponibles'
   * will be returned. If estate is on bidding, 'Mejor oferta' will be returned.
   * @param {Estate} estate // An Estate object.
   */
  static contractToString(estate) {
    const bidding = estate?.getBidding();
    const contract = estate?.getContract();
    const sellMethod = estate?.getSellMethod();
    const term = contract?.getTerm();
    const termMethod = contract?.getTermMethod();

    if (sellMethod === Estate.ON_COMPLEX) { // Estate is a complex.
      return 'Propiedades disponibles';
    } else if (bidding.getStatus() && bidding.getBids().length) { // Bidding active and has already a bid.
      return 'Mejor oferta';
    } else if (sellMethod === Estate.ON_SALE) {
      return 'Trato directo';
    } else if (sellMethod === Estate.ON_LEASE && term && termMethod) {
      const plur = term === 1 ? '' : termMethod === Contract.TERM_METHOD_DAY ? 's' : 'es';
      let tDM;

      switch (termMethod) {
        case Contract.TERM_METHOD_DAY: {
          tDM = 'día';
          break;
        } case Contract.TERM_METHOD_MONTH: {
          tDM = 'mes';
          break
        } case Contract.TERM_METHOD_YEAR: {
          tDM = 'año';
          break
        } default: tDM = '';
      }

      return `Contrato de ${term} ${tDM}${plur}`;
    }
  }

  /** Obtains an array of current estate types.
   * @returns {TypeObject[]}
   */
  static getTypes() {
    const props = Object.getOwnPropertyNames(Estate).filter(p => /^TYPE_.*/.test(p));

    return [
      { displayName: 'Casa', propName: props[0], value: Estate.TYPE_HOUSE },
      { displayName: 'Departamento de piso o loft', propName: props[1], value: Estate.TYPE_DEPARTMENT },
      { displayName: 'Habitación, oficina o local comercial', propName: props[2], value: Estate.TYPE_OFFICE },
      { displayName: 'Terraza o salón de eventos', propName: props[3], value: Estate.TYPE_EVENT_HALL },
      { displayName: 'Almacén', propName: props[4], value: Estate.TYPE_DEPOT },
      { displayName: 'Edificio', propName: props[5], value: Estate.TYPE_BUILDING },
      { displayName: 'Hotel o motel', propName: props[6], value: Estate.TYPE_HOTEL },
      { displayName: 'Lote o terreno', propName: props[7], value: Estate.TYPE_TERRAIN },
      { displayName: 'Obra negra o gris', propName: props[8], value: Estate.TYPE_BLACK_WORK },
      { displayName: 'Otro', propName: props[9], value: Estate.TYPE_OTHER }
    ]
  }

  /** Obtains the amount to pay for the estate, expressed in an string. If estate is in bidding and
   * a bid is already offered, the last bid offered will be returned.
   * @param {Estate} estate A contract to get its frequency.
   * @example (10000, Contract.PAY_FREQ_YEARLY) => return '$10,000 MXN / año'
   */
  static priceToString(estate) {
    const contract = estate?.getContract();

    if (contract?.getPayAmount()) {
      const getCurrPrice = () => {
        const bidding = estate.getBidding();

        if (bidding?.getStatus() && bidding.getBids().length) {
          // Get max offer.
          let maxBid = 0;

          for (let i = 0; i < bidding.getBids().length; i++) {
            if (maxBid < bidding.getBids()[i].getAmount())
              maxBid = bidding.getBids()[i].getAmount();
          }

          // Return bid value.
          return maxBid;
        } else return contract.getPayAmount();
      }

      const getPayFreq = () => {
        let freq = contract.getPayFrequency();

        switch (freq) {
          case Contract.PAY_FREQ_DAILY: return ' / día'
          case Contract.PAY_FREQ_MONTHLY: return ' / mes'
          case Contract.PAY_FREQ_YEARLY: return ' / año'
          case Contract.PAY_FREQ_UNIQUE: return ' (pago único)'
          default: return '';
        }
      }

      return `$ ${Global.formatNumber(getCurrPrice())} MXN${getPayFreq()}`;
    }
  }

  /** Obtains sell method class (for span.pill elements). If estate has a not valid sell method, 'template' will
   * be returned.
   * @param {Estate} estate 
   */
  static sellMethodToClass(estate) {
    const hasAgreement = Boolean(estate?.getContract().getAgreement());

    switch (estate.getSellMethod()) {
      case Estate.ON_LEASE: return hasAgreement ? 'on-lease' : 'lease'
      case Estate.ON_SALE: return hasAgreement ? 'sold' : 'sale'
      case Estate.ON_COMPLEX: return 'complex'
      default: return 'template'
    }
  }

  /** Verifies if a estate is able to be sold / lease by divisions.
   * @param {Estate} estate Estate instance.
   */
  static canBeComplex(estate) {
    const type = (estate && estate.getType()) || undefined;

    return Boolean(type) &&
      type !== Estate.TYPE_BLACK_WORK
      && type !== Estate.TYPE_OFFICE
      && type !== Estate.TYPE_DEPARTMENT
      && type !== Estate.TYPE_EVENT_HALL;
  }


  /** Verifies if a estate is able to be on lease.
   * @param {Estate} estate
   */
  static canBeOnLease(estate) {
    const type = estate?.getType() || undefined;

    return Boolean(type) &&
      type !== Estate.TYPE_BLACK_WORK
      && type !== Estate.TYPE_HOTEL;
  }

  /** Verifies if an estate can have a parkin lot or a sigle garage
   * @param {Estate} estate 
   * @returns {0|1} If 0, estate type allows a parking lot. If 1, estate type
   * allows a garage.
   */
  static getParkingType(estate) {
    const type = estate?.getType();

    return Boolean(type) &&
      (type === Estate.TYPE_BUILDING
        || type === Estate.TYPE_DEPOT
        || type === Estate.TYPE_EVENT_HALL
        || type === Estate.TYPE_HOTEL)
      ? 0 : 1
  }

  /** Checks if given number is a valid Estate status.
   * @param {number} status A status.
   */
  static isStatusValid(status) {
    return status === Estate.STATUS_SUS
      || status === Estate.STATUS_INA
      || status === Estate.STATUS_ACT
      || status === Global.STATUS_DELETED;
  }

  /** Checks if given number is a valid Estate type.
   * @param {number} type A type.
   */
  static isTypeValid(type) {
    return type >= Estate.TYPE_OTHER && type <= Estate.TYPE_BLACK_WORK;
  }

  /** Wipes unnecessary attributes based on estate's sell method. Be sure to call this function (before
   * Global.purgeObject()) before sending it to the server.
   * @example PSCollection.purge() // <- called inside this method.
   * 
   * @param {Estate} estate An estate instance.
   */
  static purge(estate) {
    if (estate) { // Sorted by sell method.
      switch (estate.getSellMethod()) {
        case Estate.ON_COMPLEX: {
          estate.setBidding(); // Remove bidding.
          estate.setContactInfo(); // Remove contact info.
          estate.setContract(); // Remove contract.
          estate.setRequirements(); // Remove requirements.
          estate.setStatus(Estate.STATUS_INA); // Default status to inactive.

          break;
        } case Estate.ON_SALE: {
          estate.setRequirements(); // Remove requirements.
          estate.getContract().setCharge(); // Remove charge at start.
          estate.getContract().setInclServs(); // Remove included services.
          estate.getContract().setTerm(); // Remove term.
          estate.getContract().setTermMethod(); // Remove term method.

          if (!estate.getBidding().getStatus()) estate.setBidding(); // Remove bidding.

          break;
        } case Estate.ON_LEASE: {
          if (!estate.getBidding().getStatus()) estate.setBidding(); // Remove bidding.

          break;
        } default: { }
      }

      // Purge properties and services.
      PSCollection.purge(estate);
    }
  }

  // *********************************************************************************** SETTERS & GETTERS *
  /** Obtains the Bidding object of the estate.
   * @return {Bidding}
   */
  getBidding() {
    return this.bidding
  }

  /** Assigns a bidding object for the estate.
   * @param {Bidding} bidding 
   */
  setBidding(bidding) {
    this.bidding = bidding ?? new Bidding();
  }

  /** Obtain the date of estate's construction. */
  getBuildDate() {
    return this.buildDate;
  }

  /** Assigns a date, meaning the estate's construction. Value passes through Global.formatDateUTC
   * @param {number} buildDate 
   */
  setBuildDate(buildDate) {
    this.buildDate = Global.formatDateUTC(buildDate);
  }

  /** Obtains the complex from where this estate is grouped by */
  getComplex() {
    return this.complex;
  }

  /** Assings a complex instance to the estate.
   * @param {Estate} [complex]
   */
  setComplex(complex) {
    this.complex = complex;
  }

  /** Obtains the contact info for the estate. If returned value is -1, contact info is
   * the same as creator or user's data.
   */
  getContactInfo() {
    return this.contactInfo;
  }

  /** Assigns the contact info for the estate. If 
   * @param {Persona&-1} [contactInfo] 
   */
  setContactInfo(contactInfo) {
    this.contactInfo = contactInfo;
  }

  /** Obtains the contract of the estate.
   * @returns {Contract} A Contract object.
   */
  getContract() {
    return this.contract;
  }

  /** Assigns a contract for the estate.
   * @param {Contract} contract A contract for the estate.
   */
  setContract(contract) {
    this.contract = contract instanceof Contract ? contract : new Contract();
  }

  /** Obtains the cover name (the first Generic file's name). */
  getCoverName() {
    return this.getImages().at(0)?.getName();
  }

  /** Obtains the creator of this estate. If undefined, owner will be returned */
  getCreator() {
    return this.creator ?? this.getOwner();
  }

  /** Assings a creator for the estate
   * @param {User} creator
   */
  setCreator(creator) {
    this.creator = creator;
  }

  /** Obtains the description of the estate (for the publishment).
   * @returns {string} The description of the estate.
   */
  getDescription() {
    return this.description;
  }

  /** Assigns a descriptión for the publishment of the estate.
   * @param {string} description 
   */
  setDescription(description) {
    this.description = description?.length > Estate.MAX_DESC_LENGTH
      ? description?.slice(0, Estate.MAX_DESC_LENGTH).trim()
      : description?.trim();
  }

  /** Obtains the free spaces from estate. */
  getFreeSpaces() {
    return this.freeSpaces;
  }

  /** Assings the free spaces for the estate. totalSpaces must be defined already or undefined will
   * be assigned. Cannot be greater than totalSpaces.
   * @param {number} freeSpaces
   */
  setFreeSpaces(freeSpaces) {
    this.freeSpaces = Number(freeSpaces) <= this.getTotalSpaces()
      ? Number(freeSpaces)
      : undefined;
  }

  /** Obtains an image from the estate's images, searching for its name.
   * @param {string} name Name of the image.
   * @returns {import('./GenericFile').default} An image or undefined.
   */
  getImage(name) {
    return this.images.find(img => img.name === name);
  }

  /** Obtain the images of the estate
   * @returns {import('./GenericFile').default[]} an array of images.
   */
  getImages() {
    return this.images;
  }

  /** Assings an array of images for the estate. Creates a copy of given array.
   * @param {import('./GenericFile').default[]} images
   */
  setImages(images) {
    this.images = Array.isArray(images) ? images : [];
  }

  /** Obtains the insurance of the estate.
   * @returns {string} Insurance's name or undefined if estate has no insurance
   */
  getInsurance() {
    return this.insurance;
  }

  /** Assigns an insurance for the estate.
   * @param {string} insurance Insurance's name. undefined if estate has no insurance.
   */
  setInsurance(insurance) {
    this.insurance = insurance?.length > Estate.MAX_INSURANCE_LENGTH
      ? insurance?.slice(0, Estate.MAX_INSURANCE_LENGTH).toUpperCase().trim()
      : insurance?.toUpperCase().trim();
  }

  //************************************************************** MARKED FOR DELETION.

  /** Obtains the location of the estate.
   * @returns {Address} Location of the estate.
   */
  getLocation() {
    return this.location;
  }

  /** Assigns a location for the estate.
   * @param {Address} location 
   */
  setLocation(location) {
    this.location = location || new Address();
  }

  /** Obtains the estate's owner. */
  getOwner() {
    return this.owner;
  }

  /** Assigns an owner to the estate.
   * @param {User} [owner] 
   */
  setOwner(owner) {
    this.owner = owner;
  }

  /** Obtains the rate object of the Estate */
  getRate() {
    return this.rate;
  }

  /** Assings a rate object for the Estate.
   * @param {Rate} [rate] 
   */
  setRate(rate) {
    this.rate = rate;
  }

  /** Obtains the requirements array of the estate.
   * @returns {String[]} An array of requirements
   */
  getRequirements() {
    return this.requirements;
  }

  /** Assigns a requirements array for the estate.
   * @param {String[]} requirements An array.
   */
  setRequirements(requirements) {
    this.requirements = Array.isArray(requirements) ? requirements : [];
  }

  /** Obtains the estate's sell method, used for specify if the estate is for sale or for lease.
   * @returns {number} Sell method, specified by Estate.ON_LEASE, Estate.ON_SALE and Estate.ON_COMPLEX.
   */
  getSellMethod() {
    return this.sellMethod;
  }

  /** Assigsn a sell method for the propery, specified by Estate.ON_LEASE, Estate.ON_SALE and
   * Estate.ON_COMPLEX. Its type must be defined already, otherwise undefined will be always assigned.
   * @param {number} sellMethod Sell method to set. If invalid, undefined will be set.
   */
  setSellMethod(sellMethod) {
    const sellMethodValid = sellMethod === Estate.ON_SALE
      || (sellMethod === Estate.ON_COMPLEX && Estate.canBeComplex(this))
      || (sellMethod === Estate.ON_LEASE && Estate.canBeOnLease(this));

    this.sellMethod = sellMethodValid ? Number(sellMethod) : undefined;
  }

  /** Obtains the dimesions (mts) of the estate terrain (x and y).
   * @returns {{ x: number, y: number}} size of the estate terrain.
   */
  getSize() {
    return this.size;
  }

  /** Assigns the size (in metters) for the estate terrain. If any of the values is NaN, will be replaced
   * by the current value or by 0.
   * @param {{ x: number, y: number }} size size object for estate.
   * @default x=0 
   * @default y=0
   */
  setSize(size) {
    let { x, y } = size || {};

    if (!isNaN(x)) x = Number(x.toFixed(2));
    else x = this.getSize()?.x || 0;

    if (!isNaN(y)) y = Number(y.toFixed(2));
    else y = this.getSize()?.y || 0;

    this.size = { x, y };
  }

  /** Obtains squared size (size.x times size.y). Will return undefined if any of both attributes is
   * undefined.
   */
  getSquaredSize() {
    if (this.size.x && this.size.y) {
      return Number((this.size.x * this.size.y).toFixed(2));
    }
  }

  /** Obtains the estate's estatus.
   * @returns {number}
   */
  getStatus() {
    return this.status;
  }

  /** Assigns a status for the estate.
   * @param {number} status 
   */
  setStatus(status) {
    this.status = Estate.isStatusValid(status)
      ? Number(status)
      : Estate.STATUS_ACT;
  }

  /** Obtains the title of the estate.
   * @returns {string} the title of the estate.
   */
  getTitle() {
    return this.title;
  }

  /** Assigns a title to the estate. Title will be siliced if exceeds max length.
   * @param {string} title The new title for the estate.
   */
  setTitle(title) {
    this.title = title?.length > Estate.MAX_TITLE_LENGTH
      ? title?.slice(0, Estate.MAX_TITLE_LENGTH).trim()
      : title?.trim();
  }

  /** Obtains the total spaces from estate */
  getTotalSpaces() {
    return this.totalSpaces;
  }

  /** Assings the total spaces for estate. Must be greater or equal to 1 or equal to -1, otherwhise
   * undefined will be set. If totalSpaces is greater than freeSpaces, freeSpaces will be set equal
   * to totalSpaces.
   * @param {Number} totalSpaces 
   */
  setTotalSpaces(totalSpaces) {
    this.totalSpaces = !isNaN(totalSpaces) && (Number(totalSpaces) >= 1 || Number(totalSpaces) === -1)
      ? Number(totalSpaces)
      : undefined;

    if (this.totalSpaces === -1 || this.totalSpaces === undefined || this.getFreeSpaces() > this.totalSpaces)
      this.setFreeSpaces(this.totalSpaces);
  }

  /** Obtains the estate type
   * @returns {number} Estate type (set by a number, specified by Estate.TYPE_...).
   */
  getType() {
    return this.type;
  }

  /** Assigns a type for the estate. Must be valid according to Estate.TYPE_
   * @param {number} type 
   */
  setType(type) {
    this.type = Estate.isTypeValid(Number(type)) ? Number(type) : undefined;
  }
}

export default Estate;