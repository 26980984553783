import { forwardRef, useCallback, useContext, useEffect, useRef, useState } from "react";
import { globalContext } from "../../context/GlobalContext";
import * as Icons from "../../assets/images";
import Address from "../../objects/Address.js";
import Button from "../../components/Button";
import Contract from "../../objects/Contract.js";
import DAOServ from "../../objects/DAOServ.js";
import ErrHandler from "../../objects/ErrHandler";
import FileChooser from "../../objects/FileChooser.js";
import FileManager from "../../components/FileManager.jsx";
import GenericFile from "../../objects/GenericFile.js";
import Global from "../../objects/Global";
import Hintbox from "../../components/Hintbox.jsx";
import Inputbar from "../../components/Inputbar.jsx";
import LoadingBlock from "../../components/LoadingBlock.jsx";
import PetAssistant from "../../components/PetAssistant.jsx";
import PropDisplay from "../../components/PropDisplay";
import PSCollection from "../../objects/PSCollection.js";
import Selectbar from "../../components/Selectbar.jsx";
import User from "../../objects/User.js";
import UserCard from "../../components/UserCard.jsx";

/** Props Type Definition.
 * @typedef {import ('../../objects/ClientSocket.js').default} ClientSocket
 * @typedef {import('../../objects/Estate.js').default} Estate
 * @typedef {import('../SignProcess.jsx').GuestObject} GuestObject
 * @typedef {Object} Props The props definition.
 * @property {Contract} contract A contract to display. It's agreement attribute must be defined.
 * @property {boolean} disableUI If true, UI will be disabled.
 * @property {GuestObject[]} guests The guests array.
 * @property {Estate} estate Its id must be defined so the compo can cast basic info.
 * @property {string} hallKey The obtained hall key.
 * @property {boolean} [ownerMode] If true. phase 1 will be rendered for the owner.
 * @property {React.LegacyRef<HTMLDivElement>} ref A reference for the root compo.
 * @property {(e: boolean) => void} setDisableUI A callback function from parent to  disable UI.
 * @property {ClientSocket} socket Current ClientSocket.
 * @property {number} today The current date.
 */

/** Renders a Phase2 compo for SignProcess component.
 * @type {React.ForwardRefRenderFunction<?, Props>}
 */
const Phase2 = forwardRef((props, ref) => {
  // *** useContext ***
  const {
    currSession,
    currSessionPicture,
    getCacheFile,
    pushAlertMessage,
    pushCacheFile,
    pushMessageHint,
    timezoneOffset
  } = useContext(globalContext);
  // *** useRef ***
  const animFig = useRef(Global.getRandom(['f1', 'f2', 'f3']));
  const chargeType = useRef(props.contract.getChargeType());
  // *** useState ***
  const [contractForceChange, setContractForceChange] = useState(
    /** @type {{ payFrequency: string, term: string }|undefined} */(undefined)
  );
  const [unlockSubmit, setUnlockSubmit] = useState();
  const [estate, setEstate] = useState(props.estate.getTitle() ? props.estate : undefined);
  const [estateCover, setEstateCover] = useState(props.estate.getImages()[0]?.getURLData());
  const [forceRender, setForceRender] = useState(false);

  /** Unlocks submit button if allowed */
  const requestUnlockSubmit = useCallback(() => {
    const hallGuests = props.guests.length > 0;
    const haveFiles = props.contract.getAgreement().getFiles().length > 0;
    const payFrequencyValid = props.contract.getPayFrequency() > 0;
    const priceValid = props.contract.getPayAmount() > 0;
    const termMethodValid = Contract.isTermMethodValid(props.contract.getTermMethod());
    const termValid = props.contract.getTerm() > 0;
    const startDateValid = props.contract.getAgreement().getStartDate() >= props.today;
    const extraChargeValid = Contract.isExtraChargeValid(props.contract.getCharge());

    setUnlockSubmit(extraChargeValid
      && hallGuests
      && haveFiles
      && payFrequencyValid
      && priceValid
      && startDateValid
      && termMethodValid
      && termValid);
  }, [props.contract, props.guests, props.today]);

  /** Submit event hanlder. */
  const buttonHandleOnSubmit = async () => {
    props.setDisableUI(true);

    // Contract parse.
    const agreement = {
      charge: props.contract.getCharge(),
      id: props.contract.getAgreement().getId(),
      includedServices: props.contract.getInclServs(),
      payAmount: props.contract.getPayAmount(),
      payFrequency: props.contract.getPayFrequency(),
      services: props.estate.getServices().filter(s => s.value).map(s => s.id),
      startDate: props.contract.getAgreement().getStartDate(),
      term: props.contract.getTerm(),
      termMethod: props.contract.getTermMethod()
    };

    // Payload append.
    const payload = new FormData();
    payload.set('agreement', JSON.stringify(agreement));
    payload.set('tst', currSession.tst);
    props.contract.getAgreement().getFiles().forEach(f => payload.append('files', f));

    // Sends files to the server (uploads as temporal). Next step will be requested by server
    // and sent to host (this instance) and guests.
    await DAOServ.post('upload_contract_files_to_hall', payload, 'MULTI')
      .catch(err => {
        pushMessageHint({ message: ErrHandler.parseError(err), type: "error" });
        props.setDisableUI(false);
      });
  }

  /** Copy hall code event handler. */
  const copyBtnHandleOnClick = async () => {
    if (props.disableUI) return;

    let message;
    let type;

    await Global.copyToClipboard(props.hallKey || 'UNSET')
      .then(() => {
        message = 'Código copiado al portapapeles.';
        type = 'complete';
      }).catch(err => {
        ErrHandler.parseError(err);
        message = 'No se pudo copiar al portapapeles.';
        type = 'error';
      });

    pushAlertMessage({ message, type });
  }

  /** @param {string} [input] */
  const extraAmountHandleOnChange = input => {
    props.contract.setCharge(input !== undefined ? Number(input) : undefined);

    requestUnlockSubmit();
  }

  /** @param {string} [input] */
  const extraChargeRequiredHandleOnChange = input => {
    props.contract.setCharge(input ? Number(input) : 0);

    chargeType.current = props.contract.getChargeType();

    requestUnlockSubmit();
    setForceRender(!forceRender);
  }

  /** Obtains the connected guests to display to the DOM. */
  const getConnectedGuests = () => {
    const num = props.guests.filter(guest => !guest.isBanned).length;

    if (!num)
      return 'Esperando invitados...'
    else
      return `${num} invitado${num > 1 ? 's' : ''} conectado${num > 1 ? 's' : ''}`
  }

  const getOnIsValidFailTermMessage = () => {
    switch (props.contract.getTermMethod()) {
      case Contract.TERM_METHOD_DAY: return 'Solo valores entre 0 y 32';
      case Contract.TERM_METHOD_MONTH: return 'Solo valores entre 0 y 12';
      case Contract.TERM_METHOD_YEAR: return 'Solo valores entre 0 y 11';
      default: return 'Valor inválido'
    }
  }

  const getPayFrequencyOptions = () => {
    const termMethod = props.contract.getTermMethod();
    /** @type {import('../../components/Selectbar').OptionObject[]} */
    const options = [
      { displayValue: 'Pago único', value: Contract.PAY_FREQ_UNIQUE },
      { displayValue: 'Diario', value: Contract.PAY_FREQ_DAILY },
    ];

    if (termMethod > Contract.TERM_METHOD_DAY)
      options.push({ displayValue: 'Mensual', value: Contract.PAY_FREQ_MONTHLY });

    if (termMethod > Contract.TERM_METHOD_MONTH)
      options.push({ displayValue: 'Anual', value: Contract.PAY_FREQ_YEARLY })

    return options;
  }

  /** Obtains current term method to display to the DOM. */
  const getTermMethod = () => {
    switch (props.contract.getTermMethod()) {
      case Contract.TERM_METHOD_DAY: return 'en días';
      case Contract.TERM_METHOD_MONTH: return 'en meses';
      case Contract.TERM_METHOD_YEAR: return 'en años';
      default: return 'sin especificar';
    }
  }

  /** @returns {import('../../components/Selectbar').OptionObject[]} */
  const getTermMethodOptions = () => {
    return [
      { displayValue: 'En años', value: Contract.TERM_METHOD_YEAR },
      { displayValue: 'En días', value: Contract.TERM_METHOD_DAY },
      { displayValue: 'En meses', value: Contract.TERM_METHOD_MONTH }
    ]
  }

  const guestBtnHandleOnClick = () => {
    if (!props.disableUI && props.ownerMode)
      ref.current.classList.add('guests-management');
  }

  /** Included services onChange handler
   * @param {string} id Service id
   * @param {number} value Current value
   */
  const inclServiceOnChangeHandler = (id, value) => {
    const inclServs = props.contract.getInclServs();

    if (value === 1)
      inclServs.push(id);
    else {
      const index = inclServs.indexOf(id);

      if (index !== -1) inclServs.splice(inclServs.indexOf(id), 1);
    }
  }

  /** @param {string} [input] */
  const payAmouHandleOnChange = input => {
    props.contract.setPayAmount(input ? Number(input) : 0);
    requestUnlockSubmit();

    // Forces render.
    if (props.contract.getChargeType() === Contract.CHARGE_SAME_AS_PAY)
      setForceRender(!forceRender);
  }

  const payFrequencyHandleOnChange = option => {
    props.contract.setPayFrequency(option);
    requestUnlockSubmit();
    setForceRender(!forceRender);
  }

  /** @param {string} [input] */
  const startDateHandleOnChange = input => {
    const date = input
      ? Global.dateToMilliUTC(Global.transformDate(input), -timezoneOffset)
      : undefined;
    props.contract.getAgreement().setStartDate(date);
    requestUnlockSubmit();
  }

  /** Used to render guests list (only when owner mode is false) */
  const renderGuestsList = () => {
    let start = true;

    return props.guests.map(guest => {
      const isHost = start;
      start = false;

      return (<UserCard
        key={guest.data.getUsername()}
        icon={isHost ? Icons.CrownIcon : undefined}
        type={isHost ? "gold" : undefined}
        user={guest.data}
      />);
    });
  }

  /** @param {string} [input] */
  const termHandleOnChange = input => {
    props.contract.setTerm(input ? Number(input) : undefined);
    requestUnlockSubmit();
  }

  const termMethodHandleOnChange = option => {
    props.contract.setTermMethod(option ? Number(option) : undefined);
    props.contract.setTerm();
    props.contract.setPayFrequency();
    requestUnlockSubmit();
    setContractForceChange({ payFrequency: '', term: '' });
  }

  useEffect(() => {
    const fetchEstate = async () => {
      if (!props.estate.getId()) return;
      let estateCover;

      try {
        let query = await DAOServ.get('get_publishment_publ', [props.estate.getId()]);
        // Parse data to estate.
        props.estate.setType(query['e_type']);
        props.estate.setLocation(new Address({ geoData: query['g_location'] }));
        props.estate.getOwner().setId(query['iduser']);
        props.estate.setSellMethod(query['sell_method']);
        props.estate.setTitle(query['title']);
        estateCover = query['cover']; // Cover file name.
        // Getting owner info.
        query = await DAOServ.post('get_user_public_data', { data: query['idowner'] }, 'JSON');

        // Getting estate cover. Checking if file is in cache.
        const auxCF = getCacheFile(estateCover);

        if (auxCF !== undefined) { // Fetch file from cache.
          const urlData = typeof auxCF.content === 'string'
            ? auxCF.content
            : await FileChooser.readAsDataURL(auxCF.content);

          props.estate.setImages([new GenericFile({
            name: estateCover,
            size: auxCF.size,
            urlData
          })]);
        } else { // Fetch file from server.
          DAOServ.getFileCallback(estateCover, async (err, file) => {
            if (file) {
              const urlData = await FileChooser.readAsDataURL(file);
              props.estate.setImages([new GenericFile({
                name: estateCover,
                size: file.size,
                urlData
              })]);

              setEstateCover(urlData);
            } else setEstateCover(Icons.EstateDefaultCover);
          });
        }
      } catch (err) {
        pushMessageHint({ message: ErrHandler.parseError(err), type: "error" });
      }
      setEstate(props.estate);
    }

    if (!props.estate.getTitle()) fetchEstate();
  }, [props.estate, getCacheFile, pushCacheFile, pushMessageHint]);

  useEffect(() => {
    requestUnlockSubmit();
  }, [requestUnlockSubmit, props.guests]);

  useEffect(() => {
    if (contractForceChange !== undefined)
      setContractForceChange();
  }, [contractForceChange]);

  if (props.ownerMode) return ( // For host
    <div className="popup-content init" id='phase-2' ref={ref}>
      <div className="phase-container">
        <PetAssistant animOnDwarf pet="vicky"
          message={`Hecho. Comparte el código de la sala con tus arrendatarios para que puedan `
            + "unirse a tu sala. Prepara el contrato mientras se unen.\nPara gestionar los "
            + "invitados de tu sala, haz clic en el segundo botón que está en la "
            + "tarjeta de arriba. Cuando todo esté listo, haz clic en el botón verde de abajo "
            + "para que tus arrendatarios revisen el contrato."} />
        <div className="box">
          <h3 className="highlight title">Información del contrato.</h3>
          <div className="flex-box wrap">
            <div className="child m5">
              <Selectbar options={getTermMethodOptions()}
                defaultValue={props.estate.getContract().getTermMethod()}
                onChange={termMethodHandleOnChange}
                placeholder={'Método de duración'}
                required />
            </div>
            <div className="child auto-width">
              <span className="arrow previous conditional" />
            </div>
            <div className="child m5">
              <Inputbar defaultValue={props.contract.getTerm()}
                stopPropagation
                disabled={!props.contract.getTermMethod() || props.disableUI}
                onChange={termHandleOnChange}
                className="phase2-input"
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                forceChangeValue={contractForceChange?.term}
                inputMode="numeric"
                isValid={input => Contract.isTermValid(props.contract, (input ? Number(input) : undefined))}
                maxLength={2}
                placeholder={{
                  default: `Duración del contrato (${getTermMethod()})`,
                  onIsValidFail: getOnIsValidFailTermMessage()
                }} required />
            </div>
          </div>
          <div className="flex-box wrap">
            <div className="child m5">
              <Inputbar defaultValue={props.contract.getPayAmount()}
                stopPropagation
                disabled={props.disableUI}
                onChange={payAmouHandleOnChange}
                className="phase2-input"
                filters={[{ regExp: Global.REGEXP_FILTER_DECIMAL }]}
                inputMode="decimal"
                isValid={(input) => Global.REGEXP_DECIMAL.test(input) && Number(input) > 0}
                maxLength={10}
                placeholder={{ default: 'Pago (MXN)' }}
                required />
            </div>
            <div className="child auto-width">
              <span className="arrow previous conditional" /></div>
            <div className="child m5">
              <Selectbar defaultValue={props.contract.getPayFrequency()}
                disabled={!props.contract.getTermMethod() || props.disableUI}
                forceChangeValue={contractForceChange?.payFrequency}
                onChange={payFrequencyHandleOnChange}
                options={getPayFrequencyOptions()}
                placeholder="Frecuencia de pago"
                required />
            </div>
          </div>
          <div className="flex-box wrap">
            <div className="child m5">
              <Inputbar defaultValue={Global.transformDateForInput(props.contract.getAgreement().getStartDate())}
                stopPropagation
                disabled={props.disableUI}
                onChange={startDateHandleOnChange}
                className="phase2-input"
                isValid={input => Global
                  .dateToMilliUTC(Global.transformDate(input), -timezoneOffset)
                  >= props.today}
                placeholder={{
                  default: 'Fecha de inicio',
                  onIsValidFail: 'Coloca la fecha de hoy o posterior'
                }}
                required
                type="date" />
            </div>
          </div>
          <div className="flex-box wrap">
            <div className="child">
              <Selectbar defaultValue={props.contract.getChargeType()}
                options={[
                  { displayValue: 'Igual que el pago', value: Contract.CHARGE_SAME_AS_PAY },
                  { displayValue: 'Personalizado', value: Contract.CHARGE_CUSTOM }
                ]}
                disabled={props.disableUI}
                placeholder='Cargo en el primer pago'
                undefinedOption="Ninguno"
                onChange={extraChargeRequiredHandleOnChange}
                required />
            </div>
            <div className="child auto-width">
              <span className="arrow previous conditional" /></div>
            <div className="child m5">
              <Inputbar onChange={extraAmountHandleOnChange}
                stopPropagation
                className="phase2-input"
                defaultValue={props.contract.getChargeType() === Contract.CHARGE_SAME_AS_PAY
                  ? props.contract.getPayAmount()
                  : props.contract.getChargeType() === Contract.CHARGE_CUSTOM
                    ? props.contract.getCharge() === Contract.CHARGE_CUSTOM
                      ? ''
                      : props.contract.getCharge()
                    : ''
                }
                disabled={props.disableUI || chargeType.current !== Contract.CHARGE_CUSTOM}
                filters={[{ regExp: Global.REGEXP_FILTER_DECIMAL }]}
                inputMode="decimal"
                placeholder={{
                  default: props.contract.getChargeType() === 0
                    ? 'Cargo extra (no requerido)'
                    : props.contract.getChargeType() === Contract.CHARGE_SAME_AS_PAY
                      ? 'Cargo extra (igual que el pago)'
                      : 'Cargo extra',
                  onIsValidFail: 'El valor es inválido o es menor o igual a 0'
                }}
                isValid={input => Global.REGEXP_DECIMAL.test(input) && Number(input) > 0}
                required={props.contract.getChargeType() === Contract.CHARGE_CUSTOM}
                forceChangeValue={props.contract.getChargeType() === Contract.CHARGE_SAME_AS_PAY
                  ? (props.contract.getPayAmount() || '')
                  : !props.contract.getChargeType()
                    ? ''
                    : props.contract.getCharge() !== Contract.CHARGE_CUSTOM
                      ? props.contract.getCharge()
                      : ''} />
            </div>
          </div>
        </div>
        <div className="box">
          <h3 className="highlight title">Servicios</h3>
          {/* Water service */}
          {props.estate.hasService(PSCollection.SERV_WTER) && <Selectbar
            defaultValue={props.contract.getInclServs().includes(PSCollection.SERV_WTER) ? 1 : 0}
            disabled={props.disableUI}
            icon={Icons.WtrIcon}
            onChange={option => inclServiceOnChangeHandler(PSCollection.SERV_WTER, option)}
            options={[
              { displayValue: 'Lo paga el arrendatario', value: 0 },
              { displayValue: 'Incluido en el arrendamiento', value: 1 }
            ]} placeholder="Servicio de agua"
            required />}
          {/* Energy service */}
          {props.estate.hasService(PSCollection.SERV_ENGY) && <Selectbar
            defaultValue={props.contract.getInclServs().includes(PSCollection.SERV_ENGY) ? 1 : 0}
            disabled={props.disableUI}
            icon={Icons.EngIcon}
            onChange={option => inclServiceOnChangeHandler(PSCollection.SERV_ENGY, option)}
            options={[
              { displayValue: 'Lo paga el arrendatario', value: 0 },
              { displayValue: 'Incluido en el arrendamiento', value: 1 }
            ]} placeholder="Servicio de luz"
            required />}
          {/* Food service */}
          {props.estate.hasService(PSCollection.SERV_FOOD) && <Selectbar
            defaultValue={props.contract.getInclServs().includes(PSCollection.SERV_FOOD) ? 1 : 0}
            disabled={props.disableUI}
            icon={Icons.FoodIcon}
            onChange={option => inclServiceOnChangeHandler(PSCollection.SERV_FOOD, option)}
            options={[
              { displayValue: 'Lo paga el arrendatario', value: 0 },
              { displayValue: 'Incluido en el arrendamiento', value: 1 }
            ]} placeholder="Servicio de alimentos"
            required />}
          {/* Gas service */}
          {props.estate.hasService(PSCollection.SERV_GAS) && <Selectbar
            defaultValue={props.contract.getInclServs().includes(PSCollection.SERV_GAS) ? 1 : 0}
            disabled={props.disableUI}
            icon={Icons.GasIcon}
            onChange={option => inclServiceOnChangeHandler(PSCollection.SERV_GAS, option)}
            options={[
              { displayValue: 'Lo paga el arrendatario', value: 0 },
              { displayValue: 'Incluido en el arrendamiento', value: 1 }
            ]} placeholder="Servicio de gas"
            required />}
          {/* Internet service */}
          {props.estate.hasService(PSCollection.SERV_INET) && <Selectbar
            defaultValue={props.contract.getInclServs().includes(PSCollection.SERV_INET) ? 1 : 0}
            disabled={props.disableUI}
            icon={Icons.NetIcon}
            onChange={option => inclServiceOnChangeHandler(PSCollection.SERV_INET, option)}
            options={[
              { displayValue: 'Lo paga el arrendatario', value: 0 },
              { displayValue: 'Incluido en el arrendamiento', value: 1 }
            ]} placeholder="Servicio de internet y/o cable y/o cable"
            required />}
          {/* Housekeeping service */}
          {props.estate.hasService(PSCollection.SERV_HKPG) && <Selectbar
            defaultValue={props.contract.getInclServs().includes(PSCollection.SERV_HKPG) ? 1 : 0}
            disabled={props.disableUI}
            icon={Icons.HkpIcon}
            onChange={option => inclServiceOnChangeHandler(PSCollection.SERV_HKPG, option)}
            options={[
              { displayValue: 'Lo paga el arrendatario', value: 0 },
              { displayValue: 'Incluido en el arrendamiento', value: 1 }
            ]} placeholder="Servicio de limpieza"
            required />}
        </div>
        <div className="box" disabled={props.disableUI}>
          <h3 className="highlight title">Documentos</h3>
          <div className="flex-box">
            <div className="child jc-left">
              <h6 className="overset"><span className="required" />Sube al menos un documento del contrato físico.</h6>
            </div>
          </div>
          <FileManager
            allowRemove
            allowUpload
            disabled={props.disableUI}
            files={props.contract.getAgreement().getFiles().map(f => { return { file: f, from: 'TEMP' } })}
            fileTypes={['application/pdf', 'image/jpeg']}
            maxFiles={5}
            maxSize={Global.U_MB * 2}
            onAdd={file => {
              props.contract.getAgreement().getFiles().push(file)
              requestUnlockSubmit();
            }}
            onRemove={i => {
              props.contract.getAgreement().getFiles().splice(i, 1);
              requestUnlockSubmit();
            }} />
        </div>
        <div className="flex-box wrap">
          <div className="child m5">
            <Inputbar defaultValue={props.contract.getAgreement().getId()}
              stopPropagation
              disabled={props.disableUI}
              onChange={input => props.contract.getAgreement().setId(input)}
              className="phase2-input"
              filters={[{ regExp: Global.REGEXP_FILTER_FORBIDDEN_SYMBOLS }]}
              maxLength={20}
              placeholder={{ default: 'Marca o dirección interna' }} />
          </div>
          <div className="child jc-left">
            <Hintbox icon={Icons.InfoIcon}
              message={'Este es un identificador para el contrato. No es necesario, pero'
                + ' te ayudará a encontrarlo más fácil en el futuro.'} />
          </div>
        </div>
      </div>
      {/* Code card */}
      <div className="code-container" disabled={props.disableUI}>
        <div className="flex-box">
          <div className="child jc-left">
            <PropDisplay header="Código de invitación"
              className="large-font"
              property={props.hallKey || 'UNSET'}
              propertyClass="gray"
            />
          </div>
          <div className="child auto-width">
            <Button title="Copiar al portapapeles"
              className="empty borderless"
              id="copy-to-clipboard"
              icon={Icons.CopyIcon}
              onClick={copyBtnHandleOnClick} />
          </div>
        </div>
        <div className="flex-box">
          {!props.disableUI && <div className="child auto-width">
            <span className={`animated-figure mini purple ${animFig.current}`} />
          </div>}
          <div className="child jc-left p10">
            <h6 className="highlight">{getConnectedGuests()}</h6>
          </div>
          <div className="child auto-width">
            <Button title="Gestionar invitados"
              className="empty borderless"
              id="guest-management"
              icon={Icons.PplCapIcon}
              onClick={guestBtnHandleOnClick} />
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        <div className="flex-box m3">
          <div className="child">
            <Button value='Enviar a revisión'
              animated
              disabled={!unlockSubmit}
              empty
              icon={Icons.SendIcon}
              isWaiting={props.disableUI}
              onClick={buttonHandleOnSubmit}
              onWaitValue='Enviando...' />
          </div>
          {props.guests.length === 0 && <div className="child auto-width">
            <Hintbox fullWidth
              message="No puedes enviar el contrato porque aún no hay invitados en la sala."
              type="warning" />
          </div>}
        </div>
      </div>
    </div>
  );
  else return ( // For guests.
    <div className="popup-content init" id='phase-2' ref={ref}>
      <div className="box estate-container">
        {!estate && <LoadingBlock noBackground miniFigure />}
        {estate && <div className="img-container">
          {!estateCover && <LoadingBlock miniFigure />}
          {estateCover && <img src={estateCover} alt="" />}
        </div>}
        {estate && <div className="info-container">
          <h5 className="highlight estate-title">{estate.getTitle()}</h5>
          <div className="flex-box">
            <div className="child jc-right m5">
              <h6 className="overset">De</h6>
            </div>
            <div className="child auto-width">
              <UserCard user={estate.getOwner()}
                mini
                rounded />
            </div>
          </div>
          <div className="flex-box">
            <div className="child auto-width">
              <span className={`animated-figure ${animFig.current} mini gray`} /></div>
            <div className="child m3 jc-left text-wrap">
              <h6 className="overset">El anfitrión está preparando tu contrato.</h6>
            </div>
          </div>
        </div>}
      </div>
      <div className="guests-container">
        <div className="flex-box">
          <div className="child auto-width m3">
            <h5>{props.guests.length + 1} personas en la sala</h5>
          </div>
        </div>
        <div className="items-list">
          <UserCard
            displayName={'Tú'}
            defaultPicture={currSessionPicture}
            type="self"
            user={new User({ username: currSession.username })} />
          {renderGuestsList()}
        </div>
      </div>
    </div>
  )
})

export default Phase2;