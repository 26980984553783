import './styles/contract-item.css';
import { useContext, useRef } from 'react';
import Global from '../objects/Global';
import UserCard from './UserCard';
import { globalContext } from '../context/GlobalContext';

/** The ContractItem compo.
 * @param {Object} props The props object.
 * @param {import('../objects/Contract').default} props.contract The contract to be rendered. Agreement
 * property must be defined.
 * @param {number} props.currentDay The current day. Must be defined to calculate agreement status, but
 * won't trigger an error if undefined.
 */
const ContractItem = props => {
  // *** useContext ***
  const { timezoneOffset } = useContext(globalContext);
  // *** useRef ***
  const agreement = useRef(props.contract.getAgreement());

  const getFormatedDeadline = () => {
    if (!Boolean(agreement.current.getCancellationDate()))
      return Global.parseDateUTC(agreement.current.getNextDeadline(), timezoneOffset);
    else
      return 'Cancelado.';
  }

  const getFormatedEndDate = () => {
    if (!Boolean(agreement.current.getCancellationDate()))
      return Global.parseDateUTC(agreement.current.getEndDate(), timezoneOffset)
    else
      return 'Cancelado.'
  };

  const getFormatedStartDate = () => Global.parseDateUTC(agreement.current.getStartDate());

  const getRemark = () => {
    return agreement.current.getId() || agreement.current.getHash();
  }

  return (
    <div className="contract-item" id={agreement.current.getHash()}>
      {/* Top bar */}
      <div className="top-bar">
        <h4 className="highlight remark">{getRemark()}</h4>
        <span className={`pill status ${props.contract.getAgreement().statusToClass()}`} />
      </div>
      <div className="info-container">
        <div className="dates">
          <p className="start-date">Inicio: <span>{getFormatedStartDate()}</span></p>
          <p className="end-date">Fin: <span>{getFormatedEndDate()}</span></p>
          <p className="deadline">Corte: <span>{getFormatedDeadline()}</span></p>
        </div>
        <div className="lessees">
          <h6 className="highlight">Arrendatario{agreement.current.getLessees().length > 1 ? 's' : ''}</h6>
          {agreement.current.getLessees().map(l => {
            return <UserCard user={l} key={l.getUsername()} mini rounded />
          })}
        </div>
      </div>
    </div>
  );
}

export default ContractItem;