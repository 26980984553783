import './styles/foot.css';
import { useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { globalContext } from './context/GlobalContext';
import Global from './objects/Global';
// Icons.
import { LogoImg } from './assets/images';

const Foot = () => {
  // *** useContext ***
  const { setShowPrivacyAndTerms } = useContext(globalContext);
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useRef ***
  const aboutUs = useRef(/** @type {HTMLAnchorElement} */(undefined));
  const beta = useRef(/** @type {HTMLAnchorElement} */(undefined));
  const contactUs = useRef(/** @type {HTMLAnchorElement} */(undefined));
  const privacy = useRef(/** @type {HTMLAnchorElement} */(undefined));
  const subscriptions = useRef(/** @type {HTMLAnchorElement} */(undefined));
  const terms = useRef(/** @type {HTMLAnchorElement} */(undefined));

  /** @type {React.MouseEventHandler} */
  const footClickHandler = e => {
    e.preventDefault();

    if (e.target === contactUs.current)  // Contact Us.
      navigate(Global.PATH_CONTACT_US);
    else if (e.target === aboutUs.current) // About us.
      navigate(Global.PATH_ABOUT_US);
    else if (e.target === subscriptions.current)
      navigate(Global.PATH_LICENSE_MANAGEMENT);
    else if (e.target === beta.current)
      navigate(Global.PATH_BETA);
    else if (e.target === privacy.current)
      setShowPrivacyAndTerms('PRIV');
    else if (e.target === terms.current)
      setShowPrivacyAndTerms('TERM');
  }

  return (
    <footer className="foot" onClick={footClickHandler}>
      <div className="foot-body">
        <div className="logo-container">
          <img src={LogoImg} alt="logo" />
          <h1 className="logo-title">Cuntrat</h1>
        </div>
        <div className="services">
          <h4 className="foot-body-title">Servicios</h4>
          <a ref={privacy} className="foot-item" href="/">Política de privacidad</a>
          <a ref={terms} className="foot-item" href="/">Términos y condiciones</a>
          <a ref={subscriptions} className="foot-item" href="/">Suscripciones</a>
          <a ref={beta} className="foot-item" href="/">Cuntrat BETA</a>
        </div>
        <div className="us">
          <h4 className="foot-body-title">Nosotros</h4>
          <a ref={aboutUs} className="foot-item" href="/">¿Quienes somos?</a>
          <a ref={contactUs} className="foot-item" href="/">Contacto y soporte</a>
        </div>
      </div>
      <div className="ver-container">
        <span>Cuntrat® 2025</span>
        <span>v1.0.0</span>
      </div>
    </footer>
  );
}

export default Foot;