import Generic from "./Generic";
import Global from "./Global";
import License from "./License";

class Subscription extends Generic {
  /** @param {Subscription|undefined} object */
  constructor(object) {
    super(object);

    const { canBeCancelled, discount, license, method, nextPayment, price, status } = object || {};

    this.setCanBeCancelled(canBeCancelled)
    this.setDiscount(discount);
    this.setLicense(new License(license));
    this.setMethod(method);
    this.setNextPayment(nextPayment);
    this.setPrice(price);
    this.setStatus(status);
  }

  /** Obtains the flag of cancellation.
   * @returns {boolean}
   */
  getCanBeCancelled() {
    return this.canBeCancelled;
  }

  /** Assings the flag of cancellation.
   * @param {boolean} canBeCancelled
   */
  setCanBeCancelled(canBeCancelled) {
    this.canBeCancelled = Boolean(canBeCancelled);
  }

  /** Obtains the discount of the subscription. */
  getDiscount() {
    return this.discount;
  }

  /** Assigns a discount for the subscription. Must be betwen 0 and 1, otherwise undefined will be assigned
   * @param {number} discount A number between 0 and 1.
   */
  setDiscount(discount) {
    this.discount = discount >= 0 && discount <= 1
      ? Number(discount)
      : undefined;
  }

  /** Obtains the license linked to the subscription. */
  getLicense() {
    return this.license;
  }

  /** Assings a license to the subscription.
   * @param {License} license 
   */
  setLicense(license) {
    this.license = license;
  }

  /** Obtains the method that the subscription is being paid.
   */
  getMethod() {
    return this.method;
  }

  /** Assings a method for the subscription be paid.
   * @param {{card: string, exp: string, code: string}} [method] 
   */
  setMethod(method) {
    this.method = method || undefined;
  }

  /** Obtains the next payment date of the subscription. */
  getNextPayment() {
    return this.nextPayment;
  }

  /** Assings a date for the next payment of the subscription. Value passes through Global.formatDateUTC.
   * @param {number} nextPayment 
   */
  setNextPayment(nextPayment) {
    this.nextPayment = Global.formatDateUTC(nextPayment);
  }

  /** Obtains the price wich what the subscription is being paid. */
  getPrice() {
    return this.price;
  }

  /** Assings a price wich will the quantity of the subscription payment.
   * @param {number} price 
   */
  setPrice(price) {
    this.price = isNaN(price) || Number(price) < 0
      ? undefined
      : Number(price);
  }

  /** Obtains the current status of the subscription. */
  getStatus() {
    return this.status;
  }

  /** Assings a status for the subscription.
   * @param {boolean} status If true, subscription is active. Otherwise is cancelled.
   */
  setStatus(status) {
    this.status = Boolean(status);
  }
}

export default Subscription;