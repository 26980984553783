import './styles/license-management.css';
import { useContext, useEffect, useRef, useState } from 'react';
import { globalContext } from './context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import Button from './components/Button';
import Dialog from './components/popups/Dialog';
import ErrHandler from './objects/ErrHandler';
import Global from './objects/Global';
import Hintbox from './components/Hintbox';
import LicenseCard from './components/LicenseCard';
import License from './objects/License';
import PropDisplay from './components/PropDisplay';
import DAOServ from './objects/DAOServ';
import UIRender from './objects/UIRender';
import User from './objects/User';
// Icons.
import * as Icons from './assets/images';
import PayRecords from './popups/PayRecords';

const LicenseManagement = () => {
  // *** useContext ***
  const { currSession, pushMessageHint, setSearchMethod, timezoneOffset } = useContext(globalContext);
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useRef ***
  const today = useRef(/** @type {number} */(undefined));
  // *** useState ***
  const [licenses, setLicenses] = useState(/** @type {License[]} */(undefined));
  const [currentPopup, setCurrentPopup] = useState(/** @type {undefined|1|2|3} */(undefined));
  const [subscription, setSubscription] = useState(
    /** @type {import('./objects/Subscription').default|null} */(undefined)
  );

  const canSubscriptionBeToggled = () => {
    return subscription
      && subscription.getLicense().getClassName() !== 'betas'
      && subscription.getLicense().getClassName() !== 'lmted'
      && subscription.getCanBeCancelled()
      && subscription.getNextPayment() > today.current;
  }

  const dialogActionHandler = () => {
    return DAOServ.post('toggle_subscription_status', { tst: currSession.tst }, 'JSON');
  }

  const dialogOnResolveHandler = () => {
    subscription.setStatus(!Boolean(subscription.getStatus()));

    const type = !subscription.getStatus() ? '' : 'complete';
    const message = !subscription.getStatus()
      ? 'La suscripción fue cancelada'
      : 'La suscripción fue reactivada';

    pushMessageHint({ message, type });
  }

  const getCancelationDialogMessage = () => {
    if (subscription.getStatus()) {
      const expiration = 'Cancelaremos tu suscripción en tu próximo corte ('
        + Global.parseDateUTC(subscription.getNextPayment() + timezoneOffset) + '). ';
      const newSub = ' Para adquirir una nueva suscripción, deberás esperar a que la suscripción actual caduque. ';

      return expiration
        + ' Para entonces, puede que algunas de tus propiedades sean desactivadas.'
        + ` ${newSub}Puedes reactivar tu suscripción antes de tu próximo corte si así lo deseas.`
    }

    return 'Reactivaremos tu suscripción y realizaremos el cobro en tu próximo corte '
      + `(${Global.parseDateUTC(subscription.getNextPayment() + timezoneOffset)}).`
  }

  const getSubscriptionExpiration = () => {
    const className = subscription?.getLicense().getClassName();

    return className === 'lmted' || className === 'betas'
      ? 'N/A'
      : Global.parseDateUTC(subscription?.getNextPayment(), timezoneOffset);
  }

  const getSubscriptionStatus = () => {
    const className = subscription?.getLicense().getClassName();

    return className === 'betas' || subscription?.getStatus()
      ? 'Activa'
      : 'Cancelada'
  }

  useEffect(() => {
    setSearchMethod(false);
    UIRender.scrollTo();
  }, [setSearchMethod]);

  useEffect(() => {
    const getCurrentLicenses = async () => {
      const fetchLicenses = [];
      /** @type {Object[]} */
      const data = await DAOServ.get('get_current_licenses');

      data.forEach(rawLic => {
        const auxLic = new License();
        const keys = Object.keys(rawLic).filter(key => key === 'bidding' || key.includes('max_'));
        // Lic init.
        auxLic.setClassName(rawLic['class_name']);
        auxLic.setDiscount(Number(rawLic['discount']));
        auxLic.setDescription(rawLic['dscr']);
        auxLic.setId(rawLic['idlicense']);
        auxLic.setPrice(Number(rawLic['price']));
        auxLic.setTitle(rawLic['title']);
        keys.forEach(k => auxLic.getFeatures()[k] = rawLic[k])

        fetchLicenses.push(auxLic);
      });

      setLicenses(fetchLicenses);
    }

    const getUserSubscription = async () => {
      // Fetch current subscription.
      setSubscription(await DAOServ.fetchSubscription(currSession.tst, true));
    }

    const getToday = async () => {
      today.current = await DAOServ.getCurrentDay();
      // Fetch current licenses.
      getCurrentLicenses();

      // Get user subscription.
      if (currSession.sessionStatus === User.SESSION_ACT)
        getUserSubscription();
      else
        setSubscription(null);
    }

    if (currSession.sessionStatus)
      getToday();
  }, [currSession, navigate, pushMessageHint]);

  return (<div className="container license-management">
    {subscription !== null && <div className="box borderless current">
      <h2 className="highlight">Tu suscripción.</h2>
      {subscription?.getLicense().getClassName() === 'betas' && <Hintbox
        icon={Icons.InfoIcon}
        message='Eres parte del programa BETA. Te avisaremos cuando exista fecha de expiración.' />}
      <div>
        {<LicenseCard btnClassName='glass'
          license={subscription?.getLicense()} />}
      </div>
      <div className="flex-box m3 wrap">
        {(subscription === undefined || subscription.getLicense().getClassName() !== 'lmted') &&
          <div className="child jc-left">
            <PropDisplay
              header='Estado'
              id='sub-status'
              property={getSubscriptionStatus()}
              waiting={!subscription} />
          </div>
        }
        {(subscription === undefined || subscription.getLicense().getClassName() !== 'lmted') &&
          <div className="child jc-left">
            <PropDisplay
              header={!subscription?.getStatus() || subscription.getLicense().getClassName() === 'betas'
                ? 'Expiración'
                : 'Próximo corte'}
              id='sub-exp'
              property={getSubscriptionExpiration()}
              waiting={!subscription} />
          </div>
        }
      </div>
      {subscription !== undefined && <div className="flex-box wrap">
        <div className="child m3">
          <Button empty
            fullWidth
            icon={Icons.RecordIcon}
            onClick={() => setCurrentPopup(3)}
            value='Historial de pagos' />
        </div>
        {subscription?.getLicense().getClassName() !== 'lmted' && <div className="child m3">
          <Button disabled={subscription.getLicense().getClassName() === 'betas'}
            empty
            fullWidth
            icon={Icons.PayIcon}
            onClick={() => { }}
            value='Gestionar método de pago' />
        </div>}
      </div>}
      {canSubscriptionBeToggled() && <div className="flex-box m3">
        <Button disabled={!canSubscriptionBeToggled()}
          empty
          fullWidth
          icon={subscription.getStatus() ? Icons.CloseIcon : Icons.CheckIcon}
          onClick={() => setCurrentPopup(1)}
          typeRender={subscription.getStatus() ? 'error' : ''}
          value={subscription.getStatus() ? 'Cancelar suscripción' : 'Reactivar suscripción'} />
      </div>}
      {subscription !== undefined
        && subscription.getLicense().getClassName() !== 'lmted'
        && !canSubscriptionBeToggled()
        && <Hintbox icon={Icons.InfoIcon}
          message={subscription.getLicense().getClassName() === 'betas'
            ? 'No puedes cancelar tu suscripción porque formas parte del programa beta.'
            : subscription.getNextPayment() <= today.current
              ? 'No puedes cancelar tu suscripción porque está en periodo de cobro'
              : 'No puedes cancelar tu suscripción porque tienes contratos de arrendamiento'
              + ' activos y/o subastas activas.'
          }
          type='warning' />}
      {subscription !== undefined
        && subscription.getLicense().getClassName() !== 'lmted'
        && !canSubscriptionBeToggled()
        && <div className="flex-box m3">
          <Button empty
            fullWidth
            icon={Icons.InfoIcon}
            onClick={() => setCurrentPopup(2)}
            value='Más información' />
        </div>}
    </div>}
    <div className="box borderless current">
      <h2 className="highlight">Nuestras licencias.</h2>
      <Hintbox message={'No necesitas de una suscripción para ser arrendatario de una o varias propiedades'
        + ', ni para ofertar en subastas.'
      } icon={Icons.WarningIcon}
        type='warning' />
      <h4 className="overset subtitle">Licencias generales</h4>
      {licenses !== undefined && licenses.map(license => {
        const className = license.getClassName();

        if (className !== 'cust2' && !className.includes('htlr')) return <LicenseCard
          key={license.getClassName()}
          license={license}
          showGetButton={license.getClassName() !== subscription?.getLicense().getClassName()} />
        else return undefined;
      })}
      <h4 className="overset subtitle">Licencias para hoteles</h4>
      {licenses !== undefined && licenses.map(license => {
        const className = license.getClassName();

        if (className === 'cust2' || className.includes('htlr')) return <LicenseCard
          key={license.getClassName()}
          license={license}
          showGetButton={license.getClassName() !== subscription?.getLicense().getClassName()} />
        else return undefined;
      })}
    </div>
    {/* Cancel subscription */}
    {currentPopup === 1 && <Dialog action={dialogActionHandler}
      confirmBtn={{
        icon: subscription.getStatus() ? Icons.BanIcon : Icons.CheckIcon,
        onWaitValue: subscription.getStatus() ? 'Cancelando...' : 'Reactivando...',
        type: subscription.getStatus() ? 'error' : 'complete',
        value: subscription.getStatus() ? 'Cancelar' : 'Reactivar'
      }} message={getCancelationDialogMessage()}
      onHide={() => setCurrentPopup()}
      onReject={err => err && pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' })}
      onResolve={dialogOnResolveHandler}
      rejectBtn={{ value: 'Regresar' }}
      renderButtonsEmpty
      renderButtonsRounded
      renderButtonsSwitched />}
    {currentPopup === 2 && <Dialog confirmBtn={{ value: 'Aceptar' }}
      message={subscription.getLicense().getClassName() === 'betas'
        ? 'No puedes cancelar tu suscripción porque eres parte del programa beta. Nosotros te avisaremos'
        + ' cuando exista una fecha de expiración para tu licencia.'
        : subscription.getNextPayment() <= today.current
          ? 'Tu suscripción ya está en periodo de cobro. No puedes cancelarla'
          : 'Una o varias de tus subastas o contratos de arrendamiento finalizan después del próximo corte.'
          + ' Para poder cancelar tu suscripción, espera a finalicen o cancela tus subastas y/o contratos.'
      } onHide={() => setCurrentPopup()}
      renderButtonsEmpty
      renderButtonsRounded
      renderButtonsSwitched />}
    {/* Payment records */}
    {currentPopup === 3 && <PayRecords onHide={() => setCurrentPopup()} />}
  </div>);
}

export default LicenseManagement;