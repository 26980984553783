import '../styles/privacy-and-terms.css';
import { useContext, useEffect, useRef, useState } from "react";
import { globalContext } from "../context/GlobalContext";
import Button from "../components/Button";
import DAOServ from "../objects/DAOServ";
import LoadingPanel from "../components/LoadingPanel";
import UIRender from "../objects/UIRender";
import { CloseIcon } from "../assets/images";

/** DocsObject typedef
 * @typedef {Object} DocsObject
 * @property {string} privacyPolicy The Privacy Policy document.
 * @property {string} termsAndConditions The Terms & Conditions document.
 */

/** Renders a Privacy and Terms popup compo.
 * @param {Object} props The props object.
 * @param {'PRIV'|'TERM'|''} [props.main] The current location.
 */
const PrivacyAndTerms = props => {
  // *** useContext ***
  const { setShowPrivacyAndTerms } = useContext(globalContext);
  // *** useRef ***
  const docContainer = useRef(/** @type {HTMLDivElement} */(undefined));
  const doc = useRef(/** @type {HTMLDivElement} */(undefined));
  const id = useRef('privacy-and-terms');
  const popup = useRef(/** @type {HTMLDivElement} */(undefined));
  // *** useState ***
  const [docs, setDocs] = useState(/** @type {DocsObject} */(undefined));
  const [location, setLocation] = useState(props.main);

  /** @type {React.MouseEventHandler<HTMLDivElement>} */
  const dispose = e => {
    if (e.target === popup.current && UIRender.isHidden(popup.current))
      setShowPrivacyAndTerms();
  }

  const getPopupTitle = () => {
    switch (location) {
      case 'PRIV': return 'Política de privacidad';
      default: return 'Términos y condiciones';
    }
  }

  useEffect(() => {
    if (docContainer.current !== undefined)
      UIRender.scrollTo(0, 0, docContainer.current);
  }, [location]);

  useEffect(() => {
    const auxId = id.current;
    const parent = popup.current?.parentNode;
    const options = { container: true, footer: true, navbar: true };

    UIRender.disableGlobalScroll(auxId);
    UIRender.disableSiblings(popup.current, options);

    return () => {
      UIRender.enableGlobalScroll(auxId);
      UIRender.enableChilds(parent, options);
    }
  }, []);

  useEffect(() => {
    const getDocs = async () => {
      setDocs({
        privacyPolicy: await DAOServ.get('get_privacy_policy'),
        termsAndConditions: await DAOServ.get('get_terms_and_conditions')
      });
    }

    if (!docs) getDocs(); // Get documents.
    else // Location changed.
      doc.current.innerHTML = location === 'PRIV'
        ? docs.privacyPolicy
        : docs.termsAndConditions;
  }, [docs, location]);

  return (<div className="privacy-and-terms popup-wrapper" id={id.current} ref={popup} onAnimationEnd={dispose}>
    <div className="popup">
      <div className="top-bar">
        <h2 className="title highlight">{getPopupTitle()}</h2>
        <Button id='popup-close'
          className='error empty rounded reduced borderless'
          icon={CloseIcon}
          onClick={() => UIRender.hideElement(popup.current)} />
      </div>
      {docs === undefined && <LoadingPanel height='100%' />}
      {docs !== undefined && <div className="popup-content">
        <div className="options-container">
          <Button borderless
            empty
            disabled={location !== 'PRIV'}
            fullWidth
            onClick={() => setLocation('TERM')}
            value='Términos y Condiciones' />
          <Button borderless
            empty
            disabled={location === 'PRIV'}
            fullWidth
            onClick={() => setLocation('PRIV')}
            value='Política de Privacidad' />
        </div>
        <div ref={docContainer} className="doc-container">
          <div ref={doc} className="doc" />
        </div>
      </div>}
    </div>
  </div>);
}

export default PrivacyAndTerms;