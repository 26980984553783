import '../styles/sign-process.css';
import { useContext, useEffect, useRef, useState } from 'react';
import { globalContext } from '../context/GlobalContext';
import * as Icons from "../assets/images";
import Button from "../components/Button";
import ClientSocket from '../objects/ClientSocket';
import Dialog from '../components/popups/Dialog';
import Phase1 from './SPComponents/Phase1';
import Phase2 from './SPComponents/Phase2';
import Phase3 from './SPComponents/Phase3';
import Phase4 from './SPComponents/Phase4';
import UIRender from '../objects/UIRender';
import GuestsManagement from './SPComponents/GuestsManagement';
import User from '../objects/User';
import ErrHandler from '../objects/ErrHandler';
import Estate from '../objects/Estate';
import Contract from '../objects/Contract';
import GenericFile from '../objects/GenericFile';
import Agreement from '../objects/Agreement.js';
import Global from '../objects/Global.js';
import { useNavigate } from 'react-router-dom';

/** GuestObject type definition.
 * @typedef {Object} GuestObject
 * @property {User} data User instance.
 * @property {boolean} isBanned A ban flag.
 * @property {boolean} [signature] A signature flag.
 */

/** SignProcessPropsObject typedef
 * @typedef {Object} SignProcessPropsObject
 * @property {Estate} props.estate An Estate instance. You must pass it if ownerMode
 * is true.
 * @property {() => void} props.onHide A callback function that will be triggered when
 * this component is hidden.
 * @property {(statusChanged?: boolean) => void} props.onResolve A callback function
 * that will be triggered when a new contract is created (pushed to the blockchain).
 * Param will be undefined if ownerMode is false or undefined.
 * @property {boolean} [props.ownerMode] Set this to true to render the component for
 * the Estate's owner (Hall creation, contract manipulation and contract creation).
 * @property {number} props.today The current date, fetched previously by parent compo.
 */

/** Renders a SignProcess compo.
 * @param {SignProcessPropsObject} props The props object
 */
const SignProcess = props => {
  // *** useContext ***
  const { currSession, pushAlertMessage } = useContext(globalContext);
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useRef ***
  const childRef = useRef(/** @type {HTMLDivElement} */);
  const compoId = useRef('sign-process');
  const contract = useRef(new Contract(props.estate?.getContract()));
  const cSocket = useRef(new ClientSocket());
  const currGuests = useRef(/** @type {GuestObject[]} */(undefined));
  const currPhase = useRef(/** @type {number} */(undefined));
  const currSignature = useRef(/** @type {boolean|undefined} */(undefined));
  const estate = useRef(props.estate || new Estate());
  const hash = useRef(/** @type {string} */(undefined));
  const onResolveRef = useRef(props.onResolve);
  const popup = useRef(/** @type {HTMLDivElement} */);
  const userOnBan = useRef(/** @type {string} */(undefined));
  // *** useState ***
  const [disableUI, setDisableUI] = useState(false);
  const [guests, setGuests] = useState(/** @type {GuestObject[]} */([]));
  const [phase, setPhase] = useState(0);
  const [showDialog, setShowDialog] = useState(
    /** @type {import('../components/popups/Dialog').DialogPropsObject} */(undefined)
  );
  const [signature, setSignature] = useState(/** @type {boolean|undefined} */);

  /** @type {React.AnimationEventHandler} */
  const popupAnimationEndHandler = e => {
    if (e.target === popup.current && UIRender.isHidden(popup.current))
      props.onHide();
    else if (e.target === childRef.current) {
      const classList = e.target.classList;
      if (classList.contains('init')) classList.remove('init');
      else if (classList.contains('prev'))
        setPhase(typeof phase === 'object' ? phase.prev : phase > 1 ? phase - 1 : phase);
      else if (classList.contains('next'))
        setPhase(phase < 3 ? phase + 1 : phase);
      else if (classList.contains('guests-management'))
        setPhase({ prev: phase });
    }
  }

  /** @type {React.MouseEventHandler} */
  const disposeBtnClickHandler = () => {
    if (phase === 0 || phase === 3) UIRender.hideElement(popup.current);
    else setShowDialog({
      confirmBtn: { icon: Icons.LogoutIcon, type: 'error', value: 'Salir de todos modos' },
      id: `${compoId.current}-dialog-popup`,
      ignoreOnResolveHideAnimation: true,
      message: props.ownerMode
        ? 'El proceso será descartado y desconectarás a los invidatos de la sala.'
        : 'Tu proceso en el contrato será descartado y te desconectarás.',
      onResolve: () => {
        if (cSocket.current.isOpen()) cSocket.current.close();

        UIRender.hideElement(popup.current);
      }, rejectBtn: { value: 'Quedarme aquí' }
    });
  }

  // First render useEffect.
  useEffect(() => {
    const id = compoId.current;
    const options = { footer: true, navbar: true }
    const parent = popup.current?.parentNode;

    // Contract's agreement init.
    contract.current.setAgreement(new Agreement());

    UIRender.disableGlobalScroll(id);
    UIRender.disableSiblings(popup.current, options);

    return () => {
      UIRender.enableGlobalScroll(id);
      UIRender.enableChilds(parent, options, id);
    }
  }, []);

  // socket useEffect
  useEffect(() => {
    const auxSocket = cSocket.current;
    // Initialize Client Socket.
    // * on socket close *
    cSocket.current.setOnCloseCallBack(e => {
      // Phase is already the end phase.
      if (currPhase.current === 3 || cSocket.current.isOpen()) return;

      if (ErrHandler.getCode(e.reason) === ErrHandler.CODES.TST_FAIL) {
        // User session has been opened on another device.
        UIRender.reloadPage();
        return;
      }

      let msg;

      switch (e.code) {
        case ClientSocket.D_NORMAL: {
          msg = props.ownerMode ? 'Sala finalizada.' : 'Has salido de la sala.';
          break;
        } case ClientSocket.D_GOING_AWAY: {
          if (ErrHandler.getCode(e.reason) === ErrHandler.CONNECTION_LOST)
            msg = ErrHandler.parseError(e.reason);
          else
            msg = !props.ownerMode
              ? 'El anfitrión ha abandonado la sala.'
              : ClientSocket.getDisconnectMsg(e.code);
          break;
        } default: msg = ClientSocket.getDisconnectMsg(e.code);
      }

      pushAlertMessage({ message: msg, type: e.code !== 1000 ? 'error' : '' });
      setShowDialog();
      UIRender.hideElement(popup.current);
    });

    cSocket.current.setOnErrorCallback(e => {
      if (!cSocket.current.isOpen()) {
        pushAlertMessage({ message: ClientSocket.getDisconnectMsg(), type: 'error' });
        UIRender.hideElement(popup.current);
      }

      setShowDialog();
    })

    cSocket.current.setOnMessageCallback(e => {
      setDisableUI(false); // Enables UI because it might have been disabled.

      // Phase is already the end phase. Socket will close automatically.
      if (currPhase.current === 3) return;

      // Message parse
      const data = ClientSocket.parseMessage(e.data);

      switch (data.code) {
        case 'FORBIDDEN': { // Forbidden action.
          pushAlertMessage({ message: 'Acción prohibida', type: 'error' });
          break;
        } case 'GUEST_JOINED': { // A guest joined.
          currGuests.current.push({
            data: new User({ username: data.params.name }),
            isBanned: false,
            signature: undefined
          });

          setGuests([...currGuests.current]);
          pushAlertMessage({ message: `${data.params.name} se unió a la sala.` });

          break;
        } case 'GUEST_LEFT': { // A guest left.
          const index = currGuests.current.findIndex(guest => guest.data.getUsername() === data.params.name);

          currGuests.current.splice(index, 1);
          setGuests([...currGuests.current]);
          pushAlertMessage({
            message: `${data.params.name} abandonó a la sala.`,
            type: 'warning'
          });

          // Dialog verification. Close if guest that left is about to be banned.
          if (userOnBan.current === data.params.name)
            setShowDialog();

          break;
        } case 'GUEST_BANNED': { // A guest got banned (only for host).
          currGuests.current.find(guest => guest.data.getUsername() === data.params.name)
            .isBanned = true;

          setGuests([...currGuests.current]); // Refreshes guests state array
          pushAlertMessage({
            message: `${data.params.name} fue baneado de la sala.`,
            type: 'warning'
          });

          break;
        } case 'GUEST_SIGNATURE_REJECT': { // A guest rejected the contract.
          if (currSession.username === data.params.name) { // Current guest rejected the contract.
            pushAlertMessage({ message: 'Has rechazado el contrato', type: 'error' });
            setSignature(false);
          } else { // Another guest signed the contract.
            const guest = currGuests.current.find(g => g.data.getUsername() === data.params.name);
            guest.signature = false;
            pushAlertMessage({ message: `${guest.data.getUsername()} rechazó el contrato`, type: 'error' });
            setGuests([...currGuests.current]);
          }

          break;
        } case 'GUEST_SIGNATURE_RESOLVE': { // A guest accepted the contract.
          if (currSession.username === data.params.name) { // Current guest signed the contract.
            pushAlertMessage({ message: 'Has aceptado y firmado el contrato' });
            setSignature(true);
          } else { // Another guest signed the contract.
            const guest = currGuests.current.find(g => g.data.getUsername() === data.params.name);
            guest.signature = true

            if (props.ownerMode || currSignature.current === true) {
              pushAlertMessage({ message: `${guest.data.getUsername()} aceptó y firmó el contrato` });
            }

            setGuests([...currGuests.current]);
          }

          break;
        } case 'GUEST_UNBANNED': { // A guest was unbaned (only for host).
          const index = currGuests.current.findIndex(guest => guest.data.getUsername() === data.params.name);

          currGuests.current.splice(index, 1);

          setGuests([...currGuests.current]);
          pushAlertMessage({ message: `"${data.params.name}" fue desbaneado.` });

          break;
        } case 'HALL_CONNECTED': { // The host created a hall or the client joined a hall.
          cSocket.current.setKey(data.params.key);
          setPhase(currPhase.current + 1);

          if (!props.ownerMode) { // Only for guests.
            // Setting estate id into estate object.
            if (!estate.current)
              estate.current = new Estate({ id: data.params.estate });

            estate.current.setId(data.params.estate);
            estate.current.setOwner(new User({ username: data.params.host }));

            if (data.params.guests) {// Receives the connected guests list.
              currGuests.current = [];
              currGuests.current.push({
                data: new User({ username: data.params.host }),
                isBanned: false
              });

              for (let i = 0; i < data.params.guests.length; i++) {
                currGuests.current.push({
                  data: new User({ username: data.params.guests[i] }),
                  isBanned: false
                });
              }

              setGuests([...currGuests.current]);
            }
          }

          pushAlertMessage({
            message: props.ownerMode ?
              'Has creado una sala.'
              : 'Te has unido a la sala.',
            type: 'complete'
          });

          break;
        } case 'PHASE_CONTINUE': { // The host sent contract for review or created the contract.
          if (currPhase.current === 1) { // Review contract phase.
            if (!props.ownerMode) {
              // Parse agreement.
              contract.current.setCharge(data.params['agreement']['charge']);
              contract.current.getAgreement().setId(data.params['agreement']['id']);
              contract.current.setInclServs(data.params['agreement']['includedServices'] ?? []);
              contract.current.setPayAmount(data.params['agreement']['payAmount']);
              contract.current.setPayFrequency(data.params['agreement']['payFrequency']);
              contract.current.getAgreement().setStartDate(data.params['agreement']['startDate']);
              contract.current.setTermMethod(data.params['agreement']['termMethod']);
              contract.current.setTerm(data.params['agreement']['term']);
              data.params['agreement']['services'].forEach(s => estate.current.addService(s));
              // Parse files.
              contract.current.getAgreement().setFiles(data.params['files'].map(f => {
                return new GenericFile({
                  name: f.name,
                  pathname: f.path,
                  size: f.size
                });
              }));
            }

            pushAlertMessage({
              message: props.ownerMode
                ? 'Has enviado el contrato para revisión'
                : 'El anfitrión ha enviado el contrato',
            });
          } else if (currPhase.current === 2) {// Contract created.
            hash.current = data.params['hash'];
            onResolveRef.current();
          }

          setPhase(currPhase.current + 1);
          setShowDialog();

          break;
        } case 'PHASE_RETURN': { // The host requested phase return or all guests left the hall.
          if (currPhase.current === 1) return; // Won't do anything if already at phase 2.

          if (data.message === 'No guests in the hall') {
            pushAlertMessage({
              message: 'Ningún invitado en la sala. Yendo un paso atrás...',
              type: 'warning'
            });
          } else {
            currGuests.current.forEach(guest => guest.signature = undefined);

            setGuests([...currGuests.current]);

            if (!props.ownerMode) {
              setSignature(undefined);

              pushAlertMessage({
                message: 'El anfitrión ha ido un paso atrás',
                type: 'warning'
              });
            }
          }

          setPhase(currPhase.current - 1);
          setShowDialog();
          break;
        } case 'INSTRUCTION_REJECT': { // Instruction reject.
          pushAlertMessage({ message: data.message, type: 'error' });
          break;
        } default: { // An unknown message was received.
          pushAlertMessage({
            message: `Instrucción '${data.code}' desconocida.`,
            type: 'warning'
          });

          if (Global.DEV_MODE) console.log(data);
        }
      }
    });

    return () => {
      if (auxSocket?.isOpen()) auxSocket.close();
    }
  }, [currSession, pushAlertMessage, props.ownerMode]);

  // Guests change
  useEffect(() => {
    if (guests !== currGuests.current) currGuests.current = guests;
  }, [guests]);

  // Current phase storage.
  useEffect(() => {
    if (phase !== currPhase.current) {
      currPhase.current = phase;
      setDisableUI(false);
    };
  }, [phase]);

  // Signature.
  useEffect(() => {
    if (signature !== currSignature.current) currSignature.current = signature;
  }, [signature]);

  return (
    <div ref={popup}
      className="popup-wrapper sign-process"
      id={compoId.current}
      onAnimationEnd={popupAnimationEndHandler}>
      <div className="popup">
        <div className="top-bar">
          <h2 className="title highlight">Firma de contrato</h2>
          <Button borderless
            disabled={disableUI}
            empty
            id="sign-process-close"
            icon={Icons.CloseIcon}
            onClick={disposeBtnClickHandler}
            reduced
            rounded
            typeRender='error' />
        </div>
        {typeof phase === 'object' && <GuestsManagement ref={childRef}
          disableUI={disableUI}
          guests={guests}
          ownerMode={props.ownerMode}
          setDisableUI={setDisableUI}
          setShowDialog={setShowDialog}
          setUserOnBan={uN => userOnBan.current = uN}
          socket={cSocket.current} />}
        {phase === 0 && <Phase1 ref={childRef}
          disableUI={disableUI}
          estate={estate.current}
          onAbort={() => UIRender.hideElement(popup.current)}
          ownerMode={props.ownerMode}
          setDisableUI={setDisableUI}
          socket={cSocket.current} />}
        {phase === 1 && <Phase2 ref={childRef}
          contract={contract.current}
          disableUI={disableUI}
          estate={estate.current}
          guests={guests}
          hallKey={cSocket.current.getKey()}
          ownerMode={props.ownerMode}
          setDisableUI={setDisableUI}
          socket={cSocket.current}
          today={props.today} />}
        {phase === 2 && <Phase3 contract={contract.current}
          disableUI={disableUI}
          estate={estate.current}
          guests={guests}
          onNextPhase={() => setPhase(phase + 1)}
          onResolve={props.onResolve}
          ownerMode={props.ownerMode}
          ref={childRef}
          setDisableUI={setDisableUI}
          setShowDialog={setShowDialog}
          sign={setSignature}
          signature={signature}
          socket={cSocket.current}
          updateHash={newHash => hash.current = newHash} />}
        {phase === 3 && <Phase4 onCloseBtnClick={disposeBtnClickHandler}
          image={estate.current.getImages()[0].getURLData()}
          onOpenContractBtnClick={() => {
            props.onHide();
            navigate(`${Global.PATH_CONTRACT}/${hash.current}`);
          }} ownerMode={props.ownerMode}
          socket={cSocket.current} />}
      </div>
      {showDialog && <Dialog action={showDialog.action}
        confirmBtn={showDialog.confirmBtn}
        ignoreOnResolveHideAnimation={showDialog.ignoreOnResolveHideAnimation}
        message={showDialog.message}
        onHide={() => setShowDialog()}
        onResolve={showDialog.onResolve}
        onReject={showDialog.onReject}
        rejectBtn={showDialog.rejectBtn}
        renderButtonsEmpty
        renderButtonsRounded
        renderButtonsSwitched={showDialog.renderButtonsSwitched} />}
    </div>
  );
}

export default SignProcess;