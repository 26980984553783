import './styles/button.css';
import { useRef } from "react";
import Global from "../objects/Global";

/** FileSubmitObject type definition.
 * @typedef {Object} FileSubmitObject
 * @property {string[]} [fileTypes] A list of file types admited for submit. Set
 * to undefined or empty to ifnore this filter.
 * @property {(f: File) => void} onChange A callback function that will be
 * triggered when a new file is loaded.
 */

/** Renders a button compo.
 * @param {Object} props The props object.
 * @param {boolean} [props.animated] Animtates compo's icon on hover. Does not work properly when
 * icon is rotated.
 * @param {boolean} [props.borderless] Draws compo borderless.
 * @param {string} [props.className] Custom class name for the compo.
 * @param {boolean} [props.rounded] Compo renders rounded.
 * @param {boolean} [props.disabled] Disables de compo.
 * @param {boolean} [props.empty] Renders backgroundless compo.
 * @param {FileSubmitObject} [props.fileSubmit] If object is defined, button will behave as a
 * file selector and will ignore onClick param.
 * @param {boolean} [props.fullWidth] If true, compo's width will be set to 100%
 * @param {string} [props.form] Deprecated. The form's id wich the button belongs. Type must be set
 * as 'submit'. Won't have any effect if fileSubmit is not undefined.
 * @param {*} [props.icon] An icon to render inside the button.
 * @param {string} [props.id] The buttons id.
 * @param {boolean} [props.isWaiting] Disables the compo in waiting state
 * (renders an animated loading figure and onWaitingValue).
 * @param {React.MouseEventHandler<HTMLButtonElement>|'mailto:...'} [props.onClick] A
 * function MouseEvent handler or a 'mailto' directive. Won't have any effect if fileSubmit is
 * defined, button is disabled or button is in waiting state.
 * @param {string} [props.onWaitValue] A value for the compo when it's on wait
 * state.
 * @param {boolean} [props.reduced] Compo renders reduced.
 * @param {React.LegacyRef} [props.reference] A useRef instance for the compo.
 * @param {90|180|270} [props.rotation] Rotates the compo's icon.
 * @param {boolean} [props.stopPropagation] If true, stopPropagation will be triggered.
 * @param {string} [props.title] A title shown on hover.
 * @param {'button'|'submit'|'reset'} props.type Compo type.
 * @param {'error'|'complete'|'warning'|'glass'|'dark-glass'|'white-stone'} [props.typeRender]
 * The button type. Depending of its type, its colouring will change. If unset, button
 * will render in purple (default button).
 * @param {string} [props.value] A value for the button.
 */
const Button = props => {
  // *** useRef ***
  const animFig = useRef(Global.getRandom(['f1', 'f2', 'f3']));
  const auxInput = useRef(/** @type {HTMLInputElement|HTMLAnchorElement} */(undefined));

  /** @type {React.MouseEventHandler<HTMLButtonElement>} */
  const buttonHandleOnClick = e => {
    if (props.stopPropagation)
      e.stopPropagation();

    if (!props.disabled && !props.isWaiting)
      auxInput.current.click();
  }

  /** @type {React.ChangeEventHandler<HTMLInputElement>} */
  const fileInputHandleOnChange = e => {
    const file = e.target.files[0]; // Obtain new file.
    e.target.value = null;

    props.fileSubmit.onChange(file);
  }

  const getClassName = () => {
    let className = 'button';

    if (props.animated) className = `${className} animate`
    if (props.borderless) className = `${className} borderless`
    if (props.rounded) className = `${className} rounded`;
    if (props.empty) className = `${className} empty`
    if (props.fullWidth) className = `${className} full-width`
    if (props.reduced) className = `${className} reduced`;
    if (props.rotation) className = `${className} rotate-img-${props.rotation}`
    if (props.typeRender) className = `${className} ${props.typeRender}`;
    if (props.className) className = `${className} ${props.className}`;

    return className;
  }

  return (
    <button
      onClick={props.fileSubmit || /^mailto:.+$/.test(`${props.onClick}`) ? buttonHandleOnClick : props.onClick}
      onAnimationEnd={e => e.stopPropagation()}
      ref={props.reference}
      disabled={props.disabled || props.isWaiting}
      type={props.type && props.type !== 'file' ? props.type : 'button'}
      className={getClassName()}
      id={props.id}
      title={props.title} >
      {/* Button icon */}
      {!props.isWaiting && props.icon && <img
        id={props.id ? `${props.id}-img` : undefined}
        src={props.icon} alt="button-img" />}
      {/* Button loading figure */}
      {props.isWaiting && <span
        id={props.id ? `${props.id}-figure` : undefined}
        className={`animated-figure ${animFig.current} mini gray`} />}
      {/* Button value */}
      {((!props.isWaiting && props.value) || (props.isWaiting && props.onWaitValue)) && <p
        id={props.id ? `${props.id}-p` : undefined}>{!props.isWaiting ? props.value : props.onWaitValue}</p>}
      {/* File input */}
      {props.fileSubmit !== undefined && <input
        ref={auxInput}
        type="file"
        accept={props.fileSubmit.fileTypes?.join(', ')}
        id={props.id ? `${props.id}-file-input` : undefined}
        hide=''
        onChange={fileInputHandleOnChange} />}
      {/* Mail send */}
      {props.fileSubmit === undefined && /^mailto:.+$/.test(`${props.onClick}`)
        && <a ref={auxInput} href={props.onClick} hide='' >mail</a>
      }
    </button>
  );
}

export default Button;