
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { globalContext } from "../../context/GlobalContext";
import Button from "../../components/Button";
import ErrHandler from '../../objects/ErrHandler';
import Global from '../../objects/Global';
import DAOServ from '../../objects/DAOServ.js';
import UIRender from '../../objects/UIRender';
// Icons.
import { PplCapIcon, SignIcon, VickyImg } from "../../assets/images";

/** Type Definition.
 * @typedef {import ('../../objects/Estate.js').default} Estate
 * @typedef {import ('../../objects/ClientSocket.js').default} ClientSocket
 * @typedef {Object} Props The props definition.
 * @property {boolean} disableUI If true, UI will be disabled.
 * @property {Estate} [estate] An estate object. Will be used if ownerMode is true.
 * @property {() => void} onAbort When hall FAILS to open a hall, this callback function will be
 * activated.
 * @property {boolean} [ownerMode] If true. phase 1 will be rendered for the owner.
 * @property {React.LegacyRef<HTMLDivElement>} [ref] A reference for the root compo.
 * @property {(e: boolean) => void} setDisableUI A callback function from parent to  disable UI.
 * @property {ClientSocket} socket The given client socket from parent.
 */

/** Renders a Phase1 compo for SignProcess component.
 * @type {React.ForwardRefRenderFunction<?, Props>}
 */
const Phase1 = forwardRef((props, ref) => {
  // *** useContext ***
  const { currSession, pushMessageHint } = useContext(globalContext);
  // *** useRef ***
  const estate = useRef(props.estate);
  const input = useRef({ i1: '', i2: '' });
  /** @type {React.LegacyRef<HTMLButtonElement>} */
  const btnCompo = useRef();
  /** @type {React.LegacyRef<HTMLInputElement>[]} */
  const inputCompo = [useRef(), useRef()];
  // *** useState ***
  const [unlockSubmit, setUnlockSubmit] = useState(props.ownerMode);

  const buttonHandleOnSubmit = async () => {
    props.setDisableUI(true);

    if (props.ownerMode) { // Create a new hall.
      await DAOServ.post('request_open_hall', { tst: currSession.tst, idEstate: props.estate.getId() }, 'JSON')
        .then(() => {
          // Socket initialize.
          props.socket.setEstateId(estate.current.getId());
          props.socket.setTST(currSession.tst);
          props.socket.connect();
        }).catch(err => {
          pushMessageHint({
            message: ErrHandler.parseError(err),
            type: 'error'
          });

          if (ErrHandler.getCode(err) === ErrHandler.CODES.FAIL) props.onAbort();
          else props.setDisableUI(false);
        });
    } else { // Join a hall
      const hallKey = [input.current.i1, input.current.i2].join('-');

      await DAOServ.post('request_join_to_hall', { tst: currSession.tst, hallKey }, 'JSON')
        .then(() => {
          props.socket.setTST(currSession.tst); // Assings tst to socket.
          props.socket.setKey(hallKey); // Asings key to socket.
          props.socket.connect(); // Connects socket.
        }).catch(err => {
          let msg;

          if (ErrHandler.getCode(err) === ErrHandler.NOT_FOUND)
            msg = `No se encontró la sala con el código "${[input.current.i1, input.current.i2].join('-')}"`;
          else
            msg = ErrHandler.parseError(err);

          inputCompo.forEach(input => input.current.classList.add('error'));
          pushMessageHint({ message: msg, type: 'error' });
          props.setDisableUI(false);
        });
    }
  }

  /**
   * @type {React.ChangeEventHandler}
   * @param {string} id
   */
  const inputHandleOnChange = (e, id) => {
    if (props.disableUI) {
      inputCompo[0].current.value = input.current.i1;
      inputCompo[1].current.value = input.current.i2;

      return;
    }

    /** @type {string|string[]} */
    let value = e.target.value?.toUpperCase() || '';

    inputCompo.forEach(input => input.current.classList.remove('error'));

    if (Global.REGEXP_SIGN_CODE.test(value)) { // Paste info has code form.
      value = value.split('-');
      inputCompo[0].current.value = value[0];
      inputCompo[1].current.value = value[1];
      input.current.i1 = value[0];
      input.current.i2 = value[1];
    } else {
      value = value.replace(/[^A-Z0-9]/g, '');
      value = value.length <= 4 ? value : value.slice(0, 4);
      e.target.value = value;

      if (id === 'block-1') {
        input.current.i1 = value;
        // Request focus.
        if (value.length === 4) {
          if (input.current.i2.length < 4) UIRender.setFocus(inputCompo[1].current);
        }
      } else if (id === 'block-2') {
        input.current.i2 = value;
        // Request focus.
        if (value.length === 4) {
          if (input.current.i1.length < 4) UIRender.setFocus(inputCompo[0].current);
        }
      }
    }

    // Unlock submit request.
    setUnlockSubmit(Global.REGEXP_SIGN_CODE.test([input.current.i1, input.current.i2].join('-')));
  }

  /** @type {React.FocusEventHandler<HTMLInputElement>} */
  const inputHandleOnFocus = e => {
    UIRender.selectElementText(e.target);
  }

  // Button unlock change useEffect.
  useEffect(() => {
    if (unlockSubmit) UIRender.setFocus(btnCompo.current);
  }, [unlockSubmit]);

  if (props.ownerMode) return (
    <div className="popup-content sign-process-content" id='phase-1' ref={ref}>
      <div className="pet-container">
        <img src={VickyImg} alt="vicky" />
      </div>
      <div className="phase-container">
        <p className="dialoge">
          Al seleccionar el  siguiente botón, recibirás un código de invitación que debes
          compartir a tus arrendatarios para que puedan unirse a la sala.
        </p>
        <div className="code-input-container">
          <Button
            className='animate empty'
            disabled={!unlockSubmit}
            icon={PplCapIcon}
            isWaiting={props.disableUI}
            onClick={buttonHandleOnSubmit}
            onWaitValue='Solicitando...'
            reference={btnCompo}
            value='Crear sala' />
        </div>
      </div>
    </div>
  );
  else return (
    <div className="popup-content sign-process-content" id='phase-1' ref={ref}>
      <div className="pet-container">
        <img src={VickyImg} alt="vicky" />
      </div>
      <div className="phase-container">
        <p className="dialoge">
          Ahora vamos a empezar el proceso de firma de contrato. Ingresa el código de invitación
          que tu arrendador te ha proporcionado para unirte a la sala
        </p>
        <div className="code-input-container">
          <h5 className="overset">Código de invitación</h5>
          <div className="flex-box jc-center">
            <div className="child auto-width">
              <input
                ref={inputCompo[0]}
                disabled={props.disableUI}
                type="text"
                className='uppercase'
                onChange={e => inputHandleOnChange(e, 'block-1')}
                onFocus={inputHandleOnFocus}
                placeholder='EJ3M' />
            </div>
            <div className="child auto-width m5">
              <h3 className="overset">—</h3>
            </div>
            <div className="child auto-width">
              <input
                ref={inputCompo[1]}
                disabled={props.disableUI}
                type="text"
                className='uppercase'
                onChange={e => inputHandleOnChange(e, 'block-2')}
                onFocus={inputHandleOnFocus}
                placeholder='PL00' />
            </div>
          </div>
          <Button
            className='animate empty'
            disabled={!unlockSubmit}
            icon={SignIcon}
            isWaiting={props.disableUI}
            onClick={buttonHandleOnSubmit}
            onWaitValue='Uniéndote...'
            reference={btnCompo}
            value='Unirme' />
        </div>
      </div>
    </div>
  );
})

export default Phase1;