import Generic from "./Generic";

/** Contract object designed for estates on/for lease. Can be used as the Agreement class */
class Contract extends Generic {
  /** Definex remark max length */
  static get REMARK_LENGTH() { return 30 }
  /** Finalize contract because of an error (being 1). */
  static get END_ERROR() { return 1 }
  /** Finalize contract because of eviction (being 3). */
  static get END_EVICTION() { return 3 }
  /** Finalize contract because of lessee request (being 2). */
  static get END_LESSEE_REQ() { return 2 }
  /** Specify extra charge same as pay amount. */
  static get CHARGE_SAME_AS_PAY() { return -1 }
  /** Specify custom extra charge. */
  static get CHARGE_CUSTOM() { return -2 }
  /** Specifies lease payment frequency is only once. */
  static get PAY_FREQ_UNIQUE() { return 1 }
  /** Specifies lease payment frequency is daily. */
  static get PAY_FREQ_DAILY() { return 2 }
  /** Specifies lease payment frequency is monthly. */
  static get PAY_FREQ_MONTHLY() { return 3 }
  /** Specifies lease payment frequency is yearly. */
  static get PAY_FREQ_YEARLY() { return 4 }
  /** Specifies agreement term is estimated per days. */
  static get TERM_METHOD_DAY() { return 1 }
  /** Specifies agreement term is estimated per months */
  static get TERM_METHOD_MONTH() { return 2 }
  /** Specifies agreement term is estimated per years. */
  static get TERM_METHOD_YEAR() { return 3 }

  /** @param {Contract} [object] A Contract object to be copied. */
  constructor(object) {
    super(object);

    const {
      agreement,
      chargeAtStart,
      inclServs,
      payAmount,
      payFrequency,
      term,
      termMethod
    } = object ?? {};

    this.setAgreement(agreement)
    this.setCharge(chargeAtStart);
    this.setInclServs(Array.isArray(inclServs) ? Array.from(inclServs) : []);
    this.setPayAmount(payAmount);
    this.setPayFrequency(payFrequency);
    this.setTermMethod(termMethod);
    this.setTerm(term);
  }
  // ********************************************************************************************** STATIC *
  /** Checks if given number is a valid extra charge. Contract.CHARGE_CUSTOM is considered as invalid.
   * @param {number} number 
   */
  static isExtraChargeValid(number) {
    return number === undefined
      || number === Contract.CHARGE_SAME_AS_PAY
      || number > 0;
  }

  /** Checks if given number is a valid term method.
   * @param {number} term
   */
  static isTermMethodValid(term) {
    return term >= Contract.TERM_METHOD_DAY && term <= Contract.TERM_METHOD_YEAR;
  }

  /** Checks if given term is valid for a contract. For a term to be valid, must be greater than zero.
   * If term method equals TERM_METHOD_DAY, term must be 31 or less to be valid.If term method equals
   * TERM_METHOD_MONTH, term must be 11 or less to be valid. If term method equals TERM_METHOD_YEAR,
   * term must equal 10 or less to be valid.
   * @param {Contract|number} contrTermMeth A contract instance or a Term Method value.
   * @param {number} term A number, being the term to test.
   */
  static isTermValid(contrTermMeth, term) {
    if (!term || !contrTermMeth) return false;

    const termMethod = contrTermMeth instanceof Contract
      ? contrTermMeth.getTermMethod()
      : contrTermMeth;

    switch (termMethod) {
      case Contract.TERM_METHOD_DAY: return term > 0 && term <= 31;
      case Contract.TERM_METHOD_MONTH: return term > 0 && term <= 11;
      case Contract.TERM_METHOD_YEAR: return term > 0 && term <= 10
      default: return false;
    }
  }

  // *********************************************************************************** SETTERS & GETTERS *
  /** Obtains the agreement attached to this contract. Might be undefined. */
  getAgreement() {
    return this.agreement;
  }

  /** Assings an agreement attached to this contract.
   * @param {import('./Agreement').default} agreement 
   */
  setAgreement(agreement) {
    this.agreement = agreement;
  }

  /** Obtains the extra pay amount required in the first lease payment.
   * @returns undefined if not required or not setted. 'Contract.CHARGE_SAME_AS_PAY' if
   * charge is the same amount that pay amount. 'Contract.CHARGE_CUSTOM' or any number
   * greater than zero if is custom input and is not
   * setted yet.
   */
  getCharge() {
    return this.chargeAtStart;
  }

  /** Obtains the charge (extra charge or charge at start) type. Can return
   * Contract.CHARGE_SAME_AS_PAY, Contract.CHARGE_CUSTOM
   * or zero if not required or unset. This function won't verify sell method from Estate.
   */
  getChargeType() {
    switch (this.getCharge()) {
      case undefined: return 0;
      case Contract.CHARGE_SAME_AS_PAY: return Contract.CHARGE_SAME_AS_PAY;
      default: return Contract.CHARGE_CUSTOM;
    }
  }

  /**
   * Assigns an extra pay amount required in the first lease payment.
   * @param {number} extra If zero, undefined will be assigned. You can assign
   * 'Contract.CHARGE_SAME_AS_PAY' to specify that extra amount is the same as pay amount.
   */
  setCharge(extra) {
    this.chargeAtStart =
      extra === Contract.CHARGE_SAME_AS_PAY
        || extra === Contract.CHARGE_CUSTOM
        || extra > 0
        ? Number(extra) : undefined;
  }

  /** Obtain the included services array from the contract, each one defined by PSCollection globals.
   * @returns {string[]} The included services array.
   */
  getInclServs() {
    return this.inclServs;
  }

  /** Assign an array of included services.
   * @param {string[]} inclServs An array. Can be empty.
   */
  setInclServs(inclServs) {
    this.inclServs = Array.isArray(inclServs) ? inclServs : [];
  }

  /**
   * Obtains the pay amount of the estate.
   * @returns {number} Estate's pay amount. 
   */
  getPayAmount() {
    return this.payAmount;
  }

  /**
   * Assigns a pay amount for the estate.
   * @param {number} payAmount 
   */
  setPayAmount(payAmount) {
    this.payAmount = !isNaN(payAmount) && payAmount > 0
      ? Number(payAmount)
      : undefined;
  }

  /** Obtains the pay frequency for the estate (only valid for ON_LEASE estates).
   * @returns {number}
   */
  getPayFrequency() {
    return this.payFrequency;
  }

  /** Assigns a pay frequency for the estate
   * @param {number} payFrequency 
   */
  setPayFrequency(payFrequency) {
    this.payFrequency = payFrequency === null || isNaN(payFrequency)
      ? undefined
      : Number(payFrequency);
  }

  /** Obtains the term of this contract. This is a number assigned by the user that means the
   * contract's duration (could be days, months or years. It depends of contract's term method).
   */
  getTerm() {
    return this.term;
  }

  /** Assigs the term of this contract. Term method must be assigned, otherwise undefined will be
   * assigned. Given term will pass through isTermValid method (that passes through isTermMethodValid
   * method too). If returns false, undefined will be assigned.
   * @param {number} term A number asigned by user.
   */
  setTerm(term) {
    this.term = Contract.isTermValid(this, term) ? term : undefined;
  }

  /** Obtains the contract's term method (a number that means days, months or years),
   * specified by Contract.TERM_METHOD_...
   * @returns {number|undefined}
   */
  getTermMethod() {
    return this.termMethod;
  }

  /** Assigns a contract's term method (a number that means days, months or years).
   * @param {number} termMethod A number assigned by 'Contract.TERM_METHOD_...'.
   */
  setTermMethod(termMethod) {
    this.termMethod = termMethod >= Contract.TERM_METHOD_DAY && termMethod <= Contract.TERM_METHOD_YEAR
      ? Number(termMethod)
      : undefined;
  }
}

export default Contract;