import md5 from "md5";
import CryptoJS from "crypto-js";

class Global {
  /** Common array separator, being '|' */
  static get ARR_SEPARATOR() { return '|' };
  static get CIPHER_ALGORITHM() { return 'aes-256-cbc' }
  /** Prefixes fetch location */
  static get FETCH_PREFIXES() { return 'https://gist.githubusercontent.com/anubhavshrimal/75f6183458db8c453306f93521e93d37/raw/f77e7598a8503f1f70528ae1cbf9f66755698a16/CountryCodes.json' }
  /** The default location for GoogleMap render */
  static get MX_LOCATION() { return { lat: 23.634501, lng: -102.552784 } }// *** Regular Expressions ***
  // **** Filters ****
  /** Regular expression used to exclude all symbols (only accepts unsymbolized letters and numbers) */
  static get REGEXP_FILTER_ALL_SYMBOLS() { return /[^A-Za-z0-9]/g }
  /** Regular expression used to filter decimal numbers */
  static get REGEXP_FILTER_DECIMAL() { return /[^0-9.]/g }
  /** Regular expression used to filter an E-Mail (doesn't filter uppercase letters) */
  static get REGEXP_FILTER_EMAIL() { return /[^\w0-9.\-_@]/g }
  /** Regular expression used to exclude forbidden symbols */
  static get REGEXP_FILTER_FORBIDDEN_SYMBOLS() { return /[^\wÀ-ÿ\s¡!'"#@$%&/()=¿?+*.,:;-]+/g }
  /** Regular expression used to filter integer numbers */
  static get REGEXP_FILTER_INTEGER() { return /[^0-9]/g }
  /** Regular expression used to exclude symboled letters */
  static get REGEXP_FILTER_SYMBOLED_LETTERS() { return /[À-ÿ]/g; }
  /** Regular expression used to exclude some symbols (symbolized letters and spaces are exceptions) */
  static get REGEXP_FILTER_SYMBOLS() { return /[^A-Za-zÀ-ÿ0-9\s]/g; }
  /** Regular expression used to filter usernames */
  static get REGEXP_FILTER_USERNAME() { return /[^\d\w.*-]/ }
  // ***** Validations *****
  /** Regular expression used to validate integer numbers */
  static get REGEXP_INTEGER() { return /^[0-9]+$/ }
  /** Regular expression used to validate decimal numbers */
  static get REGEXP_DECIMAL() { return /^([0-9]+)?(\.([0-9]+))?$/ }
  /** Regular expression that excludes forbidden symbols */
  static get REGEXP_NO_FORBIDDEN_SYMBOLS() { return /^[\wÀ-ÿ\s¡!'"#@$%&/()=¿?+*.,:;-]+$/ }
  /** Excludes all symbols. Only accepts letters, symboled letters, numbers and spaces */
  static get REGEXP_NO_SYMBOLS() { return /^[A-Za-zÀ-ÿ0-9\s]*$/ }
  /** Regular expression used to validate usernames (won't validate string length) */
  static get REGEXP_USERNAME() { return /^([a-z]([a-z0-9]*[_.*-]{0,1}[a-z0-9]+)?)$/ }
  /** Regular expression used to validate passwords */
  static get REGEXP_PASSWORD() { return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&_.*])[A-Za-zÑñ\d@$!%?&_.*]{8,30}$/ }
  /** Regular expression used to validate dates (DD/MM/YYYY) */
  static get REGEXP_DATE() { return /^(((0[1-9]|[12][0-9]|3[01])([/])(0[13578]|10|12)([/])(\d{4}))|(([0][1-9]|[12][0-9]|30)([/])(0[469]|11)([/])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([/])(02)([/])(\d{4}))|((29)(\/)(02)([/])([02468][048]00))|((29)([/])(02)([/])([13579][26]00))|((29)([/])(02)([/])([0-9][0-9][0][48]))|((29)([/])(02)([/])([0-9][0-9][2468][048]))|((29)([/])(02)([/])([0-9][0-9][13579][26])))$/ }
  /** Regular expression used to validate an E-Mail */
  static get REGEXP_EMAIL() { return /^([a-z\d.-_]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/ }
  /** Regular expression used to validate an RFC */
  static get REGEXP_RFC() { return /^([A-Z]{4})(\d{6})([A-Z0-9]{3})$/ }
  /** Regular expression that only admits letters and spaces */
  static get REGEXP_ONLY_LETTERS() { return /^[A-Za-zÀ-ÿ\s]+$/ }
  /** Regular expression used to validate a phone number with prefix (prefix_number) */
  static get REGEXP_PHONE() { return /^(\+\d{1,4})_(\d{10})$/ }
  /** Regular expression used to validate a phone number without prefix */
  static get REGEXP_PHONE_NUMBER() { return /^(([1-9]{1}[0-9]{9})|(0[1-9]{1}[0-9]{8})|(00[1-9]{1}[0-9]{7})|(000[1-9]{1}[0-9]{6})|(0000[1-9]{1}[0-9]{5}))$/ }
  /** Regular expression used to validate a phone prefix */
  static get REGEXP_PHONE_PREFIX() { return /^\+\d{1,4}$/ }
  /** Regular expression used to validate a phone prefix */
  static get REGEXP_PHONE_PREFIX_FULL() { return /^[A-Z]{2,}_\+\d{1,4}$/ }
  /** Regular expression used to validate a sign process code */
  /** Racchome name */
  static get REGEXP_RACCHOME() { return /[rR][Aa4][cCkK][cCkK]?[hH]?[oO0cC][mM][eE3]/g }
  /** Hall code */
  static get REGEXP_SIGN_CODE() { return /^[A-Z0-9]{4}[-_—][A-Z0-9]{4}$/ }
  /** Duration of a day, being 86400000 milliseconds */
  static get DAY_TO_MILLI() { return 86400000 }
  /** React Router: About us */
  static get PATH_ABOUT_US() { return '/nosotros' }
  /** React Router: Go to account */
  static get PATH_ACCOUNT() { return '/cuenta' }
  /** React Router: Go to Cuntrat BETA */
  static get PATH_BETA() { return '/beta' };
  /** React Router: Go to contact us */
  static get PATH_CONTACT_US() { return '/contacto' }
  /** React Router: Go to see contract */
  static get PATH_CONTRACT() { return '/contrato' }
  /** React Router: Go to leases viewer (EstatesViewer.jsx) */
  static get PATH_LEASES_VIEW() { return '/mis-contratos' }
  /** React Router: Go to properties viewer (EstatesViewer.jsx) */
  static get PATH_ESTATES_VIEW() { return '/mis-propiedades' }
  /** React Router: Go to subscriptions */
  static get PATH_LICENSE_MANAGEMENT() { return `${Global.PATH_ACCOUNT}/suscripcion` }
  /** React Router: Go to home */
  static get PATH_HOME() { return '/inicio' }
  /** React Router: Go to PDS map */
  static get PATH_PDS() { return '/herramientas/bdp' }
  /** React Router: Go to property */
  static get PATH_PUBLIC_ESTATE() { return '/propiedad' }
  /** React Router: Go to search */
  static get PATH_SEARCH() { return '/buscar' }
  /** React Router: Go to sign up */
  static get PATH_SIGNUP() { return `${Global.PATH_ACCOUNT}/registro` }
  /** React Router: Go to password restoration */
  static get PATH_RESTORE() { return `${Global.PATH_ACCOUNT}/reestablecer` }
  /** React Router: Go to User Management Page */
  static get PATH_USER_MANAGEMENT() { return `/herramientas/gestor-de-usuarios` }
  /** React Router: Main page */
  static get PATH_MAINPAGE() { return 'https://www.racchome.com' }
  /** React Router: Page not found */
  static get PATH_NOT_FOUND() { return '/404' }
  /** Set to true to make this page function in dev mode*/
  static get DEV_MODE() { return Boolean(process.env.REACT_APP_DEV_MODE) };
  /** Global status of deletion */
  static get STATUS_DELETED() { return -2 }
  /** Unity: Megabyte to Byte, being 1 MB == 1 048 576 B  */
  static get U_MB() { return 1048576 }
  /** Unity: Kilobyte to Byte, being  */
  static get U_KB() { return 1000 }

  /** Capitalizes a string.
   * @param {string} value
   * @example ('hello world') => 'Hello World';
   * @example ('lethal COMPANY') => 'Lethal COMPANY';
   * @example ('True raYS') => 'True Rays';
   */
  static capitalize(value) {
    /** @param {string} value */
    const isAllUpperCase = value => {
      const auxVal = Global.replace(value, ['unsymbolized-letters']);

      return !/[^A-Z0-9]/g.test(auxVal);
    }

    if (value) {
      const words = value.split(' ');

      for (let i = 0; i < words.length; i++) {
        if (!isAllUpperCase(words[i])) words[i] = words[i].toLowerCase();

        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }

      return words.join(' ');
    } else if (value === '') return '';
  }

  /** Compares the actual pathname with the given location.
   * @param {string} pathname 
   * @returns true if given pathname is the same that current page pathname.
   */
  static comparePathname(pathname) {
    return window.location.pathname === pathname;
  }

  /** Copies an string to the clipboard.
   * @param {string} content The string to be copied.
   */
  static async copyToClipboard(content) {
    if (content && typeof content === 'string') {
      try {
        navigator.clipboard.writeText(content);
      } catch (err) { return Promise.reject(err) }
    }

    return Promise.resolve();
  }

  /** Decrypts the given data with CryptoJS.AES
   * @param {string} encData
   * @param {string} key
   * @throws {Error} An exception when decrypt could not be completed.
   */
  static decrypt(encData, key) {
    const decryptData = CryptoJS.AES.decrypt(encData, key).toString(CryptoJS.enc.Utf8);

    if (!decryptData)
      throw new Error('FAIL; could not decrypt data');
    else return decryptData;
  }

  /** Encrypts the given data with CryptoJS.AES.
   * @param {*} data 
   * @param {string} key 
   */
  static encrypt(data, key) {
    const toEncrypt = typeof data === 'object'
      ? JSON.stringify(data) : `${data}`;

    return CryptoJS.AES.encrypt(toEncrypt, key).toString();
  }

  /** RECURSIVE FUNCTION: Checks if a node is child of a parent node (specified by id and/or class name).
   * @param {Element} element Element from DOM to start from.
   * @param {{id?:string, className?:string, maxChecks?:number, tagName?: string}} options An object that
   * contains parent's id, class name and maximum iterations. It must contain id, className or tagName at 
   * least, otherwise function will return false. If maxChecks is undefined, function will iterate util
   * it finds the document object. If maxChekcs equals or is less than 0, function will return undefined.
   * @returns {Element|undefined} The parent element if found. undefined if not.
   */
  static findParent(element, options) {
    const { id, className, maxChecks, tagName } = options;
    const remaining = isNaN(maxChecks - 1) ? undefined : maxChecks - 1;
    const end = maxChecks <= 0;

    if (!end && element !== document && (id || className || tagName)) {
      return ((!className || element.className.includes(className))
        && (!id || element.id === id))
        && (!tagName || element.localName === tagName)
        ? element
        : Global.findParent(element.parentNode, { id, className, maxChecks: remaining, tagName });
    } else return undefined;
  }

  /** Returns an element from the DOM through document.querySelector().
   * @param {string} tag - Element's tag. It can be a tag name, a class name, an id ('#id') or a
   * concatenation of them.
   */
  static getElement(tag) {
    return document.querySelector(tag);
  }

  /** Returns an element from DOM through document.getElementById().
   * @param {string} id - The identifier of an element.
   * @example ('remove-e-1') => return <any id="remove-e-1" />
   * @example () => return undefined;
   */
  static getElementById(id) {
    return document.getElementById(id);
  }

  /** Returns an Array of elements from the DOM through document.querySelectorAll().
   * @param {string} tag - An identifier that connects the elements. It can be tag, a class name or a
   * concatenation of both.
   * @example ('div') => return [<div />, <div />, ...];
   * @example () => return undefined;
   * @returns An array of elements from the DOM or undefined.
   */
  static getElements(tag) {
    return tag && typeof tag === 'string'
      ? Array.from(document.querySelectorAll(tag))
      : undefined;
  }

  /** Obtains a random item from an array.
   * @param {*[]} array An array instance.
   */
  static getRandom(array) {
    if (Array.isArray(array) && array.length > 0)
      return array[Math.floor(Math.random() * array.length)];
  }

  /** Extracts the value from a specified param in the current URL.
   * @param {string} param - The parameter identifier.
   * @example ('q') => new URLSearchParams(window.location.search).get('q'); // Returns q parameter.
   */
  static getURLParam(param) {
    return new URLSearchParams(window.location.search).get(param);
  }

  /** Gets the params from the current URL. */
  static getURLParams() {
    return new URLSearchParams(window.location.search);
  }

  /** Checks if an object is empty.
   * @param {Object} obj 
   */
  static isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0
  }

  /** A function that will return a string containing a formated number.
   * @param {Number|String} value A number. If its typeof is not number, undefined will be returned.
   * @example (1200300.56) => return '1 200 300.56';
   * @example (45.5) => return '45.50';
   * @example ('not a number') => return undefined;
   */
  static formatNumber(value) {
    if (typeof value !== 'number' && isNaN(Number(value))) return;

    const numParts = `${value.toString()}`.split('.');
    let count = 0;
    let result = '';

    for (let i = numParts[0].length - 1; i >= 0; i--) {
      count++;
      result = numParts[0][i].concat(result);

      if (i && count % 3 === 0) {
        result = " ".concat(result);
      }
    }

    if (numParts[1]) {
      if (numParts[1].length === 1)
        result = result.concat('.').concat(numParts[1]).concat('0');
      else
        result = result.concat('.').concat(numParts[1].substring(0, 2))
    }
    return result;
  }

  /** Parses a string date to date milliseconds. Given date will be a UTC date.
   * @param {string} date A DD/MM/YYYY formated date.
   * @param {number} [offset] The timezone offset. This offset will be added to returned value,
   * so be carefull with offset polarity.
   */
  static dateToMilliUTC(date, offset) {
    if (!Global.REGEXP_DATE.test(date)) return undefined;

    // Date transform.
    const auxOffset = offset || 0;
    let splitDate = date.split('/');

    return Date.UTC(splitDate[2], splitDate[1] - 1, splitDate[0]) + auxOffset;
  }

  /** Obtains a milliseconds UTC date
   * @param {number|string} date A milliseconds date or a YYYY-MM-DD or DD/MM/YYYY formated date.
   * @returns {number} A UTC date in milliseconds.
   * @param {number} [offset] The timezone offset. This offset will be added to returned value,
   * so be carefull with offset polarity.
   */
  static formatDateUTC(date, offset) {
    if (typeof date === 'number' || !isNaN(date)) return Number(date) + (offset ? offset : 0);
    else if (!Boolean(date)) return undefined;
    else {
      if (Global.REGEXP_DATE.test(date))
        return Global.dateToMilliUTC(date, offset);
      else if (/^[12]{1}[0-9]{3}-((0[1-9])|(1[012]))-((0[1-9])|([0-2][0-9])|3[01])$/.test(date)) {
        const splitDate = date.split('-');
        return Global.formatDateUTC(Date.UTC(splitDate[0], splitDate[1] - 1, splitDate[2]), offset);
      } else return undefined;
    };
  }

  /** Obtains the next date starting by given date.
   * @param {number|string} now Current date in milliseconds.
   * @param {Object} options The options object
   * @param {number} options.mode 1: date will be calculated by next days.
   * 2: date will be calculated by next months. any: date will be calculated
   * by next years.
   * @param {number} options.times Define how many days, months or years will
   * be omited in the calculation.
   * @throws Throws an error if 'now' is not a number or a string.
   * @example (1675144800000, {1, 1}) => {28/2/2023, 1677564000000}
   * @example (1709186400000, {2, 2}) => {28/2/2026, 1772258400000}
   */
  static getNextDate(now, options) {
    const invalid = isNaN(now)
      || typeof options !== 'object'
      || isNaN(options.times)
      || options.times < 0

    // Invalid number or invalid options.
    if (invalid) {
      let msg;

      if (isNaN(now)) msg = `'now' expected a number, got "${now}"`;
      else if (typeof options !== 'object') msg = `'options' expected object, got "${options}"`;
      else msg = `'options.times' expected number equal or greater than 0, got "${options.times}"`;

      throw new Error(msg);
    }

    const currDate = new Date(!isNaN(now) ? now : undefined);
    const nextDate = new Date(!isNaN(now) ? now : undefined);
    let validationOne;
    let validationTwo;

    switch (options.mode) {
      case 1: { // Day mode.
        nextDate.setUTCDate(nextDate.getUTCDate() + options.times);
        break;
      } case 2: { // Month mode.
        nextDate.setUTCMonth(nextDate.getUTCMonth() + options.times);
        validationOne = nextDate.getUTCMonth() > currDate.getUTCMonth() + options.times;
        validationTwo = nextDate.getUTCDate() !== currDate.getUTCDate();
        break;
      } default: { // Year mode.
        nextDate.setUTCFullYear(nextDate.getUTCFullYear() + options.times);
        validationOne = nextDate.getUTCMonth() > currDate.getUTCMonth();
        validationTwo = false;
        break;
      }
    }

    // Date is in the end of month.
    if (validationOne) nextDate.setUTCDate(0);
    // Date is start or between start and end of month.
    else if (validationTwo) nextDate.setUTCDate(currDate.getUTCDate());

    return {
      currDate: `${currDate.getDate()}/${currDate.getMonth() + 1}/${currDate.getFullYear()}`,
      currMill: Date.parse(`${currDate}`),
      nextDate: `${nextDate.getDate()}/${nextDate.getMonth() + 1}/${nextDate.getFullYear()}`,
      nextMill: Date.parse(`${nextDate}`)
    };
  }

  /** Generates a 32 long MD5 string.
   * @param {*} input.
   * @param {*} [nonce] A nonce to randomize result. Values that can be casted to false will be omited.
   */
  static md5(input, nonce) {
    const obj = typeof input === 'object'
      ? JSON.stringify(input) : input;
    const rand = typeof nonce === 'object'
      ? JSON.stringify(nonce) : nonce;

    return md5(`${obj}${rand || ''}`);
  }

  /** Purges any object. Function will remove any undefined / null / empty properties (included arrays).
   * Know that after this call method, returned object will not have any custom method beyond default
   * prototype. Call this function before sending an object to the server.
   * @param {Object.<string, *>} obj Any object of any Class instance.
   */
  static purgeObject = obj => {
    if (typeof obj === 'object') {
      const objRet = {};
      const keys = Object.keys(obj);

      for (let i = 0; i < keys.length; i++) {
        const attr = obj[keys[i]];

        if (Array.isArray(attr)) {
          const newArr = [];

          for (let j = 0; j < attr.length; j++) {

            if ((Array.isArray(attr[j]) && attr[j].length)
              || (typeof attr[j] === 'object' && !(attr[j] instanceof Blob))) {
              const subObj = Global.purgeObject(attr[j]);

              if (subObj) newArr.push(subObj);
            } else if (attr[j] !== undefined && attr[j] !== null && attr[j] !== '') {
              newArr.push(attr[j]);
            }
          }

          if (newArr.length) objRet[keys[i]] = newArr;
        } else if (typeof attr === 'object') {
          const subObj = Global.purgeObject(attr);

          if (subObj) objRet[keys[i]] = subObj;
        } else if (attr !== undefined && attr !== null && attr !== '') objRet[keys[i]] = attr;
      }

      return Global.isObjectEmpty(objRet) ? undefined : objRet;
    }
  }

  /** Replaces characters from a string.
   * @deprecated Function is outdated.
   * @param {string} value The value to modify.
   * @param {['integer'|'decimal'|'sentence'|'no-spaces'|'unsymbolized-letters'|'extra-spaces']
   *   |'integer'|'decimal'|'sentence'|'no-spaces'|'unsymbolized-letters'|'extra-spaces'} args 
   * Array of arguments or a single argument (string). 'integer' treats value as an integer number.
   * 'decimal' treats value as a decimal number. 'sentence' treats value as a sentence (contains letters,
   * numbers and spaces). 'no-spaces' removes all spaces from value. 'unsymbolized-letters' removes
   * sympolized letters and replaces them with its equal letter (f.e: Á -> A, Ö -> O, ì -> i).
   * 'extra-spaces' removes extra spaces from value.
   * @param {number} [length] value length limit. Must be greater than 0.
   */
  static replace(value, args, length) {
    let result = value;
    const argsArr = !Array.isArray(args) ? [args] : [...args];

    for (let i = 0; i < argsArr.length; i++) {
      switch (argsArr[i].toLowerCase()) {
        case 'integer': {
          result = result?.replace(/[^0-9]/, '').replace(/^0+/, '').replace(/\s+/g, '');
          break;
        } case 'decimal': {
          result = result?.replace(/[^0-9.]/g, '').replace(/^0+/, '').replace(/\s+/g, '');
          break;
        } case 'sentence': {
          result = result?.replace(/[^A-Za-zÀ-ÿ0-9\s]/, '').replace(/\s+/g, ' ');
          break;
        } case 'no-spaces': {
          result = result?.replace(/\s+/g, '');
          break;
        } case 'extra-spaces': {
          result = result?.trim().replace(/\s+/g, ' ');
          break;
        } case 'unsymbolized-letters': {
          result = result.replace(/[ÂÄÁÀ]/g, 'A');
          result = result.replace(/[âäáà]/g, 'a');
          result = result.replace(/[ÊËÉÈ]/g, 'E');
          result = result.replace(/[êëéè]/g, 'e');
          result = result.replace(/[ÎÏÍÌ]/g, 'I');
          result = result.replace(/[îïíì]/g, 'i');
          result = result.replace(/[ÔÖÓÒ]/g, 'O');
          result = result.replace(/[ôöóò]/g, 'o');
          result = result.replace(/[ÛÜÚÙ]/g, 'U');
          result = result.replace(/[ûüúù]/g, 'u');
          result = result.replace(/[Ý]/g, 'Y');
          result = result.replace(/[ý]/g, 'y');
          result = result.replace(/[Ñ]/g, 'N');
          result = result.replace(/[ñ]/g, 'n');
          break;
        } default: { }
      }
    }

    if (length >= 0) {
      result = result?.slice(0, length);
    }

    return result;
  }

  /**
   * Evaluates a string through a regular expression.
   * @param {string} value A string to be evaluated.
   * @param {RegExp} regExp A regular expression.
   * @returns {Promise<String>} On resolve, will return 'value'. On reject, will return
   * an error message.
   */
  static testRegExp(value, regExp) {
    return new Promise((resolve, reject) => {
      if (!value || !regExp) {
        return reject('Entry and / or RegExp is empty.');
      } else if (!regExp.test(value)) {
        let sRegExp = regExp.toString(); // Regular Expression to string.

        if (regExp === Global.REGEXP_NO_FORBIDDEN_SYMBOLS) {
          // Search for the first coincidence of a forbidden character.
          sRegExp = sRegExp.substring(1, sRegExp.length - 1).split('[');
          sRegExp = sRegExp[0].concat('^').concat(sRegExp[1]);
          return reject(`'${new RegExp(sRegExp).exec(value)}' is a forbidden symbol.`)
        } else if (sRegExp.includes('/^.{')) { // Regular Expression to validate length.
          sRegExp = sRegExp.substring(sRegExp.indexOf('{') + 1, sRegExp.indexOf('}'));

          if (sRegExp.includes(',')) { // RegExp have a min length limit. Could have a max length limit.
            const length = value.length < Number(sRegExp.split(',')[0]);
            return reject(`Entry is too ${length ? 'large' : 'tiny'}.`);
          } else { // Value must have a specified length.
            return reject(`Entry must be ${sRegExp} char length.`);
          }
        } else if (regExp === Global.REGEXP_NO_SYMBOLS) { // Value must contain only letters and numbers.
          return reject('Entry must contain letters and numbers only.')
        } else { // Any other regular expression.
          return reject("Entry doesn't match");
        }
      } else {
        return resolve(value);
      }
    });
  }

  /**mTransforms a 'YYYY-MM-DD' date to a 'DD/MM/YYYY' date. If date is invalid, will return undefined.
   * Use this function to transform an '<input[type='date']/> element's output.
   * @param {string} date 
   */
  static transformDate(date) {
    const generatedDate = /^[0-9]{4}[-](([1][012])|[0][0-9])[-]((0[1-9])|([3][01])|[12][0-9])$/;

    if (generatedDate.test(date)) {
      // Replacing and switching.
      const values = date.split('-');
      const formatedDate = `${values[2]}/${values[1]}/${values[0]}`

      if (Global.REGEXP_DATE.test(formatedDate)) return formatedDate;
    }
  }

  /** Obtains a parsed dd/MM/yyyy UTC date from a milli format.
   * @param {number} milli If undefined, 'NaD' will be returned.
   * @param {number} [offset] The timezone offset. This offset will be added to returned value,
   * so be carefull with offset polarity.
   * @throws An error during the parse process. */
  static parseDateUTC(milli, offset) {
    if (typeof milli !== 'number') return 'NaD';

    const auxMilli = typeof offset === 'bigint' || typeof offset === 'number'
      ? milli + offset : milli;

    try {
      const date = new Date(auxMilli);
      let day = date.getUTCDate();
      day = `${day < 10 ? '0' : ''}${day}`
      let month = date.getUTCMonth() + 1;
      month = `${month < 10 ? '0' : ''}${month}`

      return `${day}/${month}/${date.getUTCFullYear()}`;
    } catch (err) {
      throw new Error(err);
    }
  }

  /** Parses a date from milli to a parsed dd/MM/yyyy UTC date with time.
   * @param {number} milli The current date in milliseconds.
   * @param {number} [offset] The timezone offset. This offset will be added to returned value,
   * so be carefull with offset polarity.
   */
  static parseDateWithTimeUTC(milli, offset) {
    return Global.parseDateUTC(milli, offset) + ' (' + Global.parseTimeUTC(milli, { offset }) + ')';
  }

  /** Obtains the UTC time from a date (24 hrs format)
   * @param {number} milli The current day in milliseconds.
   * @param {{s: boolean, m: boolean, offset?: number}} [options] Options. If s or m is true, seconds will be
   * displayed. If m is true, millisecons and seconds will be displayed. offset is the timezone offset.
   */
  static parseTimeUTC(milli, options) {
    const auxMilli = typeof options?.offset === 'bigint' || typeof options?.offset === 'number'
      ? milli + options.offset : milli;

    const aD = new Date(auxMilli);
    const minHrs = aD.getUTCHours() < 10;
    const minMin = aD.getUTCMinutes() < 10;
    const s = options?.s || options?.m ? `:${aD.getUTCSeconds()}` : '';
    const m = options?.m ? `:${aD.getUTCMilliseconds()}` : '';

    return `${minHrs ? '0' : ''}${aD.getUTCHours()}:${minMin ? '0' : ''}${aD.getUTCMinutes()}${s}${m}`;
  }

  /** Obtains a parsed yyyy-MM-dd UTC date from a milli format. Ideal for input elements.
   * @param {number} milli
   */
  static transformDateForInput(milli) {
    if (milli) {
      const date = Global.parseDateUTC(milli).split('/');

      return `${date[2]}-${date[1]}-${date[0]}`;
    }
  }
}

export default Global;
