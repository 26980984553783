import "./styles/agreement-view.css";
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { globalContext } from './context/GlobalContext';
import * as Icons from './assets/images';
import Contract from "./objects/Contract";
import Dialog from './components/popups/Dialog';
import Hintbox from './components/Hintbox';
import List from './components/List';
import LoadingBlock from './components/LoadingBlock';
import LoadingPanel from "./components/LoadingPanel";
import PageNotFound from './PageNotFound';
import PropDisplay from './components/PropDisplay'
import TransactionLineTime from "./components/TransactionLineTime";
import UIRender from "./objects/UIRender";
import Estate from "./objects/Estate";
import DAOServ from "./objects/DAOServ";
import Agreement from "./objects/Agreement";
import GenericFile from "./objects/GenericFile";
import User from "./objects/User";
import ErrHandler from "./objects/ErrHandler";
import FileManager from './components/FileManager';
import FileChooser from "./objects/FileChooser";
import Button from "./components/Button";
import Global from "./objects/Global";
import PSCollection from "./objects/PSCollection";
import Transaction from "./objects/Transaction";
import TransactionRegist from "./components/popups/TransactionRegist";
import UserCard from "./components/UserCard";

/** @typedef {import('./components/popups/Dialog').DialogPropsObject} DialogPropsObject */
/** @typedef {import('./components/popups/TransactionRegist').TransactionRegistPropsObject} TransactionRegistPropsObject */

/** AgreementPeriodObject typedef.
 * @typedef {Object} AgreementPeriodObject
 * @property {number} from Start of period.
 * @property {number} period Zero-based period number.
 * @property {number} to End of period.
 * @property {Transaction} [transaction] The transaction (payment) attached. It is undefined
 * when period hasn't been paid.
 */

const AgreementView = () => {
  // *** useContext ***
  const {
    currSession,
    getCacheFile,
    pushAlertMessage,
    pushCacheFile,
    pushMessageHint,
    setSearchMethod,
    timezoneOffset
  } = useContext(globalContext);
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useParams ***
  const params = useParams("contract");
  // *** useRef ***
  const contractServices = useRef(/** @type {string[]} */(undefined));
  const paymentsCount = useRef(/** @type {number} */(undefined));
  // *** useState ***
  const [contract, setContract] = useState(/** @type {Contract} */(undefined));
  const [estate, setEstate] = useState(/** @type {Estate} */(undefined));
  const [estateCover, setEstateCover] = useState();
  const [showPopup, setShowPopup] = useState(
    /**@type {{ type: 1|2, props: DialogPropsObject&TransactionRegistPropsObject }} */(undefined)
  );
  const [today, setToday] = useState(/** @type {number} */(undefined));

  const canCancelContractButtonRender = () => {
    return !contract?.getAgreement()?.getCancellationDate()
      && getCurrentSessionRole() === 'owner' &&
      (contract.getAgreement().getStatus() === Agreement.STATUS_DELAYED
        || (contract.getAgreement().getStartDate() >= today
          && paymentsCount.current === 0));
  }

  const cancelContractBtnClickHandler = () => {
    const isOldContract = today > contract.getAgreement().getStartDate();
    const msg = isOldContract
      ? 'Intenta llegar a un acuerdo con tu arrendatario antes de continuar con esta acción. '
      + 'Verifica también que tu arrendatario no haya pagado el arrendamiento mediante otro '
      + 'medio de pago ajeno a esta página (p.e: efectivo o transferencia). Si es así, debes '
      + 'registrar el pago manualmente.'
      : '';

    /** @type {DialogPropsObject} */
    const auxShowPopupProps = {
      message: 'Estás a punto de cancelar este contrato. ' + msg + ' El arrendatario '
        + 'será notificado de la acción.',
      action: () => DAOServ
        .post('cancel_agreement', { tst: currSession.tst, hash: contract.getAgreement().getHash() }, 'JSON'),
      confirmBtn: {
        delay: isOldContract ? 10000 : 3000,
        icon: Icons.CloseIcon,
        onWaitValue: 'Cancelando...',
        type: 'error',
        value: 'Cancelar contrato'
      }, id: 'cancel-contract-dialog',
      onReject: err => err && pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }),
      onResolve: data => {
        contract.getAgreement().setCancellationDate(data['cancellationDate']);
        contract.getAgreement().setStatus(Agreement.STATUS_CANCELLED);
        setContract(new Contract(contract));
        pushMessageHint({ message: 'El contrato fue cancelado.', type: 'warning' })
      }, rejectBtn: { value: 'Regresar' },
      renderButtonsSwitched: true
    }

    setShowPopup({ type: 1, props: auxShowPopupProps })
  }

  const copyHashToClipboardClickHandler = async () => {
    await Global.copyToClipboard(contract.getAgreement().getHash())
      .then(() => pushAlertMessage({ message: 'Copiado al portapapeles', type: 'complete' }))
      .catch(err => pushAlertMessage({ message: ErrHandler.parseError(err), type: 'error' }));
  }

  const getCurrentSessionRole = () => {
    if (currSession.id === estate?.getOwner().getId() || currSession.id === estate?.getCreator()?.getId())
      return 'owner'
    else
      return 'lessee';
  }

  const statusToClass = () => {
    const role = getCurrentSessionRole();

    return `${contract.getAgreement().statusToClass()}${role === 'lessee' ? ' lessee' : ''}`;
  }

  const getHintboxMessage = () => {
    switch (estate.getStatus()) {
      case Estate.STATUS_INA: return 'La publicación está inhabilitada.';
      case Estate.STATUS_SUS: return 'La publicación está suspendida.';
      default: return 'La publicación fue eliminada.';
    }
  }

  const getPetAlt = () => {
    const sCN = statusToClass(contract);

    if (sCN === 'delayed' || sCN === 'cancelled') return 'alfred-bear-mad';
    else return 'alfred-bear-neutral';
  }

  const getPetImage = () => {
    const sCN = contract.getAgreement().statusToClass();

    if (sCN === 'active' || sCN === 'cut-near' || sCN === 'cut-today' || sCN === 'finalized')
      return Icons.Alfred1Img;
    else return Icons.Alfred2Img;
  }

  const getPetMessage = () => {
    const cancelContract = today < contract.getAgreement().getNextDeadline() && paymentsCount.current === 0
      ? ' Puedes cancelar el contrato ahora mismo si lo necesitas.'
      : '';
    const role = getCurrentSessionRole();

    switch (contract.getAgreement().statusToClass()) {
      case 'active': {
        return role === 'lessee'
          ? 'Estás al día. (OwO)'
          : 'Todo en orden. (OwO)7'
      } case 'cancelled': {
        return role === 'lessee'
          ? 'Este contrato fue cancelado. Es posible que te hayas atrasado en los pagos.'
          : 'Este contrato fue cancelado.'
      } case 'delayed': {
        const lT = contract.getAgreement().getLessees().length > 1
          ? 'los arrendatarios están atrasados'
          : 'el arrendatario está atrasado';
        const mT = contract.getAgreement().getLessees().length > 1
          ? ' Si te pagaron de forma externa a nuestro servicio.'
          : ' Si te pagó de forma externa a nuestro servicio.'

        return role === 'lessee'
          ? '¡Grrr Estás atrasado! Paga tus adeudos para evitar que tu contrato sea cancelado. (ÒnÓ)'
          : `Jeje, ${lT}. Puedes cancelar el contrato o registrar el pago de adeudos manualmente.${mT}`
      } case 'finalized': return 'Este contrato ha finalizado.';
      case 'cut-near': {
        return role === 'lessee'
          ? `Es el periodo de corte. Puedes pagar el próximo periodo de una vez y mantenerte al día.`
          : 'Tu arrendador aún tiene tiempo de pagarte. Si recibiste el pago por otro medio,'
          + ' regístralo manualmente.'
          + cancelContract
      } case 'cut-today': {
        return role === 'lessee'
          ? 'Es la hora de pagar. Si hiciste el pago por otro medio, avisa a tu arrendador para que'
          + ' lo registre manualmente.'
          : 'Hoy es el corte. Si recibiste el pago por otro medio, regístralo manualmente.'
          + cancelContract
      } default: return '¡Uh Oh! Hay algo mal en este contrato. (ÒwÓ)';
    }
  }

  const getTransactionLineTimeRole = () => {
    if (estate.getOwner().getId() === currSession.id)
      return 'owner'
    else if (contract.getAgreement().getLessees().find(l => l.getUsername() === currSession.username))
      return 'lessee';
  }

  const hashInfoBtnClickHandler = () => {
    setShowPopup({
      type: 1,
      props: {
        id: 'hash-info-dialog',
        message: "Tu contrato está asegurado en una cadena de bloques. Cada bloque se "
          + "identifica con una llave HASH, que es la que identifica el bloque donde "
          + "tu contrato se ubica. La información de tu contrato es inmutable.",
      }
    });
  }

  /** @param {import("./components/TransactionLineTime").AgreementPeriodObject} aPO */
  const payBtnClickHandler = aPO => {
    /** @type {TransactionRegistPropsObject} */
    const auxShowPopupProps = {
      action: t => {
        const body = {
          hash: contract.getAgreement().getHash(),
          tst: currSession.tst,
          transaction: new Transaction(t),
        };

        body.transaction.setPayer(t.getPayer().getUsername());

        return DAOServ.post('push_agreement_payment', body, 'JSON');
      }, amount: aPO.amount,
      agrPerObj: aPO,
      lessees: contract.getAgreement().getLessees().map(l => l.getUsername()),
      onHide: () => setShowPopup(),
      onReject: err => err && pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }),
      onResolve: (nD, s) => {
        contract.getAgreement().setNextDeadline(nD);
        contract.getAgreement().setStatus(s);
        setContract(new Contract(contract));
        pushMessageHint({ message: 'Transacción registrada con éxito', type: 'complete' });
      }, today: today
    }

    setShowPopup({ type: 2, props: auxShowPopupProps });
  }

  // Fetch agreement and estate info.
  useEffect(() => {
    /** @param {string} hash */
    const fetchAgreement = async hash => {
      const auxAgre = new Agreement();
      const auxCont = new Contract({ agreement: auxAgre });
      let idEstate, currDay = -1;

      try {
        const req = await DAOServ.post('get_agreement', { hash, tst: currSession.tst }, 'JSON');
        paymentsCount.current = req['paymentsCount'];
        idEstate = req['idEstate'];
        const block = req['block'];
        const data = block['data'];

        auxAgre.setStatus(req['status']);
        auxAgre.setStartDate(data['startDate']);
        auxAgre.setEndDate(req['endDate']);
        auxAgre.setId(data['id'])
        auxAgre.setNextDeadline(req['nextDeadline']);
        auxAgre.setCancellationDate(req['cancellationDate']);
        auxAgre.setHash(block['hash']);
        auxAgre.setCreationDate(block['timestamp']);
        data['files'].forEach(f => auxAgre.getFiles().push(new GenericFile({
          name: f['name'],
          pathname: f['path'],
          size: f['size']
        })));
        data['lessees'].forEach(l => auxAgre.getLessees().push(new User({ username: l })));

        auxCont.setCharge(data['chargeAtStart']);
        auxCont.setInclServs(data['includedServices']);
        auxCont.setPayAmount(data['payAmount']);
        auxCont.setPayFrequency(data['payFrequency']);
        auxCont.setTermMethod(data['termMethod']);
        auxCont.setTerm(data['term']);
        contractServices.current = data['services'];

        currDay = await DAOServ.getCurrentDay();

        fetchEstate(idEstate, auxAgre.getHash());
      } catch (err) {
        const code = ErrHandler.getCode(err);

        if (code !== ErrHandler.CODES.NOT_FOUND && code === ErrHandler.CODES.ACCESS_DENIED)
          pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
      } finally {
        setContract(auxCont);
        setToday(currDay);
      }
    }

    /** 
     * @param {number} id
     * @param {string} hash
     */
    const fetchEstate = async (id, hash) => {
      if (id === undefined) {
        setEstate(new Estate());
        return;
      }

      let newCover = Icons.EstateDefaultCover;
      const data = await DAOServ.post('get_publishment_registry', { idEstate: id, hash }, 'JSON');
      // Data parsing.
      const auxEsta = new Estate();
      auxEsta.setId(data['idestate']);
      auxEsta.setTitle(data['title']);
      auxEsta.setOwner(new User({ id: data['idowner'] }));
      auxEsta.setStatus(data['status']);
      setEstate(auxEsta);

      // Checking if file is cached.
      const auxCF = getCacheFile(data['cover']);

      if (auxCF !== undefined) { // Fetch file from cache.
        if (typeof auxCF.content === 'string')
          newCover = auxCF.content;
        else
          await FileChooser.readAsDataURL(auxCF.content)
            .then(stream => newCover = stream)
            .catch(err => err && pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }));

        auxEsta.getImages().push(new GenericFile({
          name: data['cover'],
          size: auxCF.size,
          urlData: newCover
        }));

        setEstateCover(newCover);
      } else { // Fetch file from server.
        DAOServ.getFileCallback(data['cover'], async (err, file) => {
          if (file !== undefined) {
            const urlData = await FileChooser.readAsDataURL(file);
            newCover = urlData;
            auxEsta.getImages().push(new GenericFile({
              name: data['cover'],
              size: file.size,
              urlData
            }));
            pushCacheFile({ content: urlData, name: data['cover'], size: file.size });
          }

          setEstateCover(newCover);
        });
      }
    }

    const hash = params['contract'];

    UIRender.scrollTo();
    setSearchMethod(false);

    if (currSession.sessionStatus) {
      if (currSession.sessionStatus !== User.STATUS_ACT) navigate('/', { replace: true });
      else if (hash !== undefined) fetchAgreement(hash);
      else setContract(new Contract());
    }
  }, [currSession, params, getCacheFile, navigate, pushCacheFile, pushMessageHint, setSearchMethod]);

  useEffect(() => {
    if (today === -1)
      navigate(Global.PATH_MAINPAGE, { replace: true });
  }, [navigate, today]);

  if (today === undefined || contract === undefined || timezoneOffset === undefined)
    return <LoadingPanel height="90vh" />
  else if (contract.getAgreement()?.getHash() === undefined)
    return <PageNotFound />
  else return (<div className="agreement-view container">
    <div className={`pet-container${contract === undefined ? ' wait' : ''}`}>
      <div className="pet-data-container">
        <img src={getPetImage()} alt={getPetAlt()} />
        <div className="pet-globe">{getPetMessage()}</div>
      </div>
    </div>
    <div className="contract-box">
      <div className={`top-info-container ${statusToClass()}`}>
        <h4 className='remark'>{contract.getAgreement().getId()}</h4>
        <span />
      </div>
      <div className="info-container">
        {estate !== undefined && estate.getStatus() !== Estate.STATUS_ACT && <Hintbox icon={Icons.InfoIcon}
          message={getHintboxMessage()}
          type="glass" />}
        <div className={`estate-card${estate === undefined ? ' waiting' : ''}`}>
          {estate === undefined && <span className="waiting-span" />}
          <div className="cover-container">
            {estateCover === undefined && <LoadingBlock miniFigure />}
            {estateCover !== undefined && <img className={estate.getStatus() !== Estate.STATUS_ACT ? 'grayscale' : ''} src={estateCover} alt={estate.getCoverName()} />}
          </div>
          <div className="data-container">
            <h5 className="estate-title">{estate?.getTitle()}</h5>
            {estate !== undefined && estate.getStatus() !== -2 && <Button borderless
              empty
              icon={Icons.PublIcon}
              onClick={() => navigate(`${Global.PATH_PUBLIC_ESTATE}/${estate.getId()}`)}
              rounded
              value="Ver publicación" />}
          </div>
        </div>
        <div className="box shadowless">
          <h5 className="overset">Línea de pagos</h5>
          <TransactionLineTime contract={contract}
            onPay={payBtnClickHandler}
            role={!estate ? undefined : getTransactionLineTimeRole()}
            today={today} />
          <div className="flex-box">
            {canCancelContractButtonRender() && <div className="child m3 auto-width">
              <Button borderless
                empty
                icon={Icons.CloseIcon}
                onClick={cancelContractBtnClickHandler}
                typeRender="error"
                rounded
                value="Cancelar contrato" />
            </div>}
            {getCurrentSessionRole() === 'owner'
              && (contract.getAgreement().getStatus() === Agreement.STATUS_DELAYED
                || today < contract.getAgreement().getStartDate()) && <div className="child m3">
                <Hintbox icon={Icons.InfoIcon}
                  message={"Puedes cancelar el contrato inmediatamente cuando existe un atraso o cuando "
                    + "el contrato aún no entra en vigor y aún no se ha realizado el primer pago."}
                  type="warning" />
              </div>}
          </div>
        </div>
        <div className="box shadowless">
          <h5 className="overset">Información del contrato.</h5>
          <div className="flex-box m3">
            <div className="child">
              <PropDisplay header="Creación"
                property={Global.parseDateWithTimeUTC(contract.getAgreement().getCreationDate(), timezoneOffset)} />
            </div>
            <div className="child">
              <PropDisplay header="Inicio de contrato"
                property={Global.parseDateUTC(contract.getAgreement().getStartDate(), timezoneOffset)} />
            </div>
          </div>
          <div className="flex-box m3">
            <div className="child">
              <PropDisplay header={contract.getAgreement().getStatus() === Agreement.STATUS_DELAYED
                ? 'Inicio del adeudo' : 'Próximo corte'
              } property={contract.getAgreement().getCancellationDate() !== undefined
                ? 'Cancelado'
                : typeof contract.getAgreement().getNextDeadline() === 'number'
                  ? Global.parseDateUTC(contract.getAgreement().getNextDeadline(), timezoneOffset)
                  : 'Todos los periodos pagados.'} />
            </div>
            <div className="child">
              <PropDisplay header="Fin de contrato"
                property={contract.getAgreement().getCancellationDate() !== undefined
                  ? 'Cancelado' : Global.parseDateUTC(contract.getAgreement().getEndDate(), timezoneOffset)} />
            </div>
          </div>
          <div className="flex-box m3">
            <div className="child m3 hash-prop-display">
              <PropDisplay header="HASH"
                property={contract.getAgreement().getHash()} />
            </div>
            <div className="child flex-box m3 auto-width">
              <div className="child">
                <Button borderless
                  empty
                  icon={Icons.CopyIcon}
                  onClick={copyHashToClipboardClickHandler}
                  reduced
                  rounded
                  title="Copiar al portapapeles" />
              </div>
              <div className="child">
                <Button borderless
                  empty
                  icon={Icons.InfoIcon}
                  onClick={hashInfoBtnClickHandler}
                  reduced
                  rounded
                  title="Acerca de la llave HASH" />
              </div>
            </div>
          </div>
          <div className="box shadowless lessees">
            <h6 className="overset">Arrendatarios</h6>
            {contract?.getAgreement()?.getLessees().map(l => <UserCard user={l}
              mini
              key={l.getUsername()} />)}
          </div>
          <FileManager
            allowDownload
            files={contract.getAgreement().getFiles().map(f => { return { file: f, from: 'PRIVATE' } })}
            title="Documentos asociados" />
          <div className="flex-box wrap">
            <div className="child dir-column box shadowless jc-left">
              <h5 className="overset full-width">Servicios de la propiedad.</h5>
              <List elements={contractServices.current?.map(cS => {
                return {
                  icon: PSCollection.getServiceIcon(cS),
                  text: PSCollection.getServiceName(cS),
                }
              })} emptyMessage="La propiedad no tiene servicios" />
            </div>
            <div className="child dir-column box shadowless jc-left">
              <h5 className="overset full-width">Servicios incluidos en el arrendamiento.</h5>
              <List elements={contract.getInclServs().map(iS => {
                return {
                  icon: PSCollection.getServiceIcon(iS),
                  text: PSCollection.getServiceName(iS),
                }
              })} />
            </div>
          </div>
        </div>
      </div>
    </div>
    {showPopup?.type === 1 && <Dialog action={showPopup.props.action}
      confirmBtn={showPopup.props.confirmBtn}
      id={showPopup.props.id}
      message={showPopup.props.message}
      onHide={() => setShowPopup()}
      onReject={showPopup.props.onReject}
      onResolve={showPopup.props.onResolve}
      rejectBtn={showPopup.props.rejectBtn}
      renderButtonsBorderless
      renderButtonsEmpty
      renderButtonsRounded
      renderButtonsSwitched={showPopup.props.renderButtonsSwitched} />}
    {showPopup?.type === 2 && <TransactionRegist action={showPopup.props.action}
      amount={showPopup.props.amount}
      lessees={showPopup.props.lessees}
      minDate={showPopup.props.minDate}
      onHide={showPopup.props.onHide}
      onReject={showPopup.props.onReject}
      onResolve={showPopup.props.onResolve}
      today={today}
      agrPerObj={showPopup.props.agrPerObj} />}
  </div>);
}

export default AgreementView;