import "./styles/beta.css";
import { useContext, useEffect } from "react";
import { globalContext } from "./context/GlobalContext";
import Button from "./components/Button";
import UIRender from "./objects/UIRender";
import { KevinImg, MailIcon } from './assets/images';

const Beta = () => {
  const { setSearchMethod, setShowFooter } = useContext(globalContext);

  useEffect(() => {
    UIRender.scrollTo();
    setSearchMethod();
    setShowFooter(true);
  }, [setSearchMethod, setShowFooter]);

  return (<div className="beta container">
    <div className="pet-container">
      <img src={KevinImg} alt="kevin-coon" className="pet" />
      <span className="beta-card beta" />
    </div>
    <div className="info-container">
      <h2>Forma parte del programa <span className="beta" /></h2>
      <br />
      <p className="message">
        ¿Tienes una empresa inmobiliaria, una cadena hotelera o eres
        una persona que se dedica a la venta y renta de sus propiedades?
        Entonces este programa es para ti.
        <br /><br />
        Si calificas, te otorgaremos una licencia con acceso a todas
        nuestras funciones GRATIS. Solo mándanos un correo y háblanos de tus
        propiedades: cuántas tienes, cuántas de ellas vendes y rentas, aproximadamente
        cuánto ganas de ellas al mes, cosas así.
      </p>
      <br />
      <Button onClick={'mailto:soporte@racchome.com?subject=Programa%20BETA&body=Mándanos%20tu%20nombre%20de%20usuario%20y%20háblanos%20de%20tus%20propiedades.'}
        animated
        icon={MailIcon}
        value="Contactar" />
    </div>
  </div>);
}

export default Beta;