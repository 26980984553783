import './styles/publ-estate.css';
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { globalContext } from "./context/GlobalContext";
import Button from './components/Button';
import Contract from './objects/Contract';
import ErrHandler from './objects/ErrHandler';
import HintBox from './components/Hintbox';
import PropDisplay from './components/PropDisplay';
import UIRender from './objects/UIRender';
import ImageViewer from './components/ImageViewer';
import Dialog from './components/popups/Dialog';
import List from './components/List';
import LoadingPanel from "./components/LoadingPanel";
import DAOServ from './objects/DAOServ';
import Starbar from "./components/Starbar";
import Global from './objects/Global';
import Estate from './objects/Estate';
import FileChooser from './objects/FileChooser';
import UserCard from './components/UserCard';
import User from './objects/User';
import PSCollection from './objects/PSCollection';
// Icons.
import * as Icons from './assets/images';
import Bidding from './objects/Bidding';
import BiddingBox from './components/BiddingBox';
import Bid from './objects/Bid';
import PushBid from './components/popups/PushBid';
import PageNotFound from './PageNotFound';
import Persona from './objects/Persona';

/** Renders a PublEstate page */
const PublEstate = () => {
  // *** useContext ***
  const {
    currSession,
    getCacheFile,
    pushAlertMessage,
    pushCacheFile,
    pushMessageHint,
    setShowLogin,
    setSearchMethod
  } = useContext(globalContext);
  const [bidding, setBidding] = useState(/** @type {Bidding|null} */(undefined))
  const [contactInfo, setContactInfo] = useState(/** @type {Persona} */(undefined));
  const [estate, setEstate] = useState(/** @type {Estate} */(undefined));
  const [images, setImages] = useState(/** @type {import('./objects/GenericFile').default[]} */(undefined));
  const [showContactInfoDialog, setShowContactInfoDialog] = useState(false); // Show dialog.
  const [showLessorData, setShowLessorData] = useState(false);
  const [showPushBid, setShowPushBid] = useState(false);
  const [today, setToday] = useState(/** @type {number} */(undefined));
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useParams ***
  const paramId = useParams();
  // *** useRef ***
  const cardRef = useRef(/** @type {HTMLDivElement} */(undefined));
  const paramRef = useRef(/** @type {string} */(undefined));

  const canRenderLessorInfo = () => {
    return contactInfo?.getId() !== undefined
      && estate?.getSellMethod() !== Estate.ON_COMPLEX
      && estate?.getStatus() === Estate.STATUS_ACT
      && (estate?.getBidding().getId() === undefined || !estate.getBidding().getStatus())
  }

  const contactBtnHandleOnClick = () => {
    if (currSession.sessionStatus !== User.STATUS_ACT) setShowLogin(true);
    else if (estate.getBidding().getStatus()) setShowPushBid(true);
    else setShowContactInfoDialog(true);
  }

  /** @param {string} input */
  const copyBtnHandleOnClick = input => {
    Global.copyToClipboard(input?.replace(/\s+/g, ''))
      .then(() => {
        if (Global.REGEXP_EMAIL.test(input))
          pushAlertMessage({ message: 'Correo copiado al portapapeles', type: 'complete' });
        else
          pushAlertMessage({ message: 'Teléfono copiado al portapapeles', type: 'complete' });
      }).catch(err => pushAlertMessage({ message: ErrHandler.parseError(err), type: 'error' }));
  }

  const getBiddingStatusHintboxIcon = () => {
    const isWinner = bidding?.getBids().at(0)?.getBidder().getUsername() === currSession.username;

    if (bidding !== undefined) {
      if (!bidding.getStatus()) {
        return isWinner
          ? Icons.SubsIcon
          : Icons.CloseIcon;
      } else {
        return isWinner
          ? Icons.CrownIcon
          : Icons.WarningIcon;
      }
    }
  }

  const getBiddingStatusHintboxMessage = () => {
    const isWinner = bidding?.getBids().at(0)?.getBidder().getUsername() === currSession.username;

    if (bidding !== undefined) {
      if (!bidding.getStatus()) {
        return isWinner
          ? '¡Has ganado la subasta! Tu y el propietario pueden ponerse en contacto ya.'
          : 'No has alcanzado la oferta máxima. Te avisaremos si esto cambia en el futuro.';
      } else {
        return isWinner
          ? 'Hiciste la oferta más alta. Espera a que la subasta termine para ver la información de contacto.'
          : 'Alguien más ha ofrecido un mejor precio. Haz otra oferta para no quedarte atrás';
      }
    }
  }

  const getBiddingStatusHintboxType = () => {
    const isWinner = bidding?.getBids().at(0)?.getBidder().getUsername() === currSession.username;

    if (bidding !== undefined) {
      if (!bidding.getStatus()) {
        return isWinner
          ? 'complete'
          : 'error'
      } else {
        return isWinner
          ? ''
          : 'warning'
      }
    }
  }


  const getContractInfo = () => {
    /** @param {Contract} contract */
    const getCharge = contract => {
      switch (contract.getChargeType()) {
        case Contract.CHARGE_SAME_AS_PAY: return 'Más periodo';
        case Contract.CHARGE_CUSTOM: return `$ ${Global.formatNumber(contract.getCharge())} MXN`;
        default: return 'No requerido';
      }
    }

    /** @param {Contract} contract */
    const getPayFrequency = contract => {
      switch (contract.getPayFrequency()) {
        case Contract.PAY_FREQ_DAILY: return 'diario';
        case Contract.PAY_FREQ_MONTHLY: return 'mensual';
        case Contract.PAY_FREQ_UNIQUE: return 'pago único al iniciar';
        case Contract.PAY_FREQ_YEARLY: return 'anual';
        default: return 'no especificado';
      }
    }

    /** @param {Contract} contract */
    const getTermMethod = contract => {
      const term = contract.getTerm();

      switch (contract.getTermMethod()) {
        case Contract.TERM_METHOD_DAY: return `${term} ${term > 1 ? 'días' : 'día'}`;
        case Contract.TERM_METHOD_MONTH: return `${term} ${term > 1 ? 'meses' : 'mes'}`;
        case Contract.TERM_METHOD_YEAR: return `${term} ${term > 1 ? 'años' : 'año'}`;
        default: return 'no especificado';
      }
    }

    /** @type {import('./components/List').ListElementObject[]} */
    const elements = [];
    const auxCont = estate.getContract();

    // Price.
    elements.push({ text: `Pago: $ ${Global.formatNumber(auxCont.getPayAmount())} MXN` });
    elements.push({ text: `Frecuencia de pago: ${getPayFrequency(auxCont)}` });
    elements.push({ text: `Duración: ${getTermMethod(auxCont)}` });
    elements.push({ text: `Cargo al inicio de contrato: ${getCharge(auxCont)}` });

    return elements;
  }

  const getEstatePrice = () => {
    if (bidding?.getBids().length > 0) {
      const auxEst = new Estate({ ...estate });
      auxEst.getContract().setPayAmount(Math.max(...bidding.getBids().map(b => b.getAmount())));

      return Estate.priceToString(auxEst);
    } else return Estate.priceToString(estate);
  }

  const getGoogleMapsURL = () => {
    const { lat, lng } = estate.getLocation().getGeoData();
    return `https://www.google.com/maps/?q=${lat},${lng}`;
  }

  const getIncludedServices = () => {
    /** @returns {import('./components/List').ListElementObject} */
    const getListElementObject = name => {
      switch (name) {
        case PSCollection.SERV_ENGY: return { icon: Icons.EngIcon, text: 'Luz' };
        case PSCollection.SERV_FOOD: return { icon: Icons.FoodIcon, text: 'Alimentos' };
        case PSCollection.SERV_GAS: return { icon: Icons.GasIcon, text: 'Gas' };
        case PSCollection.SERV_HKPG: return { icon: Icons.HkpIcon, text: 'Limpieza' };
        case PSCollection.SERV_INET: return { icon: Icons.NetIcon, text: 'Internet' };
        case PSCollection.SERV_WTER: return { icon: Icons.WtrIcon, text: 'Agua y alcantarillado' };
        default: return { icon: Icons.InfoIcon, text: name || 'Servicio indefinido' };
      }
    }

    /** @type {import('./components/List').ListElementObject[]} */
    const elements = [];
    const services = estate.getContract().getInclServs();
    const pSSymb = Object.getOwnPropertyNames(PSCollection).filter(p => /^SERV_.*/.test(p));

    pSSymb.forEach(symb => {
      const auxElm = getListElementObject(PSCollection[symb]);

      elements.push({
        icon: auxElm.icon,
        text: `${auxElm.text}: ${services.find(s => s === PSCollection[symb]) ? 'Sí' : 'No'}.`
      });
    });

    return elements;
  }

  const getRequirements = () => {
    /** @type {import ('./components/List').ListElementObject[]} */
    const elements = [];

    estate.getRequirements().forEach(req => elements.push({ text: req }));

    return elements;
  }

  const getUserRate = () => {
    const value = estate?.getOwner()?.getRate()?.getValue();

    return value || '-.-';
  }

  const getUserRatesCount = () => {
    const count = estate?.getOwner()?.getRate()?.getCount();

    return count
      ? `${count} opini${count > 1 ? 'ones' : 'ón'}.`
      : 'No hay opiniones.';
  }

  const isOwner = () => {
    return estate.getOwner().getId() === currSession.id
      || estate.getCreator()?.getId() === currSession.id;
  }

  const isReadyToRender = () => {
    return Boolean(estate && today);
  }

  const LessorInfoAnimationEndHandler = () => {
    if (UIRender.isHidden(cardRef.current)) setShowLessorData(!showLessorData);
  }

  /** Renders the lessor data div compo */
  const renderLessorData = () => {
    const disableConInfBtn = () => {
      const active = Boolean(bidding?.getStatus());

      return isOwner() || estate.getStatus() !== Estate.STATUS_ACT || (bidding && (
        !bidding?.getId()
        || !active
        || (currSession.tst && bidding.getBids()[0]?.getBidder().getUsername() === currSession.username)
      ));
    }

    return (
      <div className='lessor-data'>
        <h4 className="overset">Propietario</h4>
        {/* <LoadingBlock /> */}
        <UserCard user={estate.getOwner()} fullWidth />
        <div className="rating">
          <h1 className="highlight">{getUserRate()}</h1>
          <h6 className="overset">{getUserRatesCount()}</h6>
          <Starbar stars={getUserRate() === '-.-' ? 0 : getUserRate()} />
          <h6 className="overset">Calificación global</h6>
        </div>
        {canRenderLessorInfo() && <div className="contactinfo">
          <h4 className="highlight">Datos de contacto:</h4>
          <PropDisplay header='Nombre' property={contactInfo.getName()} />
          <div className="flex-box">
            <PropDisplay header='Correo' property={contactInfo.getEmail()} />
            <Button borderless
              empty
              icon={Icons.CopyIcon}
              onClick={() => copyBtnHandleOnClick(contactInfo.getEmail())}
              reduced
              rounded />
          </div>
          <div className="flex-box">
            <PropDisplay header='Teléfono'
              property={`${contactInfo.getPhone().prefix} ${contactInfo.getPhone().number}`} />
            <Button borderless
              empty
              icon={Icons.CopyIcon}
              onClick={() => copyBtnHandleOnClick(`${contactInfo.getPhone().prefix} ${contactInfo.getPhone().number}`)}
              reduced
              rounded />
          </div>
        </div>}
        {!isOwner() && <div className="flex-box m3 jc-left">
          {contactInfo?.getId() === undefined && <div className="child">
            <Button animated
              disabled={disableConInfBtn()}
              fullWidth
              icon={estate.getBidding().getStatus() ? Icons.BidIcon : Icons.MailIcon}
              onClick={contactBtnHandleOnClick}
              rounded
              value={estate.getBidding().getStatus() ? 'Ofertar' : 'Contactar'}
              typeRender={estate.getBidding().getStatus() ? 'warning' : ''} />
          </div>}
          <div className="child auto-width">
            <Button animated
              empty
              icon={Icons.UserIcon}
              onClick={seeProfileBtnHandleOnClick}
              rounded
              value='Ver perfil' />
          </div>
        </div>}
        {/* Bidding hintbox */}
        {bidding !== undefined && estate.getStatus() === Estate.STATUS_ACT && <HintBox
          icon={getBiddingStatusHintboxIcon()}
          message={getBiddingStatusHintboxMessage()}
          type={getBiddingStatusHintboxType()} />}
      </div>
    );
  }

  const requestContactInfoActionHandler = async () => {
    return await DAOServ.post('request_estate_contactinfo', { tst: currSession.tst, idEstate: estate.getId() }, 'JSON')
      .then(query => {
        // idcontactinfo, name, phone, email
        const auxPhone = query['phone'].split('_');

        return Promise.resolve(new Persona({
          email: query['email'],
          id: query['idcontactinfo'],
          firstName: query['name'],
          phone: { code: auxPhone[0], number: auxPhone[2], prefix: auxPhone[1] }
        }));
      }).catch(err => {
        pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
        return Promise.reject(err);
      });
  }

  const requestContactInfoResolveHandler = data => {
    pushMessageHint({
      message: 'Notificación enviada. Ya puedes ver la información de contacto',
      type: 'complete'
    });

    setContactInfo(data);
  }

  const revealerBtnHandleOnClick = () => {
    UIRender.hideElement(cardRef.current)
  }

  const seeProfileBtnHandleOnClick = () => {
    // show PublProfile page. @deprecated Currently does nothing.
  }

  // paramId useEffect.
  useEffect(() => {
    paramRef.current = Number(paramId['idEstate']);
    setEstate();
    UIRender.scrollTo();
  }, [paramId]);

  // Fetch estate useEffect.
  useEffect(() => {
    const fetchEstate = () => {
      if (paramRef.current === undefined) {
        setEstate(new Estate());
      } else {
        DAOServ.fetchEstate(currSession.tst, paramRef.current)
          .then(data => setEstate(data))
          .catch(err => {
            if (ErrHandler.getCode(err) === ErrHandler.CODES.NOT_FOUND) {
              setEstate(new Estate());
            } else {
              pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
              navigate(Global.PATH_HOME); // Go to start menu.
            }
          });
      }
    }

    if (currSession.sessionStatus && !estate) fetchEstate();
  }, [currSession, estate, navigate, pushMessageHint]);

  // Estate's images and current day.
  useEffect(() => {
    /** Obtains images from current page's estate */
    const fetchImages = async () => {
      try {
        const images = estate.getImages();
        let cachedImages = 0;

        // Checking cached images.
        for (let i = 0; i < images.length; i++) {
          const auxCF = getCacheFile(images[i].getName());

          if (auxCF !== undefined) {
            cachedImages++;
            images[i].setSize(auxCF.size)
            images[i].setURLData(typeof auxCF.content === 'string'
              ? auxCF.content
              : await FileChooser.readAsDataURL(auxCF.content)
            );
          }
        }

        if (cachedImages !== images.length) { // Getting remaining files.
          const nonCachedFiles = images.filter(img => img.getURLData() === undefined);
          // Request images.
          const files = await Promise.all(nonCachedFiles.map(nCF => DAOServ.getFile(nCF.getName())));
          // Parse images.
          const urlDatas = await Promise.all(files.map(file => FileChooser.readAsDataURL(file)));
          // Assigning filename.
          for (let i = 0; i < urlDatas.length; i++) {
            nonCachedFiles[i].setURLData(urlDatas[i]);
            nonCachedFiles[i].setSize(files[i].size);
            pushCacheFile({ content: urlDatas[i], name: nonCachedFiles[i].getName(), size: files[i].size });
          }
        }

        setImages(images);
      } catch (err) {
        pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
      }
    }

    const fetchToday = async () => {
      await DAOServ.getCurrentDay()
        .then(data => setToday(data))
        .catch(err => {
          pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
          navigate('/', { replace: true });
        });
    }


    if (estate) {
      fetchToday();
      fetchImages();
    }
  }, [currSession, estate, getCacheFile, navigate, pushCacheFile, pushMessageHint]);

  // bidding & contactinfo useEffect.
  useEffect(() => {
    const fetchBiddingBids = async () => {
      /** @type {Bid[]} */
      const auxBids = [];

      await DAOServ.get('get_bidding_bids', [estate.getBidding().getId()])
        .then(data => {
          /** @type {*[]} */
          const bids = data;

          bids.forEach(bid => {
            const auxBid = new Bid();
            auxBid.getBidder().getPicture().setName(bid['picture']);
            auxBid.getBidder().setUsername(bid['username']);
            auxBid.setAmount(Number(bid['amount']));
            auxBid.setCreationDate(Number(bid['bid_creation_date']));
            auxBid.setId(Number(bid['idbid']));
            auxBids.push(auxBid);
          });

          estate.getBidding().setBids(auxBids);
          setBidding(estate.getBidding());

          if (!estate.getBidding().getStatus())
            fetchContactInfo();
        }).catch(err => {
          pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
          setBidding();
        })

    }

    const fetchContactInfo = async () => {
      if (estate.getStatus() !== Estate.STATUS_ACT)
        setContactInfo(new Persona());
      else {
        DAOServ.post('get_estate_contactinfo', { tst: currSession.tst, idEstate: estate.getId() }, 'JSON')
          .then(query => {
            const auxPhone = query['phone'].split('_');

            setContactInfo(new Persona({
              email: query['email'],
              id: query['idcontactinfo'],
              firstName: query['name'],
              phone: { code: auxPhone[0], number: auxPhone[2], prefix: auxPhone[1] }
            }));
          }).catch(() => setContactInfo(new Persona()));
      }
    }


    if (estate !== undefined) {
      if (estate.getBidding().getId() !== undefined) {
        if (bidding === undefined) fetchBiddingBids(); // Fetch contact info in function.
      } else if (contactInfo === undefined)
        fetchContactInfo();
    }
  }, [bidding, contactInfo, currSession, estate, pushMessageHint]);

  // Search bar useEffect
  useEffect(() => { setSearchMethod(true) }, [setSearchMethod])

  // Render.
  if (estate !== undefined && estate.getId() === undefined) return <PageNotFound />
  else return (<div className="container publ-estate">
    {!isReadyToRender() && <LoadingPanel className='full-height' />}
    {estate && <div className="estate-info">
      {estate.getStatus() !== Estate.STATUS_ACT && <HintBox
        message={estate.getStatus() === Estate.STATUS_INA
          ? 'Esta publicación está inactiva'
          : 'Esta publicación fue suspendida'
        } icon={Icons.InfoIcon}
        type={estate.getStatus() === Estate.STATUS_INA
          ? 'warning'
          : 'error'
        } />}
      <ImageViewer images={images} showImagesList={true} />
      <div className="flex-box">
        <div className="child jc-left">
          <h2 className='estate-title'>{estate.getTitle()}</h2>
        </div>
        {/* bidding icon */}
        {estate.getBidding().getStatus() && <div className="child auto-width">
          <img src={Icons.BiddIcon} alt="bidding" className='img-icon' title='Esta propiedad está en subasta' />
        </div>}
        {/* pill */}
        <div className="child auto-width">
          <span className={`pill ${Estate.sellMethodToClass(estate)}`} />
        </div>
      </div>
      {/* Price */}
      <h3 className="highlight">{getEstatePrice()}</h3>
      {bidding !== undefined && <h5 className="overset">Mejor oferta</h5>}
      {/* Location */}
      <div className="flex-box m3">
        <div className="child jc-left">
          <HintBox message={estate.getLocation().getAddress()}
            empty icon={Icons.LocIcon}
            fullWidth />
        </div>
        <div className="child auto-width">
          <Button borderless
            empty
            icon={Icons.LocIcon}
            onClick={() => window.open(getGoogleMapsURL())}
            reduced
            rounded
            value='Ver en Maps' />
        </div>
      </div>
      {/* Bidding */}
      {estate.getBidding().getId() !== undefined && <div className="box">
        <h4 className="overset">Subasta</h4>
        <BiddingBox bidding={bidding}
          currSessionUsername={currSession.username}
          endTimeOptions={{ today, type: 2 }}
          offerBtn={{
            disabled: estate.getOwner().getUsername() === currSession.username
              || estate.getCreator().getUsername() === currSession.username,
            onClick: contactBtnHandleOnClick
          }} />
      </div>}
      <div className="box">
        <h3 className="overset">Descripción.</h3>
        <p className='description'>{estate.getDescription() || 'empty'}</p>
      </div>
      {estate.getSellMethod() === Estate.ON_LEASE && <div className="box">
        <h3 className="overset">{bidding?.getId() !== undefined ? 'Contrato base.' : 'Contrato.'}</h3>
        <List elements={getContractInfo()} />
        <HintBox icon={Icons.WarningIcon}
          message='Esta información es preliminar y podría estar sujeta a cambios'
          type='warning' />
      </div>}
      {estate.getSellMethod() !== Estate.ON_COMPLEX && <div className="box">
        <h3 className="overset">
          {estate.getSellMethod() === Estate.ON_LEASE ? 'Servicios incluidos' : 'Servicios de la propiedad'}
        </h3>
        <List elements={getIncludedServices()} />
        {estate.getSellMethod() === Estate.ON_LEASE && <HintBox icon={Icons.WarningIcon}
          message='Esta información es preliminar y podría estar sujeta a cambios'
          type='warning' />}
      </div>}
      {estate.getSellMethod() === Estate.ON_LEASE && <div className="box">
        <h3 className="overset">Requisitos.</h3>
        <HintBox icon={Icons.InfoIcon}
          message='Estos son los requisitos que debes cumplir para arrendar esta propiedad' />
        <List elements={getRequirements()} inlineItems />
      </div>}
    </div>}
    {/* Lessor info */}
    {estate !== undefined && <div className="lessor-info">{renderLessorData()}</div>}
    {/* Lessor info revealer for mobile */}
    {!showLessorData && <div className="lessor-info-mobile-revealer" ref={cardRef} onAnimationEnd={LessorInfoAnimationEndHandler}>
      <Button empty
        fullWidth
        icon={Icons.ShowIcon}
        onClick={revealerBtnHandleOnClick}
        rounded
        value='Ver propietario' />
    </div>}
    {/* Lessor info for mobile */}
    {estate !== undefined && showLessorData && <div className="lessor-info-mobile" ref={cardRef} onAnimationEnd={LessorInfoAnimationEndHandler}>
      <Button empty
        fullWidth
        icon={Icons.HideIcon}
        onClick={revealerBtnHandleOnClick}
        rounded
        value='Ocultar propietario' />
      {renderLessorData()}
    </div>}
    {showContactInfoDialog && <Dialog
      action={requestContactInfoActionHandler}
      confirmBtn={{ type: 'complete', value: 'Continuar' }}
      id='request-contactinfo-dialog'
      message={'El propietario sabrá que estás interesado en esta propiedad. Ambos podrán ver su '
        + 'información personal para que puedan contactarse, pero te invitamos a que tomes la '
        + 'iniciativa. :3'}
      onHide={() => setShowContactInfoDialog(false)}
      onReject={err => err && pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' })}
      onResolve={requestContactInfoResolveHandler}
      rejectBtn={{ value: 'Cancelar' }}
      renderButtonsEmpty
      renderButtonsRounded />}
    {showPushBid && <PushBid basePrice={estate.getContract().getPayAmount()}
      bidding={bidding}
      onHide={() => setShowPushBid(false)}
      onReject={errCode => {
        if (errCode === ErrHandler.CODES.BIDDING_IS_INACTIVE) {
          estate.getBidding().setManualStatus(0);
          estate.getBidding().setStatus(false);
        }

        setBidding()
      }}
      onResolve={b => {
        bidding.setBids([b, ...bidding.getBids()]);
        setBidding(new Bidding(bidding));
      }} />}
  </div>);
}

export default PublEstate;