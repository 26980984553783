import { useCallback, useEffect, useRef, useState } from "react";
import Bidding from "../../objects/Bidding";
import Contract from "../../objects/Contract";
import Estate from "../../objects/Estate";
import Global from "../../objects/Global";
import Inputbar from "../../components/Inputbar";
import PetAssistant from '../../components/PetAssistant';
import Selectbar from "../../components/Selectbar";
import Hintbox from "../../components/Hintbox";
import { WarningIcon } from "../../assets/images";

/** Renders a Step3 compo for CreateEstate
 * @param {Object} props The props object.
 * @param {Estate} props.estate The estate that's being created.
 * @param {(e: boolean ) => void} props.setEnableNextBtn Callback function used to
 * enable or disable next step.
 */
const Step3 = props => {
  // *** useCallback ***
  const requestNextStep = useCallback(() => {
    const term = props.estate.getContract().getTerm();
    const termMethod = props.estate.getContract().getTermMethod();
    const sellMethod = props.estate.getSellMethod();
    const onBidding = props.estate.getBidding().getStatus();
    const minBid = props.estate.getBidding().getMinimumBid();
    const price = props.estate.getContract().getPayAmount();
    const payFreq = props.estate.getContract().getPayFrequency();
    const extraCharge = props.estate.getContract().getCharge();

    enableNextStepCallback.current(price > 0
      && (!onBidding || minBid >= Bidding.MIN_BID)
      && (sellMethod === Estate.ON_SALE
        || (sellMethod === Estate.ON_LEASE && payFreq > 0 && term > 0 && termMethod > 0
          && (extraCharge === undefined || extraCharge === -1 || extraCharge > 0)
        )));
  }, [props.estate]);
  // *** useRef ***
  const chargeForceChange = useRef(/** @type {(input: string) => void} */(undefined));
  const payFreqForceChange = useRef(/** @type {(input: string) => void} */(undefined));
  const termForceChange = useRef(/** @type {(input: string) => void} */(undefined));
  /** @type {React.MutableRefObject<function(boolean)>} */
  const enableNextStepCallback = useRef(props.setEnableNextBtn);

  // *** High-priority functions ***
  /** @returns {import('../../components/Inputbar').InputbarPlaceholderObject} */
  const getChargeInputPlaceholder = () => {
    const chargeType = props.estate.getContract().getChargeType();

    if (chargeType === Contract.CHARGE_CUSTOM) {
      return { default: 'Cantidad del cargo (MXN)', onIsValidFail: 'Ingresa un cargo mayor a cero' }
    } else if (chargeType === Contract.CHARGE_SAME_AS_PAY) {
      return { default: 'Cargo igual que la renta' }
    } else {
      return { default: 'Cargo no requerido' }
    }
  }

  /** @returns {import('../../components/Inputbar').InputbarPlaceholderObject} */
  const getTermPlaceholder = () => {
    switch (props.estate.getContract().getTermMethod()) {
      case Contract.TERM_METHOD_DAY: {
        return {
          default: 'Duración (días)',
          onIsValidFail: 'Ingresa de 1 a 31 días'
        };
      } case Contract.TERM_METHOD_MONTH: {
        return {
          default: 'Duración (meses)',
          onIsValidFail: 'Ingresa de 1 a 11 meses'
        };
      } case Contract.TERM_METHOD_YEAR: {
        return {
          default: 'Duración (años)',
          onIsValidFail: 'Ingresa de 1 a 10 años'
        }
      } default: {
        return { default: 'Método aún sin especificar' }
      }
    }
  }

  // *** useState (low-priority) ***
  const [chargePlaceholder, setChargePlaceholder] = useState(getChargeInputPlaceholder());
  const [termPlaceholder, setTermPlaceholder] = useState(getTermPlaceholder());

  const chargeInputHandleOnChange = input => {
    const contract = props.estate.getContract();

    if (!input) {
      if (contract.getChargeType() === Contract.CHARGE_CUSTOM) {
        contract.setCharge(Contract.CHARGE_CUSTOM);
      }
    } else {
      contract.setCharge(Number(input));
    }

    requestNextStep();
  }

  const chargeSelectHandleOnChage = option => {
    props.estate.getContract().setCharge(option || undefined);
    chargeForceChange.current(getChargeInputDefaultValue());
    setChargePlaceholder(getChargeInputPlaceholder());
    requestNextStep();
  }

  const getChargeInputDefaultValue = () => {
    const charge = props.estate.getContract().getCharge();
    const chargeType = props.estate.getContract().getChargeType();

    if (chargeType === Contract.CHARGE_CUSTOM) { // Custom charge.
      return charge === Contract.CHARGE_CUSTOM ? '' : charge;
    } else if (chargeType === Contract.CHARGE_SAME_AS_PAY) { // Charge same as pay.
      return props.estate.getContract().getPayAmount();
    } else { // Charge not required
      return '';
    }
  }

  const getChargeOptions = () => {
    /** @type {import('../../components/Selectbar').OptionObject[]} */
    const options = [];

    options.push({ displayValue: 'Igual que la renta', value: Contract.CHARGE_SAME_AS_PAY });
    options.push({ displayValue: 'Personalizado', value: Contract.CHARGE_CUSTOM });

    return options;
  }

  const getPayFrequencyOptions = () => {
    const termMethod = props.estate.getContract().getTermMethod();
    /** @type {import('../../components/Selectbar').OptionObject[]} */
    const options = [
      { displayValue: 'Pago único', value: Contract.PAY_FREQ_UNIQUE },
      { displayValue: 'Diario', value: Contract.PAY_FREQ_DAILY },
    ];

    if (termMethod > Contract.TERM_METHOD_DAY)
      options.push({ displayValue: 'Mensual', value: Contract.PAY_FREQ_MONTHLY });

    if (termMethod > Contract.TERM_METHOD_MONTH)
      options.push({ displayValue: 'Anual', value: Contract.PAY_FREQ_YEARLY })

    return options;
  }

  const getPricePlaceholder = () => {
    return props.estate.getBidding().getStatus()
      ? (props.estate.getSellMethod() === Estate.ON_LEASE
        ? 'Renta publicada (MXN)' : 'Precio publicado (MXN)')
      : (props.estate.getSellMethod() === Estate.ON_LEASE
        ? 'Pago por periodo (MXN)'
        : 'Precio (MXN)');
  }

  /** @returns {import('../../components/Selectbar').OptionObject[]} */
  const getTermMethodOptions = () => {
    return [
      { displayValue: 'En años', value: Contract.TERM_METHOD_YEAR },
      { displayValue: 'En días', value: Contract.TERM_METHOD_DAY },
      { displayValue: 'En meses', value: Contract.TERM_METHOD_MONTH }
    ]
  }

  const minBidHandleOnChange = input => {
    props.estate.getBidding().setMinimumBid((input && Number(input)) || undefined);
    requestNextStep();
  }

  const payFrequencyHandleOnChange = option => {
    props.estate.getContract().setPayFrequency(option);
    requestNextStep();
  }

  const priceHandleOnChange = input => {
    props.estate.getContract().setPayAmount((input && Number(input)) || undefined);

    if (props.estate.getContract().getChargeType() === Contract.CHARGE_SAME_AS_PAY)
      chargeForceChange.current(input || '');

    requestNextStep();
  }

  const termHandleOnChange = input => {
    props.estate.getContract().setTerm((input && Number(input)) || undefined);
    requestNextStep();
  }

  const termMethodHandleOnChange = option => {
    const contract = props.estate.getContract();

    contract.setPayFrequency();
    contract.setTermMethod(option);
    contract.setTerm();
    payFreqForceChange.current('');
    termForceChange.current('');
    setTermPlaceholder(getTermPlaceholder());
    requestNextStep();
  }

  useEffect(() => { requestNextStep() }, [requestNextStep]);

  return (
    <div className="popup-content create-estate-content" >
      <PetAssistant pet="kevin" animOnDwarf
        message={"¡Muy bien! Háblame sobre las ganacias que quieres recibir por esta propiedad."
          + " También podrás modificar estos valores cuando estés firmando un contrato."} />
      <div className="box">
        <h4 className="highlight">Precio</h4>
        <div className="flex-box wrap">
          <div className="child m3">
            <Inputbar onChange={priceHandleOnChange}
              defaultValue={props.estate.getContract().getPayAmount() || ''}
              filters={[{ regExp: Global.REGEXP_FILTER_DECIMAL }, { regExp: /^0+\.|^\./, replace: '0.' }]}
              inputMode="decimal"
              isValid={input => input && Number(input) > 0}
              maxLength={10}
              placeholder={{ default: getPricePlaceholder(), onIsValidFail: 'Ingresa un valor mayor que creo', }}
              required />
          </div>
          {props.estate.getBidding().getStatus() && <div className="child m3">
            <Inputbar onChange={minBidHandleOnChange}
              defaultValue={props.estate.getBidding().getMinimumBid() || ''}
              filters={[{ regExp: Global.REGEXP_FILTER_DECIMAL }, { regExp: /^0+|^\./ }]}
              inputMode="decimal"
              isValid={input => input && Number(input) >= Bidding.MIN_BID}
              maxLength={7}
              placeholder={{
                default: 'Puja mínima (MXN)',
                onIsValidFail: `Ingresa un valor mayor o igual a ${Bidding.MIN_BID}`,
              }}
              required />
          </div>}
        </div>
      </div>
      {props.estate.getSellMethod() === Estate.ON_LEASE && <div className="box">
        <h4 className="highlight">Contrato</h4>
        <div className="flex-box wrap">
          <div className="child m3">
            <Selectbar options={getTermMethodOptions()}
              defaultValue={props.estate.getContract().getTermMethod()}
              onChange={termMethodHandleOnChange}
              placeholder={'Método de duración'}
              required />
          </div>
          <div className="child m3">
            <Inputbar onChange={termHandleOnChange}
              defaultValue={props.estate.getContract().getTerm()}
              disabled={!props.estate.getContract().getTermMethod()}
              filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }, { regExp: /^0+/ }]}
              forceChangeRef={termForceChange}
              inputMode="numeric"
              isValid={input => Contract.isTermValid(props.estate.getContract(), input)}
              maxLength={2}
              placeholder={termPlaceholder}
              required />
          </div>
          <div className="child m3">
            <Selectbar options={getPayFrequencyOptions()}
              defaultValue={props.estate.getContract().getPayFrequency()}
              disabled={!props.estate.getContract().getTermMethod()}
              forceChangeRef={payFreqForceChange}
              onChange={payFrequencyHandleOnChange}
              placeholder="Frecuencia de pago"
              required />
          </div>
        </div>
        <h4 className="highlight">Cargo extra</h4>
        <div className="flex-box m3 wrap">
          <div className="child">
            <Selectbar options={getChargeOptions()}
              defaultValue={props.estate.getContract().getChargeType()}
              onChange={chargeSelectHandleOnChage}
              placeholder="Cargo al iniciar contrato (ninguno por defecto)"
              undefinedOption="Ninguno" />
          </div>
          <div className="child">
            <Inputbar onChange={chargeInputHandleOnChange}
              defaultValue={getChargeInputDefaultValue()}
              disabled={props.estate.getContract().getChargeType() !== Contract.CHARGE_CUSTOM}
              filters={[
                { regExp: Global.REGEXP_FILTER_DECIMAL },
                { regExp: /^0{2,}\./, replace: '0.' },
                { regExp: /\.{2,}/, replace: '.' }]}
              forceChangeRef={chargeForceChange}
              inputMode="decimal"
              isValid={input => input && Number(input) > 0}
              maxLength={11}
              placeholder={chargePlaceholder}
              required />
          </div>
        </div>
      </div>}
      {props.estate.getSellMethod() === Estate.ON_LEASE && <Hintbox icon={WarningIcon}
        message={'El primer pago de renta será requerido cuando un contrato entra en vigor, '
          + 'junto con el cargo de primer pago (si fue especificado), y deberá pagarse durante o '
          + 'antes de la fecha límite'}
        type="warning" />}
    </div>
  );
}

export default Step3;