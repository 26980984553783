import './styles/contact-us.css';
import { useContext, useEffect } from 'react';
import { globalContext } from './context/GlobalContext';
import Button from "./components/Button";
import UIRender from './objects/UIRender';
import { LukaSupportImg, MailIcon } from "./assets/images";

const ContactUs = () => {
  // *** useContext ***
  const { setSearchMethod, setShowFooter } = useContext(globalContext);

  useEffect(() => {
    setSearchMethod();
    setShowFooter(true);
    UIRender.scrollTo();
  }, [setSearchMethod, setShowFooter]);

  return (<div className="contact-us container">
    <img className='pet-img' src={LukaSupportImg} alt="contact-us-pet" />
    <div className="info-container">
      <h4 className='title'>Contacto</h4>
      <p className='main-message'>
        ¿Necesitas soporte, quieres cotizar una suscripción personalizada, quieres reportar errores o
        solo tienes dudas? Envíanos un correo.
      </p>
      <p className="sub-message">
        Te responderemos en un plazo máximo de 24 horas.
      </p>
      <div className="flex-box m5">
        <Button animated
          icon={MailIcon}
          onClick={'mailto:soporte@racchome.com?subject=Solicitud%20de%20soporte'}
          value="Contactar" />
      </div>
    </div>
  </div>);
}

export default ContactUs;