import './styles/myprofile.css';
import { globalContext } from './context/GlobalContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BankCard from './objects/BankCard';
import BankCardEditor from './components/popups/BankCardEditor';
import Button from './components/Button';
import DAOServ from './objects/DAOServ';
import Dialog from "./components/popups/Dialog";
import ErrHandler from './objects/ErrHandler';
import FileChooser from './objects/FileChooser';
import Global from './objects/Global';
import Hintbox from './components/Hintbox';
import ImageCropper from './objects/ImageCropper';
import LicenseCard from './components/LicenseCard';
import LoadingBlock from './components/LoadingBlock';
import MailConfig from './objects/MailConfig';
import NotFoundBox from './components/NotFoundBox';
import Pass from './objects/Pass';
import Persona from './objects/Persona';
import PropDisplay from './components/PropDisplay';
import SetField from './components/popups/SetField';
import Switch from './components/Switch';
import User from './objects/User';
// Icons
import * as Icons from './assets/images';
import UIRender from './objects/UIRender';

/** @typedef {import('./components/popups/SetField').SetFieldPropsObject} SetFieldPropsObject */
/** @typedef {import('./components/popups/Dialog').DialogPropsObject} DialogPropsObject */
/** The ShowPopupObject typedef
 * @typedef {Object} MyProfileShowPopupObject
 * @property {'bank-card'|'dialog'|'set-field'} type The popup type to render.
 * @property {SetFieldPropsObject&DialogPropsObject} [attached] The data included.
 * If type equals to 'bank-card', this attribute won't be used
 */

/** Renders MyProfile compo page */
const MyProfile = () => {
  // *** useContext ***
  const {
    currSession,
    currSessionPicture,
    setCurrSession,
    setCurrSessionPicture,
    setShowLoadingScreen,
    pushCacheFile,
    pushMessageHint,
    setSearchMethod,
    timezoneOffset
  } = useContext(globalContext);
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useRef ***
  const today = useRef(/** @type {number} */(undefined));
  // *** useState ***
  const [allowButtons, setAllowButtons] = useState((undefined));
  const [bankCard, setBankCard] = useState(/** @type {BankCard} */(undefined));
  const [changingPicture, setChangingPicture] = useState(false);
  const [license, setLicense] = useState(/** @type {import('./objects/License').default} */(undefined));
  const [mailConfig, setMailConfig] = useState(/** @type {MailConfig&-1} */(undefined));
  const [onSwitchEdit, setOnSwitchEdit] = useState(false);
  const [popup, setPopup] = useState(/** @type {MyProfileShowPopupObject} */(undefined));
  const [sendEmailCode, setSendEmailCode] = useState(false);
  const [user, setUser] = useState(/** @type {User} */(undefined));

  const changeImgHandleOnClick = async () => {
    /** @type {import('./objects/GenericFile').default} */
    let auxGF;

    setShowLoadingScreen(true);

    await DAOServ.fetchPass(currSession.tst)
      .then(pass => {
        if (!pass.getPass(Pass.UP_PICT))
          return Promise.reject(ErrHandler.getError(ErrHandler.CODES.ACCESS_DENIED));

        setShowLoadingScreen(false);
        const fC = new FileChooser();

        return fC.open('image', ['jpeg', 'jpg']); // Open file.
      }).then(gF => {
        auxGF = gF;

        return ImageCropper.cropImage(gF, ImageCropper.R_480P)
      }).then(urlData => {
        if (urlData === currSessionPicture) // File already uploaded.
          return Promise.reject(ErrHandler.getError(ErrHandler.CODES.PARAM_DUPLICATE));

        setChangingPicture(true);

        // File update.
        const payload = new FormData();
        auxGF.setURLData(urlData);
        payload.append('tst', currSession.tst);
        payload.append('file', auxGF.toFile());

        return DAOServ.post('update_user_picture', payload, 'MULTI');
      }).then(() => {
        setCurrSessionPicture(auxGF.getURLData());
        pushMessageHint({ message: 'Foto actualizada', type: 'complete' });
        pushCacheFile({ content: auxGF.getURLData(), name: auxGF.getName(), size: auxGF.getSize() });
      }).catch(err => {
        const code = ErrHandler.getCode(err);

        if (code !== ErrHandler.CODES.USER_ABORT) {
          pushMessageHint({
            message: code === ErrHandler.CODES.PARAM_DUPLICATE
              ? 'Ya tienes esta foto subida'
              : code === ErrHandler.getCodes().PARAM_INVALID
                ? 'La imagen es demasiado pequeña'
                : ErrHandler.parseError(err),
            type: code.includes(ErrHandler.CODES.PARAM_DUPLICATE) ? 'warning' : 'error'
          });
        }
      });

    setShowLoadingScreen(false);
    setChangingPicture(false);
  }

  const aboutChargeMethodBtnHandleOnClick = () => {
    setPopup({
      type: 'dialog',
      attached: {
        message: 'Cuando tus arrendatarios paguen sus contratos a través de nuestra página, '
          + 'recibirás los pagos en esta tarjeta. Si está expirada, los pagos serán rechazados.',
      }
    })
  }

  const deleteChargeMethodBtnHandleOnClick = () => {
    setPopup({
      type: 'dialog',
      attached: {
        action: () => DAOServ.post('delete_bank_card', { tst: currSession.tst }, 'JSON'),
        confirmBtn: { icon: Icons.DeleteIcon, onWaitValue: 'Eliminando... ', type: 'error', value: 'Eliminar' },
        message: 'Si tienes propiedades con contratos activos, tus arrendatarios deberán'
          + ' pagarte mediante opciones de terceros y deberás registrar las transacciones'
          + ' manualmente.',
        onReject: err => err && pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }),
        onResolve: () => {
          setBankCard(new BankCard());
          pushMessageHint({ message: 'Tarjeta eliminada', type: 'complete' });
        }, rejectBtn: { value: 'Cancelar' },
        renderButtonsSwitched: true
      }
    })
  }

  /** Edit attribute callback function handler.
   * @param {'birthdate'|'email'|'first-name'|'last-name'|'password'|'tel'|'rfc'} target
   * Attribute to edit.
   */
  const editBtnHandleOnClick = async target => {
    if (!isTargetValid(target)) {
      pushMessageHint({ message: 'Atributo no encontrado', type: 'error' });
      return;
    }

    let previousPasscode;

    setShowLoadingScreen(true);

    try {
      const res = target !== 'password'
        ? await DAOServ.fetchPass(currSession.tst)
        : await DAOServ.post('get_user_passcode', { tst: currSession.tst }, 'JSON');

      if (target === 'password') previousPasscode = res;
      else if (!res.getPass(Pass.UP_SINF))
        throw new Error(ErrHandler.getError(ErrHandler.CODES.ACCESS_DENIED));
    } catch (err) {
      pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
      return;
    } finally {
      setShowLoadingScreen(false);
    }

    let colName;

    /** @type {import('./components/popups/SetField').SetFieldPropsObject&1} */
    let sFProps = {
      action: pkg => {
        let payload, func;

        if (target === 'password') {
          payload = { curr: pkg.newValue, prev: pkg.oldValue, tst: currSession.tst };
          func = 'update_passcode';
        } else {
          const data = target === 'birthdate'
            ? Global.dateToMilliUTC(Global.transformDate(pkg.newValue), -timezoneOffset)
            : pkg.newValue;
          func = 'update_user';
          payload = { column: colName, data, tst: currSession.tst };
        }

        return DAOServ.post(func, payload, 'JSON')
      }, onReject: err => pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' })
    };

    // Assigning values.
    switch (target) {
      case 'birthdate': {
        const currDay = today.current;

        /** @type {import ('./components/popups/SetField').InputObject} */
        const options = {
          defaultValue: Global.transformDateForInput(user.getBirthdate()),
          isValid: input => input
            && currDay - Global.dateToMilliUTC(Global.transformDate(input), -timezoneOffset)
            >= Persona.MIN_AGE_MILLI,
          placeholder: {
            default: 'Fecha de nacimiento',
            onIsValidFail: 'Solo se admite la mayoría de edad',
          }, required: true,
          type: 'date'
        };
        colName = 'birthdate';
        sFProps.onResolve = newVal => {
          const date = Date.parse(newVal) + (-1 * timezoneOffset);
          user.setBirthdate(date);
          setUser(new User(user));
          pushMessageHint({ message: 'Fecha actualizada', type: 'complete' });
        };
        sFProps.options = options;
        break;
      } case 'email': {
        /** @type {import ('./components/popups/SetField').InputObject} */
        const options = {
          defaultValue: user.getEmail(),
          filters: [{ regExp: Global.REGEXP_FILTER_EMAIL }],
          isValid: input => Global.REGEXP_EMAIL.test(input),
          maxLength: Persona.EMAIL_MAX_LENGTH,
          placeholder: { default: 'Correo electrónico', onIsValidFail: 'Correo inválido' },
          required: true,
          textTransform: 'lowercase',
          type: 'email'
        };
        colName = 'email';
        sFProps.onResolve = newVal => {
          user.setEmail(newVal);
          user.setEmailVerified(false);
          setUser(new User(user));
          pushMessageHint({ message: 'Correo actualizado. Revisa tu bandeja para verificarlo' });
        };
        sFProps.options = options
        sFProps.hint = 'Te enviaremos un correo para que puedas verificar la nueva dirección';
        break;
      } case 'first-name': {
        /** @type {import ('./components/popups/SetField').InputObject} */
        const options = {
          defaultValue: user.getFirstName(),
          filters: [{ regExp: Global.REGEXP_FILTER_SYMBOLS }, { regExp: /\d/g }],
          maxLength: Persona.NAME_MAX_LENGTH,
          minLength: Persona.NAME_MIN_LENGTH,
          onBlur: input => input?.replace(/\s+/g, ' ').replace(/^\s|\s$/, ''),
          placeholder: { default: 'Nombre(s)' },
          required: true,
          textTransform: 'capitalize'
        };

        colName = 'first_name';
        sFProps.onResolve = newVal => {
          user.setFirstName(newVal);
          setUser(new User(user));
          pushMessageHint({ message: 'Nombre actualizado', type: 'complete' });
        };
        sFProps.options = options;
        break;
      } case 'last-name': {
        /** @type {import ('./components/popups/SetField').InputObject} */
        const options = {
          defaultValue: user.getLastName(),
          filters: [{ regExp: Global.REGEXP_FILTER_SYMBOLS }, { regExp: /\d/g }],
          maxLength: Persona.NAME_MAX_LENGTH,
          minLength: Persona.NAME_MIN_LENGTH,
          onBlur: input => input?.replace(/\s+/g, ' ').replace(/^\s|\s$/, ''),
          placeholder: { default: 'Apellido(s)' },
          required: true,
          textTransform: 'capitalize'
        };
        colName = 'last_name';
        sFProps.onResolve = newVal => {
          user.setLastName(newVal);
          setUser(new User(user));
          pushMessageHint({ message: 'Nombre actualizado', type: 'complete' });
        };
        sFProps.options = options;
        break;
      } case 'password': {
        /** @type {import ('./components/popups/SetField').InputObject} */
        const options = {
          confirmation: {
            default: 'Reingresa la contraseña nueva',
            onIsValidFail: 'Las contraseñas no coinciden'
          }, filters: [{ regExp: Global.REGEXP_FILTER_FORBIDDEN_SYMBOLS }, { regExp: /\s+/g }],
          isValid: input => !Global.REGEXP_RACCHOME.test(input),
          maxLength: User.PASSWORD_MAX_LENGTH,
          minLength: User.PASSWORD_MIN_LENGTH,
          placeholder: {
            default: 'Contraseña nueva',
            onIsValidFail: 'Contraseña inválida',
          }, previousValue: {
            placeholder: {
              default: 'Contraseña anterior',
              onIsValidFail: 'Contraseña incorrecta'
            }, priori: input => input !== undefined ? Global.md5(input) : input,
            value: previousPasscode
          }, required: true,
          type: 'password'
        };

        colName = 'passcode';
        sFProps.hint = 'Ingresa una contraseña que no vayas a olvidar';
        sFProps.onResolve = () => pushMessageHint({ message: 'Contraseña actualizada', type: 'complete' });
        sFProps.options = options;
        break;
      } case 'tel': {
        const auxPhone = user.getPhone();
        /** @type {import ('./components/popups/SetField').InputObject} */
        const options = {
          defaultValue: `${auxPhone.code}_${auxPhone.prefix}_${auxPhone.number}`,
          filters: [{ regExp: Global.REGEXP_FILTER_INTEGER }],
          isValid: input => Global.REGEXP_PHONE_NUMBER.test(input),
          maxLength: 10,
          minLength: 10,
          placeholder: 'Número',
          required: true,
          type: 'tel'
        };
        colName = 'phone';
        sFProps.hint = 'Si tu teléfono tiene menos de 10 dígitos, empieza el número con ceros'
          + ' hasta que el número sea aceptado (hasta cuatro ceros).';
        sFProps.onResolve = newVal => {
          const phone = newVal.split('_');
          user.setPhone({ code: phone[0], number: phone[2], prefix: phone[1] });
          setUser(new User(user));
          pushMessageHint({ message: 'Teléfono actualizado', type: 'complete' });
        };
        sFProps.options = options;
        break;
      } case 'genre': {
        /** @type {import ('./components/popups/SetField').SelectObject} */
        const options = {
          placeholder: 'Género',
          defaultValue: user.getGenre() ?? 3,
          required: true,
          values: [
            { displayValue: 'Hombre', value: Persona.GENRE_MALE },
            { displayValue: 'Mujer', value: Persona.GENRE_FEMALE },
            { displayValue: 'Otro o prefiero no decirlo', value: 3 }
          ]
        };

        colName = 'genre';
        sFProps.onResolve = newVal => {
          user.setGenre(newVal);
          setUser(new User(user));
          pushMessageHint({ message: 'Género actualizado', type: 'complete' });
        };
        sFProps.options = options;
        break;
      } default: { // RFC.
        /** @type {import ('./components/popups/SetField').InputObject} */
        const options = {
          filters: [{ regExp: Global.REGEXP_FILTER_ALL_SYMBOLS }],
          isValid: input => user.testRFC(input),
          maxLength: Persona.RFC_LENGTH,
          minLength: Persona.RFC_LENGTH,
          placeholder: {
            default: 'RFC',
            onIsValidFail: 'RFC inválido o no coincide con tu información',
            onMinLengthFail: 'El RFC debe contener 13 caracteres'
          }, required: true,
          textTransform: 'uppercase'
        }
        colName = 'rfc';
        sFProps.onResolve = newVal => {
          user.setRFC(newVal);
          setUser(new User(user));
          pushMessageHint({ message: 'RFC registrado', type: 'complete' });
        }
        sFProps.options = options;
        break;
      }
    }

    setPopup({ type: 'set-field', attached: sFProps, hint: '' });
  }

  /** Checks if target received by editBtnHandleOnClick is valid
   * @param {string} target
   */
  const isTargetValid = target => {
    return target === 'birthdate'
      || target === 'email'
      || target === 'first-name'
      || target === 'last-name'
      || target === 'genre'
      || target === 'password'
      || target === 'tel'
      || (target === 'rfc' && !user?.getRFC());
  }

  /** The MailConfig Switch Action handler.
   * @param {boolean} newState 
   * @param {string} id 
   */
  const mailConfigSwitchActionHandler = async (newState, id) => {
    setOnSwitchEdit(true);

    let res;

    await DAOServ.post('update_user_mailconfig', { tst: currSession.tst, id, val: newState }, 'JSON')
      .then(() => {
        mailConfig.setConfig(id, newState);
        res = Promise.resolve();
        pushMessageHint({ message: 'Preferencias de correo actualizadas', type: 'complete' });
      }).catch(err => res = Promise.reject(err))
      .finally(setOnSwitchEdit(false));

    return res;
  }

  const mailConfigSwitchPromiseRejectHandler = err => {
    pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
  }

  const resendEmailCodeButtonClickHandler = () => {
    setSendEmailCode(true);

    DAOServ.post('resend_email_code', { tst: currSession.tst }, 'JSON')
      .then(() => pushMessageHint({ message: 'El código fue reenviado' }))
      .catch(err => {
        let message, type;

        if (ErrHandler.getCode(err) === ErrHandler.CODES.NOT_FOUND) {
          message = 'Ya fue verificado o el correo anterior fue restaurado';
          type = 'warning';
        } else {
          message = ErrHandler.parseError(err);
          type = 'error';
        }

        pushMessageHint({ message, type });
      }).finally(() => setSendEmailCode(false));
  }

  useEffect(() => { UIRender.scrollTo() }, []);

  // Fetch info
  useEffect(() => {
    const fetchAll = async () => {
      try {
        today.current = await DAOServ.getCurrentDay();
        await fetchUserData();
        await fetchLicense();

        if (!currSession.isSubuser) await fetchMailConfig();
        if (!currSession.isSubuser) await fetchBankCard();
      } catch (err) {
        const code = ErrHandler.getCode(err);

        pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });

        if (code === ErrHandler.CODES.TST_FAIL) {
          setCurrSession();
          navigate('/', { replace: true });
        }
      }
    }

    const fetchBankCard = async () => {
      try {
        const query = await DAOServ.post('get_bank_card', { tst: currSession.tst }, 'JSON');
        // Getting key.
        let key = '', nonce = 0;
        const auxUserData = {
          creation_date: currSession.creationDate,
          iduser: currSession.id,
          username: currSession.username
        }

        do {
          key = Global.md5(auxUserData, nonce);
          nonce++;
        } while (key.substring(0, 3) !== '000');

        const auxBC = Global.decrypt(query['cardNumber'], key);
        const exp = Number(Global.decrypt(query['exp'], key));

        setBankCard(new BankCard({
          cardNumber: `${auxBC.substring(0, 4)} **** **** ${auxBC.substring(12, 16)}`,
          exp
        }));
      } catch (err) {
        const code = ErrHandler.getCode(err);

        if (code === ErrHandler.CODES.NOT_FOUND) setBankCard(new BankCard());
        else pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
      }
    }

    const fetchMailConfig = async () => {
      try {
        const query = await DAOServ.post('get_user_mailconfig', { username: currSession.username }, 'JSON');
        const auxMailConfig = new MailConfig(query);
        auxMailConfig.setId(query['idmailconfig']);
        setMailConfig(auxMailConfig);
      } catch (err) {
        pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
        setMailConfig(-1);
      }
    }

    const fetchLicense = async () => {
      const lS = await DAOServ.fetchLicenseStatistics(currSession.tst);

      setAllowButtons({
        map: true,
        users: lS.max_sub_acc > 0
      })
    }

    const fetchUserData = async () => {
      const data = await DAOServ.post('get_user_data', { tst: currSession.tst }, 'JSON');

      const phone = data.phone.split('_');
      const usrAux = new User();
      usrAux.setId(data.iduser);
      usrAux.setBirthdate(Number(data.birthdate));
      usrAux.setCreationDate(data.creation_date);
      usrAux.setEmail(data.email);
      usrAux.setEmailVerified(data['email_ver']);
      usrAux.setFirstName(data.first_name);
      usrAux.setGenre(data.genre);
      usrAux.setSuperuser(data.idsuper ? new User({ id: data.idsuper }) : undefined);
      usrAux.setLastName(data.last_name);
      usrAux.setPhone({ code: phone[0], number: phone[2], prefix: phone[1] });
      usrAux.setRFC(data.rfc);
      usrAux.setUsername(data.username);
      setUser(usrAux);
    }

    setSearchMethod();

    if (currSession.sessionStatus) {
      if (currSession.sessionStatus === User.STATUS_ACT)
        fetchAll();
      else
        navigate('/', { replace: true });
    }
  }, [currSession, navigate, pushMessageHint, setCurrSession, setSearchMethod]);

  // Susbcription
  useEffect(() => {
    const fetchSubscription = async () => {
      const query = await DAOServ.fetchSubscription(currSession.tst, true);
      setLicense(query.getLicense());
    }

    if (user !== undefined && license === undefined)
      fetchSubscription();
  }, [currSession, license, user]);

  return (
    <div className='container my-profile'>
      <h1 className='highlight'>Tu información.</h1>
      {/* User and personal data */}
      <div className="flex-box wrap jc-center">
        {/* User data */}
        <div className="box borderless reduced">
          <h3 className="highlight">Datos de usuario.</h3>
          <div className='flex-box wrap'>
            <div className="child auto-width img-editor">
              <div className="img-container">
                {(!user || changingPicture) && <LoadingBlock />}
                {user && !changingPicture && <img src={currSessionPicture}
                  alt="profile-img"
                  className="profile-img" />}
                {user && !changingPicture && !popup && !sendEmailCode && !onSwitchEdit
                  && <img src={Icons.NewPicIcon}
                    className='profile-img clicker'
                    alt="change-img"
                    onClick={changeImgHandleOnClick}
                  />}
              </div>
            </div>
            <div className='child no-flex'>
              {user === undefined && <PropDisplay waiting />}
              {user !== undefined && <h2 className='highlight'>{`@${user.getUsername()}`}</h2>}
              <LicenseCard license={license} reduced />
              {user !== undefined && !currSession.isSubuser && <Button empty
                disabled={!user || popup || sendEmailCode || onSwitchEdit}
                fullWidth
                icon={Icons.SubsIcon}
                id='subscribe'
                onClick={() => navigate(Global.PATH_LICENSE_MANAGEMENT)}
                value='Gestionar suscripción' />}
            </div>
          </div>
          <div className='flex-box'>
            {user && <div className="child auto-width m3">
              <Button rounded
                disabled={popup || sendEmailCode || onSwitchEdit}
                empty
                reduced
                icon={Icons.EditIcon}
                onClick={() => editBtnHandleOnClick('tel')} />
            </div>}
            <div className="child jc-left m3">
              <PropDisplay id='tel'
                className={'max-width'}
                header='Teléfono'
                waiting={!user}
                property={user?.getFullPhone(' ')} />
            </div>
          </div>
          <div className='flex-box'>
            {user && <div className="child auto-width m3">
              <Button rounded
                disabled={popup || sendEmailCode || onSwitchEdit}
                empty
                reduced
                icon={Icons.EditIcon}
                onClick={() => editBtnHandleOnClick('email')} />
            </div>}
            <div className="child jc-left">
              <PropDisplay id='email'
                className={'max-width'}
                header='Correo electrónico'
                waiting={!user}
                property={user?.getEmail()} />
            </div>
            {user !== undefined && <div className={"child auto-width m3"}>
              <div className={`ver-icon${!user.getEmailVerified() ? ' warning' : ''}`}>
                <img src={user.getEmailVerified() ? Icons.CheckIcon : Icons.InfoIcon} alt="email-ver" />
              </div>
            </div>}
          </div>
          {user !== undefined && !user.getEmailVerified() && <div className="flex-box m3">
            <div className="child auto-width">
              <Button disabled={popup || onSwitchEdit}
                empty
                icon={Icons.MailIcon}
                isWaiting={sendEmailCode}
                onClick={resendEmailCodeButtonClickHandler}
                onWaitValue='Reenviando'
                value='Reenviar' />
            </div>
            <div className="child">
              <Hintbox icon={Icons.InfoIcon}
                type='warning'
                message={'No has verificado tu correo. Revisa tu bandeja de entrada o de '
                  + 'spam para verificarlo, o reenvía el código de verificación.'
                } />
            </div>
          </div>}
          <Button disabled={!user || popup || sendEmailCode || onSwitchEdit}
            value='Cambiar contraseña'
            empty
            fullWidth
            icon={Icons.EditIcon}
            onClick={() => editBtnHandleOnClick('password')} />
        </div>
        {/* Personal data */}
        <div className="box borderless reduced">
          <h3 className="highlight">Datos personales.</h3>
          {/* First name */}
          <div className='flex-box'>
            {user && <div className="child m3 auto-width">
              <Button rounded
                disabled={popup || sendEmailCode || onSwitchEdit}
                empty
                reduced
                icon={Icons.EditIcon}
                onClick={() => editBtnHandleOnClick('first-name')} />
            </div>}
            <div className="child m3 jc-left">
              <PropDisplay id='first-name'
                className={'max-width'}
                header='Nombre(s)'
                waiting={!user}
                property={user?.getFirstName()} />
            </div>
          </div>
          {/* Last name */}
          <div className='flex-box'>
            {user && <div className="child m3 auto-width">
              <Button rounded
                disabled={popup || sendEmailCode || onSwitchEdit}
                empty
                reduced
                icon={Icons.EditIcon}
                onClick={() => editBtnHandleOnClick('last-name')} />
            </div>}
            <div className="child m3 jc-left">
              <PropDisplay id='last-name'
                className={'max-width'}
                header='Apellido(s)'
                waiting={!user}
                property={user?.getLastName()} />
            </div>
          </div>
          {/* Genre */}
          <div className='flex-box'>
            {user && <div className="child m3 auto-width">
              <Button rounded
                disabled={popup || sendEmailCode || onSwitchEdit}
                empty
                reduced
                icon={Icons.EditIcon}
                onClick={() => editBtnHandleOnClick('genre')} />
            </div>}
            <div className="child m3 jc-left">
              <PropDisplay id='first-name'
                className={'max-width'}
                header='Género'
                waiting={!user}
                property={user?.getGenre() === Persona.GENRE_MALE
                  ? 'Hombre' : user?.getGenre() === Persona.GENRE_FEMALE
                    ? 'Mujer' : 'Otro o prefiero no decirlo'} />
            </div>
          </div>
          {/* Birthdate */}
          <div className='flex-box'>
            {user && <div className="child m3 auto-width">
              <Button rounded
                disabled={popup || sendEmailCode || onSwitchEdit}
                empty
                reduced
                icon={Icons.EditIcon}
                onClick={() => editBtnHandleOnClick('birthdate')} />
            </div>}
            <div className="child m3 jc-left">
              <PropDisplay id='birthdate'
                className={'max-width'}
                header='Fecha de nacimiento'
                waiting={!user}
                property={Global.parseDateUTC(user?.getBirthdate(), timezoneOffset)} />
            </div>
          </div>
          {/* RFC */}
          <div className='flex-box'>
            {user && <div className="child m3 auto-width">
              <Button disabled={user?.getRFC() || popup || sendEmailCode || onSwitchEdit}
                rounded
                empty
                reduced
                icon={Icons.EditIcon}
                onClick={() => editBtnHandleOnClick('rfc')} />
            </div>}
            <div className="child m3 jc-left">
              <PropDisplay id='rfc'
                className={'max-width'}
                header='RFC'
                waiting={!user}
                property={user?.getRFC() || 'No registrado'} />
            </div>
          </div>
          {/* Bank card */}
          {!currSession.isSubuser && <div className='flex-box'>
            {bankCard !== undefined && <div className="child m3 auto-width">
              <Button disabled={popup || sendEmailCode || onSwitchEdit}
                rounded
                empty
                reduced
                icon={Icons.EditIcon}
                onClick={() => setPopup({ type: 'bank-card' })} />
            </div>}
            <div className="child m3 jc-left">
              <PropDisplay id='bank-card'
                className={'max-width'}
                header='Método de cobro'
                waiting={!bankCard}
                property={bankCard?.getCardNumber() || 'No registrado'} />
            </div>
            {bankCard?.getCardNumber() !== undefined && <div className="child m3 auto-width">
              <Button borderless
                disabled={popup || sendEmailCode || onSwitchEdit}
                empty
                icon={Icons.InfoIcon}
                onClick={aboutChargeMethodBtnHandleOnClick}
                reduced
                rounded
                title='Acerca del método de cobro' />
            </div>}
            {bankCard?.getCardNumber() !== undefined && <div className="child m3 auto-width">
              <Button borderless
                disabled={popup || sendEmailCode || onSwitchEdit}
                empty
                icon={Icons.DeleteIcon}
                onClick={deleteChargeMethodBtnHandleOnClick}
                reduced
                rounded
                typeRender='error' />
            </div>}
          </div>}
          {bankCard?.getExp() > today.current
            && today.current >= bankCard?.getExp() - Global.DAY_TO_MILLI * 30
            && <Hintbox icon={Icons.WarningIcon}
              message='Tu tarjeta expirará pronto.'
              type='warning' />}
          {bankCard?.getExp() < today.current && <Hintbox icon={Icons.CloseIcon}
            message={'Tu tarjeta ha expirado. Debes cambiarla para que tus arrendatarios '
              + 'sigan pagando sus contratos.'} type='error' />}
        </div>
      </div>
      {/* Statistics */}
      <div className="box borderless statistics">
        <h3 className="highlight">Estadísticas</h3>
        <NotFoundBox img={Icons.KevinNotFoundImg} message='Próximamente...' />
      </div>
      {/* Tools and Mail settings */}
      {currSession?.isSubuser === false && <div className="flex-box wrap jc-center">
        {/* Tools */}
        <div className="box borderless reduced tools">
          <h3 className="highlight">Herramientas</h3>
          {allowButtons === undefined && <LoadingBlock />}
          {allowButtons !== undefined && <div>
            <div className="flex-box m3">
              <div className="child auto-width">
                <Button disabled={!allowButtons.users || sendEmailCode || popup || onSwitchEdit}
                  empty
                  icon={Icons.PplCapIcon}
                  onClick={() => navigate(Global.PATH_USER_MANAGEMENT)}
                  value='Subcuentas' />
              </div>
              <div className="child">
                <Hintbox empty
                  icon={Icons.InfoIcon}
                  message='Una poderosa herramienta para gestionar subcuentas.' />
              </div>
            </div>
          </div>}
        </div>
        {/* Mail push settings */}
        <div className="box borderless reduced">
          <h3 className="highlight">Preferencias de correos push</h3>
          {/* General */}
          <div className="box shadowless borderless">
            <h4 className="highlight">General</h4>
            {/* Agreement creation */}
            <div className="flex-box m3 jc-left">
              <div className="child auto-width">
                <Button borderless
                  disabled={popup || onSwitchEdit}
                  empty
                  icon={Icons.InfoIcon}
                  onClick={() => setPopup({
                    type: 'dialog',
                    attached: {
                      message: mailConfig.getConfig(MailConfig.AGRE_CREA)
                        ? 'Te avisaremos cuando un contrato haya sido creado.'
                        : 'No te avisaremos cuando un contrato haya sido creado.'
                    }
                  })} reduced
                  rounded />
              </div>
              <div className="child jc-left">
                <Switch action={mailConfigSwitchActionHandler}
                  defaultValue={mailConfig?.getConfig(MailConfig.AGRE_CREA)}
                  disabled={!mailConfig || popup}
                  id={MailConfig.AGRE_CREA}
                  onActionReject={mailConfigSwitchPromiseRejectHandler}
                  placeholder={{ default: 'Contratos nuevos' }} />
              </div>
            </div>
            {/* Contact requests */}
            <div className="flex-box m3 jc-left">
              <div className="child auto-width">
                <Button borderless
                  disabled={popup || onSwitchEdit}
                  empty
                  icon={Icons.InfoIcon}
                  onClick={() => setPopup({
                    type: 'dialog',
                    attached: {
                      message: mailConfig.getConfig(MailConfig.CONT_REQU)
                        ? 'Te avisaremos cuando alguien esté interesado en una de tus propiedades.'
                        + ' El aviso solo llegará a tu correo personal.'
                        : 'No te avisaremos cuando alguien esté interesado en una de tus propiedades.'
                    }
                  })} reduced
                  rounded />
              </div>
              <div className="child jc-left">
                <Switch action={mailConfigSwitchActionHandler}
                  defaultValue={mailConfig?.getConfig(MailConfig.CONT_REQU)}
                  disabled={!mailConfig || popup}
                  id={MailConfig.CONT_REQU}
                  onActionReject={mailConfigSwitchPromiseRejectHandler}
                  placeholder={{ default: 'Solicitudes de contacto' }} />
              </div>
            </div>
            {/* Subscription payments */}
            <div className="flex-box m3 jc-left">
              <div className="child auto-width">
                <Button borderless
                  disabled={popup || onSwitchEdit}
                  empty
                  icon={Icons.InfoIcon}
                  onClick={() => setPopup({
                    type: 'dialog',
                    attached: {
                      message: mailConfig.getConfig(MailConfig.SUBS_PAYM)
                        ? 'Te avisaremos cuando tu suscripción se renueve exitosamente.'
                        : 'No te avisaremos cuando tu suscripción se renueve exitosamente.'
                    }
                  })} reduced
                  rounded />
              </div>
              <div className="child jc-left">
                <Switch action={mailConfigSwitchActionHandler}
                  defaultValue={mailConfig?.getConfig(MailConfig.SUBS_PAYM)}
                  disabled={!mailConfig || popup}
                  id={MailConfig.SUBS_PAYM}
                  onActionReject={mailConfigSwitchPromiseRejectHandler}
                  placeholder={{ default: 'Cobros de suscripción' }} />
              </div>
            </div>
          </div>
          {/* Lessee */}
          <div className="box shadowless borderless">
            <h4 className="highlight">Arrendatario</h4>
            {/* Agreement cancellation */}
            <div className="flex-box m3 jc-left">
              <div className="child auto-width">
                <Button borderless
                  disabled={!MailConfig || mailConfig === -1 || popup || onSwitchEdit}
                  empty
                  icon={Icons.InfoIcon}
                  onClick={() => setPopup({
                    type: 'dialog',
                    attached: {
                      message: mailConfig.getConfig(MailConfig.LE_AGRE_CANC)
                        ? 'Te avisaremos cuando un contrato en el que eres arrendatario sea cancelado.'
                        : 'No te avisaremos cuando un contrato en el que eres arrendatario sea cancelado.'
                    }
                  })} reduced
                  rounded />
              </div>
              <div className="child jc-left">
                <Switch action={mailConfigSwitchActionHandler}
                  defaultValue={mailConfig?.getConfig(MailConfig.LE_AGRE_CANC)}
                  disabled={!mailConfig || popup}
                  id={MailConfig.LE_AGRE_CANC}
                  onActionReject={mailConfigSwitchPromiseRejectHandler}
                  placeholder={{ default: 'Contratos cancelados' }} />
              </div>
            </div>
            {/* Agreement finalized */}
            <div className="flex-box m3 jc-left">
              <div className="child auto-width">
                <Button borderless
                  disabled={!mailConfig || mailConfig === -1 || popup || onSwitchEdit}
                  empty
                  icon={Icons.InfoIcon}
                  onClick={() => setPopup({
                    type: 'dialog',
                    attached: {
                      message: mailConfig.getConfig(MailConfig.LE_AGRE_FINA)
                        ? 'Te avisaremos cuando los contratos en los que eres arrendatario finalicen.'
                        : 'No te avisaremos cuando los contratos en los que eres arrendatario finalicen.'
                    }
                  })} reduced
                  rounded />
              </div>
              <div className="child jc-left">
                <Switch action={mailConfigSwitchActionHandler}
                  defaultValue={mailConfig?.getConfig(MailConfig.LE_AGRE_FINA)}
                  disabled={!mailConfig || popup}
                  id={MailConfig.LE_AGRE_FINA}
                  onActionReject={mailConfigSwitchPromiseRejectHandler}
                  placeholder={{ default: 'Fin de contratos' }} />
              </div>
            </div>
            {/* Agreement end or deadline nearby */}
            <div className="flex-box m3 jc-left">
              <div className="child auto-width">
                <Button borderless
                  disabled={!mailConfig || mailConfig === -1 || popup || onSwitchEdit}
                  empty
                  icon={Icons.InfoIcon}
                  onClick={() => setPopup({
                    type: 'dialog',
                    attached: {
                      message: mailConfig.getConfig(MailConfig.LE_AGRE_NEAR)
                        ? 'Te avisaremos cuando los contratos en los que eres arrendatario estén'
                        + ' por finalizar o se aproxime el siguiente corte.'
                        : 'No te avisaremos cuando los contratos en los que eres arrendatario estén'
                        + ' por finalizar o se aproxime el siguiente corte.'
                    }
                  })} reduced
                  rounded />
              </div>
              <div className="child jc-left">
                <Switch action={mailConfigSwitchActionHandler}
                  defaultValue={mailConfig?.getConfig(MailConfig.LE_AGRE_NEAR)}
                  disabled={!mailConfig || popup}
                  id={MailConfig.LE_AGRE_NEAR}
                  onActionReject={mailConfigSwitchPromiseRejectHandler}
                  placeholder={{ default: 'Fin y/o corte de contratos' }} />
              </div>
            </div>
            {/* Agreement end or deadline nearby */}
            <div className="flex-box m3 jc-left">
              <div className="child auto-width">
                <Button borderless
                  disabled={!mailConfig || mailConfig === -1 || popup || onSwitchEdit}
                  empty
                  icon={Icons.InfoIcon}
                  onClick={() => setPopup({
                    type: 'dialog',
                    attached: {
                      message: mailConfig.getConfig(MailConfig.LE_AGRE_DEAD)
                        ? 'Te avisaremos cuando los contratos en los que eres arrendatario estén'
                        + ' en la fecha de corte.'
                        : 'No te avisaremos cuando los contratos en los que eres arrendatario estén'
                        + ' en la fecha de corte.'
                    }
                  })} reduced
                  rounded />
              </div>
              <div className="child jc-left">
                <Switch action={mailConfigSwitchActionHandler}
                  defaultValue={mailConfig?.getConfig(MailConfig.LE_AGRE_DEAD)}
                  disabled={!mailConfig || popup}
                  id={MailConfig.LE_AGRE_DEAD}
                  onActionReject={mailConfigSwitchPromiseRejectHandler}
                  placeholder={{ default: 'Corte de contratos' }} />
              </div>
            </div>
            {/* Agreement payment */}
            <div className="flex-box m3 jc-left">
              <div className="child auto-width">
                <Button borderless
                  disabled={!mailConfig || mailConfig === -1 || popup || onSwitchEdit}
                  empty
                  icon={Icons.InfoIcon}
                  onClick={() => setPopup({
                    type: 'dialog',
                    attached: {
                      message: mailConfig.getConfig(MailConfig.LE_AGRE_PAYM)
                        ? 'Te avisaremos cuando hayas pagado alguno de tus arrendamientos'
                        : 'No te avisaremos cuando hayas pagado alguno de tus arrendamientos'
                    }
                  })} reduced
                  rounded />
              </div>
              <div className="child jc-left">
                <Switch action={mailConfigSwitchActionHandler}
                  defaultValue={mailConfig?.getConfig(MailConfig.LE_AGRE_PAYM)}
                  disabled={!mailConfig || popup}
                  id={MailConfig.LE_AGRE_PAYM}
                  onActionReject={mailConfigSwitchPromiseRejectHandler}
                  placeholder={{ default: 'Pagos de arrendamientos' }} />
              </div>
            </div>
            {/* Delayed agreement payment */}
            <div className="flex-box m3 jc-left">
              <div className="child auto-width">
                <Button borderless
                  disabled={!mailConfig || mailConfig === -1 || popup || onSwitchEdit}
                  empty
                  icon={Icons.InfoIcon}
                  onClick={() => setPopup({
                    type: 'dialog',
                    attached: {
                      message: mailConfig.getConfig(MailConfig.LE_AGRE_DELA)
                        ? 'Te avisaremos cuando te hayas atrasado con los pagos en algún contrato.'
                        : 'No te avisaremos cuando te hayas atrasado con los pagos en algún contrato.'
                    }
                  })} reduced
                  rounded />
              </div>
              <div className="child jc-left">
                <Switch action={mailConfigSwitchActionHandler}
                  defaultValue={mailConfig?.getConfig(MailConfig.LE_AGRE_DELA)}
                  disabled={!mailConfig || popup}
                  id={MailConfig.LE_AGRE_DELA}
                  onActionReject={mailConfigSwitchPromiseRejectHandler}
                  placeholder={{ default: 'Arrendamientos atrasados' }} />
              </div>
            </div>
          </div>
          {/* Lessor */}
          <div className="box shadowless borderless">
            <h4 className="highlight">Arrendador</h4>
            {/* Agreement cancellation */}
            <div className="flex-box m3 jc-left">
              <div className="child auto-width">
                <Button borderless
                  disabled={!MailConfig || mailConfig === -1 || popup || onSwitchEdit}
                  empty
                  icon={Icons.InfoIcon}
                  onClick={() => setPopup({
                    type: 'dialog',
                    attached: {
                      message: mailConfig.getConfig(MailConfig.LR_AGRE_CANC)
                        ? 'Te avisaremos cuando un contrato en el que eres el arrendador sea cancelado.'
                        : 'No te avisaremos cuando un contrato en el que eres el arrendador sea cancelado.'
                    }
                  })} reduced
                  rounded />
              </div>
              <div className="child jc-left">
                <Switch action={mailConfigSwitchActionHandler}
                  defaultValue={mailConfig?.getConfig(MailConfig.LR_AGRE_CANC)}
                  disabled={!mailConfig || popup}
                  id={MailConfig.LR_AGRE_CANC}
                  onActionReject={mailConfigSwitchPromiseRejectHandler}
                  placeholder={{ default: 'Contratos cancelados' }} />
              </div>
            </div>
            {/* Agreement finalized */}
            <div className="flex-box m3 jc-left">
              <div className="child auto-width">
                <Button borderless
                  disabled={!mailConfig || mailConfig === -1 || popup || onSwitchEdit}
                  empty
                  icon={Icons.InfoIcon}
                  onClick={() => setPopup({
                    type: 'dialog',
                    attached: {
                      message: mailConfig.getConfig(MailConfig.LR_AGRE_FINA)
                        ? 'Te avisaremos cuando los contratos en los que eres el arrendador finalicen.'
                        : 'No te avisaremos cuando los contratos en los que eres el arremdadpr finalicen.'
                    }
                  })} reduced
                  rounded />
              </div>
              <div className="child jc-left">
                <Switch action={mailConfigSwitchActionHandler}
                  defaultValue={mailConfig?.getConfig(MailConfig.LR_AGRE_FINA)}
                  disabled={!mailConfig || popup}
                  id={MailConfig.LR_AGRE_FINA}
                  onActionReject={mailConfigSwitchPromiseRejectHandler}
                  placeholder={{ default: 'Fin de contratos' }} />
              </div>
            </div>
            {/* Agreement near end */}
            <div className="flex-box m3 jc-left">
              <div className="child auto-width">
                <Button borderless
                  disabled={!mailConfig || mailConfig === -1 || popup || onSwitchEdit}
                  empty
                  icon={Icons.InfoIcon}
                  onClick={() => setPopup({
                    type: 'dialog',
                    attached: {
                      message: mailConfig.getConfig(MailConfig.LR_AGRE_NEAR)
                        ? 'Te avisaremos cuando los contratos en los que eres el arrendador estén'
                        + ' por finalizar.'
                        : 'No te avisaremos cuando los contratos en los que eres el arrendador estén'
                        + ' por finalizar.'
                    }
                  })} reduced
                  rounded />
              </div>
              <div className="child jc-left">
                <Switch action={mailConfigSwitchActionHandler}
                  defaultValue={mailConfig?.getConfig(MailConfig.LR_AGRE_NEAR)}
                  disabled={!mailConfig || popup}
                  id={MailConfig.LR_AGRE_NEAR}
                  onActionReject={mailConfigSwitchPromiseRejectHandler}
                  placeholder={{ default: 'Fin y/o corte de contratos' }} />
              </div>
            </div>
            {/* Agreement payment */}
            <div className="flex-box m3 jc-left">
              <div className="child auto-width">
                <Button borderless
                  disabled={!mailConfig || mailConfig === -1 || popup || onSwitchEdit}
                  empty
                  icon={Icons.InfoIcon}
                  onClick={() => setPopup({
                    type: 'dialog',
                    attached: {
                      message: mailConfig.getConfig(MailConfig.LR_AGRE_PAYM)
                        ? 'Te avisaremos cuando tus arrendadores pagen sus contratos.'
                        : 'No te avisaremos cuando tus arrendadores pagen sus contratos.'
                    }
                  })} reduced
                  rounded />
              </div>
              <div className="child jc-left">
                <Switch action={mailConfigSwitchActionHandler}
                  defaultValue={mailConfig?.getConfig(MailConfig.LR_AGRE_PAYM)}
                  disabled={!mailConfig || popup}
                  id={MailConfig.LR_AGRE_PAYM}
                  onActionReject={mailConfigSwitchPromiseRejectHandler}
                  placeholder={{ default: 'Pagos de arrendamientos' }} />
              </div>
            </div>
            {/* Delayed agreement payment */}
            <div className="flex-box m3 jc-left">
              <div className="child auto-width">
                <Button borderless
                  disabled={!mailConfig || mailConfig === -1 || popup || onSwitchEdit}
                  empty
                  icon={Icons.InfoIcon}
                  onClick={() => setPopup({
                    type: 'dialog',
                    attached: {
                      message: mailConfig.getConfig(MailConfig.LR_AGRE_DELA)
                        ? 'Te avisaremos cuando tus arrendatarios se atrasen con sus contratos.'
                        : 'No te avisaremos cuando tus arrendatarios se atrasen con sus contratos.'
                    }
                  })} reduced
                  rounded />
              </div>
              <div className="child jc-left">
                <Switch action={mailConfigSwitchActionHandler}
                  defaultValue={mailConfig?.getConfig(MailConfig.LR_AGRE_DELA)}
                  disabled={!mailConfig || popup}
                  id={MailConfig.LR_AGRE_DELA}
                  onActionReject={mailConfigSwitchPromiseRejectHandler}
                  placeholder={{ default: 'Arrendamientos atrasados' }} />
              </div>
            </div>
          </div>
          <Button empty
            fullWidth
            onClick={() => setPopup({
              type: 'dialog',
              attached: {
                message: 'Es posible que estés recibiendo nuestros correos en tu bandeja de'
                  + ' correo no deseado o spam. Márcalos como correo deseado, así los recibirás'
                  + ' en tu bandeja principal y ayudarás a que los servicios de email nos'
                  + ' reconozcan como una marca legítima. :3'
              }
            })}
            rounded
            value='¿No recibes nuestros correos?' />
        </div>
      </div>}
      {/* Popups */}
      {popup?.type === 'bank-card' && <BankCardEditor ignoreCardCVC
        onHide={() => setPopup()}
        onResolve={bC => {
          const aux = bC.getCardNumber();
          setBankCard(new BankCard({
            cardNumber: `${aux.substring(0, 4)} **** **** ${aux.substring(12, 16)}`,
            exp: bC.getExp()
          }));
        }} />}
      {popup?.type === 'dialog' && <Dialog action={popup.attached.action}
        confirmBtn={popup.attached.confirmBtn}
        id='charge-method-dialog'
        message={popup.attached.message}
        onHide={() => setPopup()}
        onReject={popup.attached.onReject}
        onResolve={popup.attached.onResolve}
        rejectBtn={popup.attached.rejectBtn}
        renderButtonsEmpty
        renderButtonsRounded
        renderButtonsSwitched={popup.attached.renderButtonsSwitched} />}
      {popup?.type === 'set-field' && <SetField action={popup.attached.action}
        hint={popup.attached.hint}
        onHide={() => setPopup()}
        onReject={popup.attached.onReject}
        onResolve={popup.attached.onResolve}
        options={popup.attached.options} />}
    </div>
  )
};

export default MyProfile;