import Generic from "./Generic";

/** MailconfigObject typedef
 * @typedef {Object} MailconfigObject
 * @property {number} idmailconfig The tuple's id.
 * @property {number} iduser The user tuple's id linked.
 * @property {boolean} agre_crea Agreement creation.
 * @property {boolean} le_agre_canc Lessee: agreement cancellation.
 * @property {boolean} le_agre_dead Lessee: Agreement deadline.
 * @property {boolean} le_agre_dela Lessee: Agreement delayed.
 * @property {boolean} le_agre_fina Lessee: Agreement finalized.
 * @property {boolean} le_agre_near Lessee: Agreement near deadline or near end.
 * @property {boolean} le_agre_paym Lessee: Agreement payment.
 * @property {boolean} lr_agre_canc Lessor: Agreement cancellation.
 * @property {boolean} lr_agre_dela Lessor: Agreement delayed.
 * @property {boolean} lr_agre_fina Lessor: Agreement finalized.
 * @property {boolean} lr_agre_near Lessor: Agreement near deadline or near end.
 * @property {boolean} lr_agre_paym Lessor: Agreement payment.
 * @property {boolean} cont_requ Contact request.
 * @property {boolean} subs_paym Subscription payment successfully.
 */

/** The Mail push configuration. */
class MailConfig extends Generic {
  /** An agreement has been signed. */
  static get AGRE_CREA() { return 'agre_crea' };
  /** Lessee: an agreement has been cancelled. */
  static get LE_AGRE_CANC() { return 'le_agre_canc' };
  /** Lessee: an agreement is in its deadline. */
  static get LE_AGRE_DEAD() { return 'le_agre_dead' };
  /** Lessee: an agreement payment is delayed. */
  static get LE_AGRE_DELA() { return 'le_agre_dela' };
  /** Lessee: an agreement finalized. */
  static get LE_AGRE_FINA() { return 'le_agre_fina' };
  /** Lessee: an agreement is near to its end or a deadline. */
  static get LE_AGRE_NEAR() { return 'le_agre_near' };
  /** Lessee: an agreement has been paid */
  static get LE_AGRE_PAYM() { return 'le_agre_paym' };
  /** Lessor: an agreement has been cancelled. */
  static get LR_AGRE_CANC() { return 'lr_agre_canc' };
  /** Lessor: an agreement payment is delayed. */
  static get LR_AGRE_DELA() { return 'lr_agre_dela' };
  /** Lessor: an agreement finalized. */
  static get LR_AGRE_FINA() { return 'lr_agre_fina' };
  /** Lessor: an agreement is near to its end. */
  static get LR_AGRE_NEAR() { return 'lr_agre_near' };
  /** Lessor: an agreement has been paid */
  static get LR_AGRE_PAYM() { return 'lr_agre_paym' };
  /** Someone requested contact info (lessor only). */
  static get CONT_REQU() { return 'cont_requ' };
  /** A subscription has been paid or paymend was rejected. */
  static get SUBS_PAYM() { return 'subs_paym' };

  /** @param {MailConfig&MailconfigObject} [object] */
  constructor(object) {
    super(object);

    /** @type {MailconfigObject} */
    this.config = {
      agre_crea: Boolean(object?.agre_crea || object?.config?.agre_crea),
      le_agre_canc: Boolean(object?.le_agre_canc || object?.config?.le_agre_canc),
      le_agre_dead: Boolean(object?.le_agre_dead || object?.config?.le_agre_dead),
      le_agre_dela: Boolean(object?.le_agre_dela || object?.config?.le_agre_dela),
      le_agre_fina: Boolean(object?.le_agre_fina || object?.config?.le_agre_fina),
      le_agre_near: Boolean(object?.le_agre_near || object?.config?.le_agre_near),
      le_agre_paym: Boolean(object?.le_agre_paym || object?.config?.le_agre_paym),
      lr_agre_canc: Boolean(object?.lr_agre_canc || object?.config?.lr_agre_canc),
      lr_agre_dela: Boolean(object?.lr_agre_dela || object?.config?.lr_agre_dela),
      lr_agre_fina: Boolean(object?.lr_agre_fina || object?.config?.lr_agre_fina),
      lr_agre_near: Boolean(object?.lr_agre_near || object?.config?.lr_agre_near),
      lr_agre_paym: Boolean(object?.lr_agre_paym || object?.config?.lr_agre_paym),
      cont_requ: Boolean(object?.cont_requ || object?.config?.cont_requ),
      subs_paym: Boolean(object?.subs_paym || object?.config?.subs_paym)
    }

    Object.freeze();
  }

  /** Obtains all configs */
  getAllConfigs() {
    return this.config;
  }

  /** Obtains the specified mail configuration section.
   * @param {string} attr Config name (defined by class globals)
   * @returns {boolean}
   */
  getConfig(attr) {
    return this.config[`${attr}`];
  }

  /** Assings new value to the specified config.
   * @param {string} attr Config name (defined by class globals) 
   * @param {boolean} val New value for the config.
   */
  setConfig(attr, val) {
    if (this.config[`${attr}`] !== undefined)
      this.config[`${attr}`] = val;
  }
}

export default MailConfig;