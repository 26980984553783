import Address from "./Address";
import Bidding from "./Bidding";
import Contract from "./Contract";
import ErrHandler from "./ErrHandler";
import Estate from "./Estate";
import GenericFile from "./GenericFile";
import Global from "./Global";
import Pass from "./Pass";
import Persona from "./Persona";
import Rate from "./Rate";
import Subscription from "./Subscription";
import UIRender from "./UIRender";
import User from "./User";

/** FileRequestObject typedef
 * @typedef {Object} FileRequestObject
 * @property {string} name The file name to be fetched.
 * @property {(err?: Promise<never>, file?: Blob) => void} callback A function that will be
 * fired when file resolves or rejects. If resolves, err will be undefined. Otherwise err
 * will be Promise<never>
 */

/** The server DAO object. Use it to communicate with the server and fetch / send data. */
class DAOServ {
  static get PROTO() { return Global.DEV_MODE ? 'http' : 'https' };
  static get HOST() { return window.location.hostname }
  static get PORT() { return '8000' }
  static get CONTENT_PLAIN() { return 'application/x-www-form-urlencoded' }
  static get CONTENT_MULTI() { return 'multipart/form-data' }
  static get CONTENT_JSON() { return 'application/json' }
  /** @type {FileRequestObject[]} */
  static fileRequest = [];
  static sessionOpen = false;

  /** Makes a GET request to the server.
   * @param {string} location The server location.
   * @param {string[]} [parameters] The params.
   * @param {boolean} [returnResponse] If true, data returned will be the Response object from
   * fetch function.
   */
  static async get(location, parameters, returnResponse) {
    if (!location) {
      return Promise.reject('Location is undefined');
    }

    const param = (parameters?.length > 0 && `/${parameters.join('/')}`) || '';
    const port = Global.DEV_MODE ? `:${DAOServ.PORT}` : '';
    const url = `${DAOServ.PROTO}://${DAOServ.HOST}${port}/${location}${param}`;

    const request = await fetch(url);

    if (returnResponse) return request;

    const data = await request.text();

    if (request.status >= 400) { // An error occurred.
      if (!ErrHandler.isGenericError(data)) // Error is unknown.
        return Promise.reject(ErrHandler.parseUnkownResponse(request));
      else {
        if (ErrHandler.getCode(data) === ErrHandler.CODES.TST_FAIL && DAOServ.sessionOpen)
          UIRender.reloadPage();

        return Promise.reject(data);
      }
    } else {
      try {
        return Promise.resolve(JSON.parse(data));
      } catch (err) {
        return Promise.resolve(data);
      }
    }
  }

  /** Obtains the current day in milliseconds (UTC).
   * @param {boolean} [withTime] If true, obtains current time in milliseconds.
   * @returns {Promise<number>}
   */
  static async getCurrentDay(withTime) {
    return await DAOServ.get(withTime ? 'today_with_time' : 'today')
      .then(data => Promise.resolve(Number(data.today)))
      .catch(err => Promise.reject(err));
  }

  /** Obtains the Mexico City timezone offset in milliseconds.
   * @returns {Promise<number>}
   */
  static async getTimezoneOffset() {
    return await DAOServ.get('timezone_offset')
      .then(data => Promise.resolve(data.offset))
      .catch(err => Promise.reject(err));
  }

  /** Makes a GET request to the server to retrieve a file
   * @param {string} fileName The file name or pathname.
   * @param {Object} [options] The object options. Can be undefined if requested file is located
   * at PUBLIC.
   * @param {'PRIVATE'|'PUBLIC'|'TEMP'} options.location The location. If PRIVATE or TEMP, tst is
   * required.
   * @param {string} [options.tst] The current session's temporal session token. Not needed if
   * options.location is set to PUBLIC.
   */
  static async getFile(fileName, options) {
    const flag = typeof options !== 'object' || options.location === 'PUBLIC';
    const port = Global.DEV_MODE ? `:${DAOServ.PORT}` : '';
    const url = flag
      ? `${DAOServ.PROTO}://${DAOServ.HOST}${port}/static/${fileName}`
      : (options.location === 'PRIVATE' ? 'get_private_file' : 'get_temporal_file');

    try {
      /** @type {Response} */
      const file = flag
        ? await fetch(url)
        : await DAOServ.post(url, { tst: options.tst, fileName }, 'JSON', true);

      if (!file.ok)
        return Promise.reject(ErrHandler.getError(ErrHandler.CODES.NOT_FOUND, 'requested file not found'));
      else
        return await file.blob();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /** Makes a GET request to the server to retrieve a file. Every request will be set on a queue
   * and, when the request is resolved or rejected, will fire the given callback.
   * @param {string} name The file name or pathname.
   * @param {(err?: Promise<never>, file?:Blob)=>void} callback A function
   * that will be fired when promise is resolved or rejected. If resolved, err will be
   * undefined and file will contain a blob object. If rejected, err will be a reject
   * and file will be undefined.
   */
  static async getFileCallback(name, callback) {
    if (!DAOServ.fileRequest.length) { // First request of the queue.
      DAOServ.fileRequest.push({ name, callback });

      do {
        const { name, callback } = DAOServ.fileRequest[0];

        try {
          const blob = await DAOServ.getFile(name);

          callback && callback(undefined, blob);
        } catch (err) {
          callback && callback(err);
        } finally {
          DAOServ.fileRequest.splice(0, 1);
        }
      } while (DAOServ.fileRequest.length)
    } else { // Resolving a promise.
      DAOServ.fileRequest.push({ name, callback });
    }
  }

  /** Obtains an estate's info from server. If fetched Estate is a subdivision from another, its complex
   * attribute will be defined and will contain only its id.
   * @param {string} tst Current active TST.
   * @param {number} idEstate The estate's id to fetch.
   */
  static async fetchEstate(tst, idEstate) {
    const auxEst = new Estate(); // Auxiliar instance.

    if (idEstate === undefined)
      return Promise.reject(ErrHandler.getError(ErrHandler.CODES.PARAM_MISSING));
    else if (isNaN(idEstate) || Number(idEstate) <= 0)
      return Promise.reject(ErrHandler.getError(ErrHandler.CODES.PARAM_INVALID));

    let dir = 'get_publishment_full'; // Server location.
    let query = await DAOServ.post(dir, { tst, idEstate }, 'JSON'); // Fetch data.
    const address = new Address(); // Auxiliar instance.

    // ** Complex **
    if (Boolean(query['idcomplex']))
      auxEst.setComplex(new Estate({ id: Number(query['idcomplex']) }));

    // ** Casting address **
    address.setGeoData({
      lat: Number(query['g_location']['lat']),
      lng: Number(query['g_location']['lng'])
    });

    // ** Casting fetched data **
    auxEst.setBuildDate(Number(query['estate_build_date']));
    auxEst.setCreationDate(Number(query['estate_creation_date']));
    auxEst.setDescription(query['description']);
    auxEst.setId(Number(query['idestate']));
    auxEst.setImages(query['images'].map(imgName => new GenericFile({ name: imgName })));
    auxEst.setInsurance(query['insurance']);
    auxEst.setLocation(address);
    auxEst.setRequirements(query['requirements']?.split(Global.ARR_SEPARATOR));
    auxEst.setSize(query['size']);
    auxEst.setStatus(Number(query['estate_status']));
    auxEst.setTitle(query['title']);
    auxEst.setTotalSpaces(query['total_spaces']);
    auxEst.setType(Number(query['e_type']));
    auxEst.setOwner(new User({ id: query['idowner'], username: query['owner_username'] }));
    auxEst.setFreeSpaces(query['free_spaces']);
    auxEst.setSellMethod(Number(query['sell_method']));

    if (Number(query['estate_rates_count'] > 0)) { // Estate has rate.
      auxEst.setRate(new Rate({
        count: Number(query['estate_rates_count']),
        value: Number(query['estate_global_rate'])
      }));
    }

    if (Number(query['owner_rates_count']) > 0) { // Owner has rate.
      auxEst.getOwner().setRate(new Rate({
        count: Number(query['owner_rates_count']),
        value: Number(query['owner_global_rate'])
      }));
    }

    if (query['idbidding']) { // Estate is in bidding.
      const bidding = new Bidding();
      // Casting bidding.
      bidding.setEndDate(Number(query['bidding_end_date']));
      bidding.setId(Number(query['idbidding']));
      bidding.setMinimumBid(Number(query['min_bid']));
      bidding.setManualStatus(Number(query['bidding_status']));
      bidding.setStatus(Number(query['bidding_current_status']));
      auxEst.setBidding(bidding);
    }

    if (query['idcontract']) { // Estate has base contract.
      const contract = new Contract();

      contract.setCharge(Number(query['charge_at_start']));
      contract.setId(Number(query['idcontract']));
      contract.setInclServs(query['incl_serv']?.split(Global.ARR_SEPARATOR));
      contract.setPayAmount(Number(query['pay_amount']));
      contract.setPayFrequency(Number(query['pay_frequency']));
      contract.setTermMethod(Number(query['term_method']));
      contract.setTerm(Number(query['term']));

      auxEst.setContract(contract);
    }

    if (query['idpscollection']) { // Estate has PSCollection.
      // Getting PSCollection keys.
      const keys = Object.keys(query).filter((key => /^ps_/.test(key)));
      // Adding properties and services.
      for (let i = 0; i < keys.length; i++) {
        const val = query[keys[i]];

        if (val !== null) {
          if (keys[i].includes('_p_'))  // Property
            auxEst.addProperty(keys[i].replace('ps_', ''), val)
          else // Service.
            auxEst.addService(keys[i].replace('ps_', ''), val);
        }
      }
    }

    // Request reverse geolocation.
    dir = 'find_address';
    query = await DAOServ.post(dir, { geoData: address.getGeoData() }, 'JSON');
    const addCom = query['address_components'];
    let inc = addCom.length > 6 ? 3 : 2;

    // Casting fetched address.
    address.setStreet(`${addCom[1]['long_name']} ${addCom[0]['long_name']}`);
    address.setSuburb(addCom[2]['long_name']);
    address.setCity(addCom.length === 6 ? '_' : addCom[inc]['long_name']);
    inc++;
    address.setState(addCom[inc]['long_name']);
    inc++;
    address.setCountry(addCom[inc]['long_name']);

    return Promise.resolve(auxEst);
  }

  /** Obtains a user's contact info.
   * @param {string} tst The current session token.
   * @param {string} [username] The id from the desired user to parse its contact info. If undefined, the
   * returned contact info will be related to given TST. If not, given username must be related to given
   * TST (subuser or a co-user) to get a response.
   * @param {boolean} [superuser] If true, username will be ignored and will try to get superuser contact
   * info (only if given TST is a subuser, otherwise will get current session info).
   */
  static async fetchUserContactInfo(tst, username) {
    const payload = { tst, username };
    const query = await DAOServ.post('get_user_contactinfo', payload, 'JSON');
    const auxCInf = new Persona();
    const auxPhone = query['phone'].split('_');

    auxCInf.setEmail(query['email']);
    auxCInf.setFirstName(query['name']);
    auxCInf.setPhone({ code: auxPhone[0], number: auxPhone[2], prefix: auxPhone[1] });

    return auxCInf;
  }

  /** Obtains the license statistics of current user through post method.
   * @param {string} tst Current TST.
   * @returns {Promise<import('./License').LicenseFeaturesObject>} 
   */
  static fetchLicenseStatistics(tst) {
    return DAOServ.post('get_license_statistics', { tst }, 'JSON');
  }

  /** Obtains the permissions of a user through post method.
   * @param {string} tst Current TST.
   */
  static async fetchPass(tst) {
    return new Pass(await DAOServ.post('get_user_pass', { tst }, 'JSON'));
  }

  /** Obtains the current subscription of a user.
   * @param {string} tst Current TST. 
   * @param {boolean} fullInfo If false, fetched data will be only id and license info (title and class),  
   */
  static async fetchSubscription(tst, fullInfo) {
    const data = await DAOServ.post('get_user_subscription', { tst, fullInfo }, 'JSON');

    const auxSub = new Subscription();
    const keys = Object.keys(data).filter(key => key === 'bidding' || key.includes('max_'));
    const subLic = auxSub.getLicense();
    // Subscription init.
    auxSub.setCanBeCancelled(data['can_be_cancelled']);
    auxSub.setCreationDate(Number(data['start_date']));
    auxSub.setDiscount(Number(data['sub_discount']));
    auxSub.setId(data['idsubscription']);
    auxSub.setMethod(data['method']);
    auxSub.setNextPayment(Number(data['next_payment']) || undefined);
    auxSub.setPrice(Number(data['sub_price']) || undefined);
    auxSub.setStatus(data['active']);
    // License init.
    subLic.setClassName(data['class_name']);
    subLic.setDescription(data['description']);
    subLic.setDiscount(Number(data['lic_discount']));
    subLic.setId(data['idlicense']);
    subLic.setPrice(Number(data['lic_price']));
    subLic.setTitle(data['title']);
    keys.forEach(key => subLic.getFeatures()[key] = data[key]);

    return Promise.resolve(auxSub);
  }

  /** Makes a POST request to the server. The returned value can be an string (text) or an object
   * (JSON.parse)
   * @param {string} location Server location.
   * @param {*} body Payload to send to the server. It can be a FormData, an
   * object or a string.
   * @param {'JSON'|'MULTI'|'PLAIN'} [contentType] The content type to send. Use MULTI to send
   * files (body must be a FormData instance for this to work). PLAIN is the default. This will
   * be ignored if body is a FormData object instance.
   * @param {boolean} [returnResponse] If true, data returned will be the Response object from
   * fetch function.
   */
  static async post(location, body, contentType, returnResponse) {
    if (!location)
      return Promise.reject(ErrHandler.getError(ErrHandler.CODES.PARAM_MISSING), 'Location is undefined');

    const port = Global.DEV_MODE ? `:${DAOServ.PORT}` : '';
    const url = `${DAOServ.PROTO}://${DAOServ.HOST}${port}/${location}`;
    let type, content;

    switch (contentType?.toUpperCase()) {
      case 'JSON': {
        type = DAOServ.CONTENT_JSON;
        break;
      } case 'MULTI': {
        type = DAOServ.CONTENT_MULTI;
        break;
      } default: type = DAOServ.CONTENT_PLAIN;
    }

    if (typeof body === 'object' && !(body instanceof FormData))
      content = JSON.stringify(body);
    else
      content = body;

    const init = {
      body: content,
      method: 'POST',
      headers: contentType !== 'MULTI' && !(body instanceof FormData)
        ? { 'Content-Type': type }
        : undefined
    }

    const request = await fetch(url, init);

    if (returnResponse) return request;

    const data = await request.text();

    if (request.status >= 400) { // An error occurred.
      if (!ErrHandler.isGenericError(data)) // Error is unknown.
        return Promise.reject(ErrHandler.parseUnkownResponse(request));
      else {
        if (ErrHandler.getCode(data) === ErrHandler.CODES.TST_FAIL && DAOServ.sessionOpen)
          UIRender.reloadPage();

        return Promise.reject(data);
      }
    } else {
      try {
        return Promise.resolve(JSON.parse(data));
      } catch (err) {
        return Promise.resolve(data);
      }
    }
  }

  /** Updates the session status. Must be called when page is being
   * loaded for the first time. When a query sends a TST_FAIL error and
   * this attribute is true, page will be reloaded to force the user to login again.
   * @param {boolean} session 
   */
  static setSessionOpen(session) {
    DAOServ.sessionOpen = Boolean(session);
  }
}

export default DAOServ;