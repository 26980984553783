/** The GenericFile definition for general purposes */
class GenericFile {
  /** @param {GenericFile} [object] A GenericFile instance to be copied */
  constructor(object) {
    const { name, pathname, size, urlData } = object ?? {};

    this.setName(name);
    this.setPathname(pathname);
    this.setSize(size);
    this.setURLData(urlData);
  }

  /** Obtains the Data URL stream of the file, ready to be displayed in an img element. */
  getURLData() {
    return this.urlData;
  }

  /** Assigns a Data URL stream to the file. (must be on urlData format).
   * @param {string} urlData 
   */
  setURLData(urlData) {
    this.urlData = urlData;
  }

  /** Obtains the file name */
  getName() {
    return this.name;
  }

  /** Assigns a name for the file.
   * @param {string} name 
   */
  setName(name) {
    this.name = name || undefined;
  }

  /** Obtains the pathname of the file */
  getPathname() {
    return this.pathname;
  }

  /** Assings a pathname of the file */
  setPathname(pathname) {
    this.pathname = pathname;
  }

  /** Obtains the size (in bytes) of the file.
   */
  getSize() {
    return this.size;
  }

  /** Assingns a size (in bytes) for the file. 
   * @param {number} size
   */
  setSize(size) {
    this.size = size === null || isNaN(size)
      ? undefined : Number(size);
  }

  /** Obtains the file type.
   */
  getType() {
    const start = this.urlData?.indexOf(':');
    const end = this.urlData?.indexOf(';');
    return this.urlData?.substring(start + 1, end);
  }

  /** Parses the generic file to a file. */
  toFile() {
    if (!this.urlData) {
      return undefined;
    }

    const arr = this.urlData.split(',');
    const mime = 0;
    const data = 1;

    arr[mime] = arr[mime].match(/:(.*?);/)[1];

    const dec64Data = atob(arr[data]);
    let n = dec64Data.length;
    const bit8Data = new Uint8Array(n);

    while (n--) {
      bit8Data[n] = dec64Data.charCodeAt(n);
    }

    return new File([bit8Data], this.name, { type: arr[mime] });
  }
}

export default GenericFile;