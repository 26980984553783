import './styles/user-management.css';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { globalContext } from './context/GlobalContext';
import Button from './components/Button';
import CreateSubUser from './popups/CreateSubuser';
import DAOServ from './objects/DAOServ';
import Dialog from './components/popups/Dialog';
import ErrHandler from './objects/ErrHandler';
import GenericFile from './objects/GenericFile';
import Global from './objects/Global';
import Inputbar from './components/Inputbar';
import LoadingBlock from './components/LoadingBlock';
import Pass from './objects/Pass';
import PassToolWizard from './popups/PassToolWizard';
import Persona from './objects/Persona';
import Selectbar from './components/Selectbar';
import SetField from './components/popups/SetField';
import Switch from './components/Switch';
import UIRender from './objects/UIRender';
import User from './objects/User';
import UserCard from './components/UserCard';
import * as Icons from './assets/images';

/** InputCompoObject Typedef
 * @typedef {Object} InputCompoObject
 * @property {string} id Compo identifier.
 * @property {React.MutableRefObject<HTMLInputElement|HTMLSelectElement>} compo The compo.
 */

/** EditFieldObject Typedef
 * @typedef {Object} EditFieldObject
 * @property {'birthdate'|'email'|'genre'|'first-name'|'last-name'|'phone'|'rfc'} id The
 * id of the field being modified.
 * @property {string} [newValue] The new value of the field.
 * @property {string} [oldValue] The old value of the field.
 */

/** Rollback Function Typedef
 * @typedef {(i?: *) => void} RollbackFunction
 */

/** Renders a UserManagement compo. */
const UserManagement = () => {
  // *** useContext ***
  const {
    currSession,
    pushMessageHint,
    setShowLoadingScreen,
    setSearchMethod,
    timezoneOffset
  } = useContext(globalContext);
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useRef ***
  const inputRollback = useRef(/** @type {RollbackFunction} */(undefined)); // Input rollback function.
  const prefixRollback = useRef(/** @type {RollbackFunction} */(undefined)); // Prefix rollback function.
  const maxUsers = useRef(/** @type {number} */(undefined));
  const selectorRef = useRef();
  const refCompos = useRef(/** @type {InputCompoObject[]} */([
    { compo: useRef(), id: 'first-name' },
    { compo: useRef(), id: 'last-name' },
    { compo: useRef(), id: 'birthdate' },
    { compo: useRef(), id: 'genre' },
    { compo: useRef(), id: 'rfc' },
    { compo: useRef(), id: 'email' },
    { compo: useRef(), id: 'phone' }
  ]));
  // *** useState ***
  const [currUser, setCurrUser] = useState(/** @type {User} */(undefined));
  const [editField, setEditField] = useState(/** @type {EditFieldObject&string} */(undefined));
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [prefixes, setPrefixes] = useState(/** @type {import('./Signup').PrefixObject[]} */(undefined));
  const [showPopup, setShowPopup] = useState(/** @type {'create'|'delete'|'passcode'|'wizard'} */(undefined));
  const [toggleSelector, setToggleSelector] = useState(/** @type {boolean} */);
  const [users, setUsers] = useState(/** @type {User[]} */(undefined));

  const cancelEditBtnHandleOnClick = () => {
    inputRollback.current();
    setEditField(undefined);
  }

  const createUserBtnHandleOnClick = async () => {
    setShowLoadingScreen(true);

    try {
      // Getting user's license statistics.
      const licSta = await DAOServ.fetchLicenseStatistics(currSession.tst);

      if (licSta.is_cancelled)
        throw new Error('Tu suscripción está en solicitud de cancelación. No puedes crear usuarios');

      setShowPopup('create');
    } catch (err) {
      const code = ErrHandler.getCode(err);
      const message = code !== undefined
        ? ErrHandler.parseError(err)
        : err instanceof Error ? err.message : err;

      pushMessageHint({ message, type: 'error' });
    } finally {
      setShowLoadingScreen(false);
    }
  }

  const deleteUserHandleOnResolve = () => {
    pushMessageHint({
      message: `El usuario '${currUser.getUsername()}' fue eliminado`,
      type: 'complete'
    });

    users.splice(users.findIndex(user => user === currUser), 1);
    setCurrUser();
    setUsers([...users]);
  }
  /** Edit / Save Field btn click handler.
   * @param {string} id The id stored (or that will be stored) in the editField object. 
   * @param {string} [currValue] Current input value (required for edit).
   */
  const editFieldBtnHandleOnClick = async (id, currValue) => {
    setEditField({ id, newValue: currValue, oldValue: currValue });

    if (toggleSelector) setToggleSelector(false);
  }

  /** Save field btn click handler
   * @param {(val: *) => void} callbackReplacer For save to database. Assign the User
   * set method to replace a determined attribute value. If database update is
   * successful, this callback function will be activated with the new input value.
   */
  const saveFieldBtnHandleOnClick = async callbackReplacer => {
    if (!editField) return;

    const payload = {
      column: editField.id.replace('-', '_'),
      data: editField.id === 'birthdate'
        ? Global.dateToMilliUTC(Global.transformDate(editField.newValue), -timezoneOffset)
        : editField.newValue,
      iduser: currUser.getId(),
      tst: currSession.tst,
    }

    setShowLoadingScreen(true);

    await DAOServ.post('update_user', payload, 'JSON')
      .then(() => {
        callbackReplacer(editField.newValue);
        setEditField();
        pushMessageHint({ message: 'Campo actualizado', type: 'complete' });
      }).catch(err => pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }));

    setShowLoadingScreen(false);
  }

  const getSelectorClass = () => {
    switch (toggleSelector) {
      case true: return 'show';
      case false: return 'hide';
      default: return '';
    }
  }

  /** @param {string} [input] */
  const inputHandleOnChange = input => {
    editField.newValue = input;
    setDisableSubmit(!input || input === editField.oldValue);
  }

  /** Phone input change handler.
   * @param {string} input The input.
   * @param {'number'|'prefix'} type The phone section that's being changed.
   */
  const phoneHandleOnChange = (input, type) => {
    if (!editField.newValue) {
      editField.newValue = input;
    } else {
      const phoneSplit = editField.newValue.split('_');

      if (type === 'number') { // Number edit.
        if (!input) editField.newValue = [phoneSplit[0], phoneSplit[1]].join('_'); // Only setting prefix.
        else editField.newValue = [phoneSplit[0], phoneSplit[1], input].join('_'); // Setting number too.
      } else { // Prefix edit.
        if (!input) editField.newValue = phoneSplit[2]; // Only setting number.
        else editField.newValue = [input, phoneSplit[2]].join('_'); // Setting prefix too.
      }

      setDisableSubmit(input && phoneSplit.length > 2);
    }
  }

  /** Pass switch change handler
   * @param {boolean} newState The current switch state.
   * @param {string} pass The pass argument id.
   */
  const passHandleOnChange = async (newState, pass) => {
    let res;
    const payload = {
      tst: currSession.tst,
      users: [currUser.getId()], pass: currUser.getPass().getAllPasses()
    };
    currUser.getPass().setPass(pass, newState);

    setEditField(`switch-${pass}`);

    await DAOServ.post('pass_tool_wizard', payload, 'JSON')
      .then(() => {
        const getPassMessage = () => {
          switch (pass) {
            case Pass.DE_BIDD: return "'Finalizar subastas'";
            case Pass.DE_CONT: return "'Cancelar contratos'";
            case Pass.DE_ESTA: return "'Eliminar propiedades'";
            case Pass.IN_BIDD: return "'Iniciar subastas'";
            case Pass.IN_CONT: return "'Crear contratos'";
            case Pass.IN_ESTA: return "'Crear propiedades'";
            case Pass.UP_ESTA: return "'Act. propiedades'";
            case Pass.UP_PICT: return "'Act. foto de perfil'";
            case Pass.UP_SINF: return "'Act. info. personal'";
            default: return 'Permiso';
          }
        }

        res = Promise.resolve();
        pushMessageHint({
          message: `'${getPassMessage()}' ${newState ? ' concedido' : ' denegado'}`,
          type: 'complete'
        });
      }).catch(err => {
        res = Promise.reject();
        currUser.getPass().setPass(pass, !newState); // Restore user pass field.
        pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
      }).finally(() => setEditField());

    return res;
  }

  const renderUsersLimit = () => {
    if (!users) return '...';
    else return `${users.length}/${maxUsers.current}`;
  }

  const renderUserList = () => {
    if (!users) return;

    const auxArr = [];

    for (let i = 0; i < users.length; i++) {
      auxArr.push(<UserCard fullWidth
        hover={users[i] !== currUser}
        user={users[i]}
        key={users[i].getUsername()}
        type={users[i] === currUser ? 'glass' : ''}
      />);
    }

    return auxArr;
  }

  /** @type {React.AnimationEventHandler<HTMLDivElement>} */
  const selectorHandleOnAnimationEnd = e => {
    if (e?.target === selectorRef.current && toggleSelector === false) {
      setToggleSelector();
    }
  }

  /** @type {React.MouseEventHandler<HTMLDivElement>} */
  const selectorHandleOnClick = e => {
    if (editField) return;

    const userCard = Global.findParent(e.target, { className: 'user-card', maxChecks: 3 });

    if (userCard) {
      const index = Array.from(userCard.parentNode.children).findIndex(f => f === userCard);

      if (index !== -1) {
        setCurrUser(users[index]);
        setToggleSelector(false);
      }
    }
  }

  /** Account status change handler.
   * @param {boolean} newState
   * @param {() => void} rollback
   */
  const statusHandleOnChange = async newState => {
    let res;
    const payload = {
      tst: currSession.tst,
      users: [currUser.getId()],
      status: newState ? User.STATUS_ACT : User.STATUS_INA
    };

    setEditField('switch-status');

    await DAOServ.post('pass_tool_wizard', payload, 'JSON')
      .then(() => {
        currUser.setStatus(newState ? User.STATUS_ACT : User.STATUS_INA);
        pushMessageHint({
          message: `La cuenta fue ${newState ? 'activada' : 'desactivada'}`,
          type: 'complete'
        });

        res = Promise.resolve();
      }).catch(err => {
        res = Promise.reject(err);
        pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' })
      }).finally(setEditField());

    return res;
  }

  /** @param {import('./components/popups/SetField').SetFieldActionObject} pkg */
  const updatePassword = pkg => {
    const body = {
      tst: currSession.tst,
      idUser: currUser.getId(),
      passcode: pkg.newValue
    }

    return DAOServ.post('update_subuser_passcode', body, 'JSON');
  }

  useEffect(() => { UIRender.scrollTo() }, [currUser]);

  useEffect(() => {
    if (!editField) {
      inputRollback.current = undefined;
      UIRender.clearTextSelection();
    } else if (typeof editField === 'object') {
      const compo = refCompos.current.find(e => e.id === editField.id).compo.current
      UIRender.setFocus(compo);

      if (editField.id !== 'birthdate' && editField.id !== 'genre')
        UIRender.selectElementText(compo);
    }

    setDisableSubmit(true);
  }, [editField]);

  useEffect(() => {
    const fetchLicenseStatistics = async () => {
      const licStat = await DAOServ.fetchLicenseStatistics(currSession.tst)

      if (licStat.max_sub_acc <= 0) {
        return Promise.reject(ErrHandler.getError(ErrHandler.CODES.ACCESS_DENIED));
      } else {
        maxUsers.current = licStat.max_sub_acc;
        return Promise.resolve();
      }
    }

    const fetchPrefixes = async () => {
      const rawData = await fetch(Global.FETCH_PREFIXES);
      const data = await rawData.json();
      const auxArr = [];
      const prefArr = Array.from(data);

      for (let i = 0; i < prefArr.length; i++) {
        auxArr.push({
          code: prefArr[i]['code'],
          dial_code: prefArr[i]['dial_code'],
          name: prefArr[i]['name']
        });
      }

      setPrefixes(auxArr);
      return Promise.resolve();
    }

    const fetchUsers = async () => {
      const auxUsrArr = [];

      const data = Array.from(await DAOServ.post('get_subusers', { tst: currSession.tst }, 'JSON'));

      for (const row of data) {
        const auxUser = new User();
        // User info.
        const auxPhone = row['phone'].split('_');
        auxUser.setId(row['iduser']);
        auxUser.setBirthdate(row['birthdate']);
        auxUser.setCreationDate(row['creation_date']);
        auxUser.setEmail(row['email']);
        auxUser.setFirstName(row['first_name']);
        auxUser.setGenre(row['genre']);
        auxUser.setLastName(row['last_name']);
        auxUser.setLastSession(row['last_session']);
        auxUser.setPhone({ code: auxPhone[0], number: auxPhone[2], prefix: auxPhone[1] });
        auxUser.setPicture(new GenericFile({ name: row['picture'] }));
        auxUser.setRFC(row['rfc']);
        auxUser.setStatus(row['status']);
        auxUser.setUsername(row['username']);
        // Pass info.
        auxUser.getPass().setId(row['idpass']);
        const passKeys = Object.keys(row).filter(e => /^(in|de|up)_.*/.test(e));

        for (let j = 0; j < passKeys.length; j++)
          auxUser.getPass().setPass(passKeys[j], row[passKeys[j]]);

        auxUsrArr.push(auxUser);
      };

      setUsers(auxUsrArr);
    }

    const start = async () => {
      try {
        await fetchLicenseStatistics();
        await fetchPrefixes();
        await fetchUsers();
      } catch (err) {
        pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
        navigate(-1);
      }
    }

    if (currSession.sessionStatus !== undefined) {
      if (currSession.sessionStatus === User.SESSION_ACT) {
        UIRender.scrollTo();
        setSearchMethod(false);
        start();
      } else {
        navigate('/', { replace: true });
      }
    }
  }, [currSession, navigate, pushMessageHint, setSearchMethod]);

  return (
    <div className="container user-management">
      {/* Selector opener */}
      {!editField && <div className={`selector-opener ${getSelectorClass()}`}>
        <Button borderless
          empty
          icon={Icons.ArrowHeadIcon}
          onClick={() => setToggleSelector(toggleSelector === undefined ? true : false)}
          rotation={toggleSelector ? 90 : 270}
          typeRender='white-stone' />
      </div>}
      {/* Selector */}
      <div className={`user-selector-container ${getSelectorClass()}`}
        onAnimationEnd={selectorHandleOnAnimationEnd}
        ref={selectorRef}>
        <div className="user-selector">
          <div className="flex-box m3">
            <div className="child jc-left m5">
              <h3 className='highlight'>Usuarios ({renderUsersLimit()})</h3>
            </div>
            <div className="child auto-width">
              <Button animated
                disabled={users === undefined || users.length <= 1 || editField}
                empty
                icon={Icons.ToolIcon}
                isWaiting={!users}
                onClick={() => setShowPopup('wizard')}
                title='Permisos globales' />
            </div>
            <div className="child auto-width">
              <Button animated
                disabled={editField}
                empty
                icon={Icons.AddIcon}
                isWaiting={!users}
                onClick={createUserBtnHandleOnClick}
                title='Nuevo usuario' />
            </div>
          </div>
          {users && prefixes && <div className="list" disabled={editField} onClick={selectorHandleOnClick}>
            {renderUserList()}
            <span className="wrapper" />
          </div>}
          {(!users || !prefixes) && <LoadingBlock noBackground />}
        </div>
      </div>
      {/* Editor */}
      <div className="user-container">
        {!currUser && <div className='no-user-selection'>
          <div className="img-container">
            <img src={Icons.AaronImg} alt="aaron" />
          </div>
          <h5>Selecciona un usuario de la lista para gestionarlo o haz clic en
            <span className="highlight"> + </span>para crear uno</h5>
        </div>}
        {currUser && <div className='user-editor'>
          <div className="basic-data-preview">
            <div className="img-container">
              <img src={currUser.getPicture().getURLData() || Icons.DefUsPic} alt="user-pic" />
              {!editField && <img className='change-toggler' src={Icons.NewPicIcon} alt="new-pic" />}
            </div>
            <div className="basic-info">
              <h2 className="highlight">@{currUser.getUsername()}</h2>
              <p className="name">{currUser.getName()}</p>
              <p className="creation-date">Creado el <span className="highlight">
                {Global.parseDateUTC(currUser.getCreationDate(), timezoneOffset)}
              </span></p>
              {Boolean(currUser.getLastSession()) && <p className="creation-date">
                Última sesión: <span className="highlight">
                  {Global.parseDateWithTimeUTC(currUser.getLastSession(), timezoneOffset)}
                </span>
              </p>}
              <div className="flex-box m3">
                <div className="child auto-width">
                  <Switch action={statusHandleOnChange}
                    disabled={editField && editField !== 'switch-status'}
                    defaultValue={currUser.getStatus() === User.STATUS_ACT}
                    forceChangeValue={currUser.getStatus() === User.STATUS_ACT}
                    placeholder={{ default: 'Activar cuenta', onChecked: 'Cuenta activa' }} />
                </div>
                <div className="child">
                  <Button disabled={editField}
                    empty
                    icon={Icons.DeleteIcon}
                    onClick={() => setShowPopup('delete')}
                    typeRender='error'
                    value='Eliminar cuenta' />
                </div>
              </div>
            </div>
          </div>
          <div className="edit-info">
            {/* Personal info */}
            <div className="box shadowless">
              <h4 className="highlight">Información básica</h4>
              {/* First name */}
              <div className="flex-box m3">
                <div className="child">
                  <Inputbar defaultValue={currUser.getFirstName()}
                    disabled={editField?.id !== 'first-name'}
                    filters={[{ regExp: Global.REGEXP_FILTER_SYMBOLS }, { regExp: /\s+/g, replace: ' ' }]}
                    forceChangeRef={editField?.id === 'first-name' && inputRollback}
                    forceChangeValue={currUser.getFirstName()}
                    isValid={input => currUser.getFirstName() !== input}
                    maxLength={50}
                    minLength={2}
                    onBlur={input => input?.trim()}
                    onChange={inputHandleOnChange}
                    onEnter={() => { }}
                    placeholder={{ default: 'Nombre(s)' }}
                    reference={refCompos.current.find(e => e.id === 'first-name').compo}
                    required={editField?.id === 'first-name'}
                    textTransform='capitalize' />
                </div>
                <div className="child auto-width">
                  <Button rounded
                    disabled={editField && (editField.id !== 'first-name' || disableSubmit)}
                    empty
                    icon={editField?.id === 'first-name' ? Icons.SaveIcon : Icons.EditIcon}
                    onClick={() => {
                      if (editField?.id === 'first-name')
                        saveFieldBtnHandleOnClick(val => currUser.setFirstName(val));
                      else
                        editFieldBtnHandleOnClick('first-name', currUser.getFirstName());
                    }}
                    reduced
                    typeRender={editField?.id === 'first-name' ? 'complete' : ''} />
                </div>
                {editField?.id === 'first-name' && <div className="child auto-width">
                  <Button rounded
                    empty
                    icon={Icons.CloseIcon}
                    onClick={cancelEditBtnHandleOnClick}
                    reduced
                    typeRender='error' />
                </div>}
              </div>
              {/* Last name */}
              <div className="flex-box m3">
                <div className="child">
                  <Inputbar defaultValue={currUser.getLastName()}
                    disabled={editField?.id !== 'last-name'}
                    filters={[{ regExp: Global.REGEXP_FILTER_SYMBOLS }, { regExp: /\s+/g, replace: ' ' }]}
                    forceChangeRef={editField?.id === 'last-name' && inputRollback}
                    forceChangeValue={currUser.getLastName()}
                    maxLength={50}
                    minLength={2}
                    onBlur={input => input?.trim()}
                    onChange={inputHandleOnChange}
                    onEnter={() => { }}
                    placeholder={{ default: 'Apellido(s)' }}
                    reference={refCompos.current.find(e => e.id === 'last-name').compo}
                    required={editField?.id === 'last-name'}
                    textTransform='capitalize' />
                </div>
                <div className="child auto-width">
                  <Button rounded
                    disabled={editField && (editField.id !== 'last-name' || disableSubmit)}
                    empty
                    icon={editField?.id === 'last-name' ? Icons.SaveIcon : Icons.EditIcon}
                    onClick={() => {
                      if (editField?.id === 'last-name')
                        saveFieldBtnHandleOnClick(val => currUser.setLastName(val));
                      else
                        editFieldBtnHandleOnClick('last-name', currUser.getLastName());
                    }}
                    reduced
                    typeRender={editField?.id === 'last-name' ? 'complete' : ''} />
                </div>
                {editField?.id === 'last-name' && <div className="child auto-width">
                  <Button rounded
                    empty
                    icon={Icons.CloseIcon}
                    onClick={cancelEditBtnHandleOnClick}
                    reduced
                    typeRender='error' />
                </div>}
              </div>
              {/* Genre */}
              <div className="flex-box m3">
                <div className="child">
                  <Selectbar defaultValue={currUser.getGenre()}
                    disabled={editField?.id !== 'genre'}
                    forceChangeRef={editField?.id === 'genre' && inputRollback}
                    forceChangeValue={editField?.id !== 'genre' ? currUser.getGenre() : undefined}
                    onChange={inputHandleOnChange}
                    options={[
                      { displayValue: 'Hombre', value: Persona.GENRE_MALE },
                      { displayValue: 'Mujer', value: Persona.GENRE_FEMALE },
                      { displayValue: 'Otro o prefiero no decirlo', value: Persona.GENRE_UNSET }
                    ]}
                    placeholder='Género'
                    reference={refCompos.current.find(e => e.id === 'genre').compo} />
                </div>
                <div className="child auto-width">
                  <Button rounded
                    disabled={editField && (editField.id !== 'genre' || disableSubmit)}
                    empty
                    icon={editField?.id === 'genre' ? Icons.SaveIcon : Icons.EditIcon}
                    onClick={() => {
                      if (editField?.id === 'genre')
                        saveFieldBtnHandleOnClick(val => currUser.setGenre(val));
                      else
                        editFieldBtnHandleOnClick('genre', currUser.getGenre());
                    }}
                    reduced
                    typeRender={editField?.id === 'genre' ? 'complete' : ''} />
                </div>
                {editField?.id === 'genre' && <div className="child auto-width">
                  <Button rounded
                    empty
                    icon={Icons.CloseIcon}
                    onClick={cancelEditBtnHandleOnClick}
                    reduced
                    typeRender='error' />
                </div>}
              </div>
              {/* Birthdate */}
              <div className="flex-box m3">
                <div className="child">
                  <Inputbar defaultValue={Global.transformDateForInput(currUser.getBirthdate() + timezoneOffset)}
                    disabled={editField?.id !== 'birthdate'}
                    forceChangeRef={editField?.id === 'birthdate' && inputRollback}
                    forceChangeValue={Global.transformDateForInput(currUser.getBirthdate() + timezoneOffset)}
                    onChange={inputHandleOnChange}
                    placeholder={{ default: 'Fecha de nacimiento' }}
                    reference={refCompos.current.find(e => e.id === 'birthdate').compo}
                    required={editField?.id === 'birthdate'}
                    type='date' />
                </div>
                <div className="child auto-width">
                  <Button rounded
                    disabled={editField && (editField.id !== 'birthdate' || disableSubmit)}
                    empty
                    icon={editField?.id === 'birthdate' ? Icons.SaveIcon : Icons.EditIcon}
                    onClick={() => {
                      if (editField?.id === 'birthdate')
                        saveFieldBtnHandleOnClick(val => currUser.setBirthdate(val));
                      else
                        editFieldBtnHandleOnClick('birthdate', Global.transformDateForInput(currUser.getBirthdate()));
                    }}
                    reduced
                    typeRender={editField?.id === 'birthdate' ? 'complete' : ''} />
                </div>
                {editField?.id === 'birthdate' && <div className="child auto-width">
                  <Button rounded
                    empty
                    icon={Icons.CloseIcon}
                    onClick={cancelEditBtnHandleOnClick}
                    reduced
                    typeRender='error' />
                </div>}
              </div>
              {/* RFC */}
              <div className="flex-box m3">
                <div className="child">
                  <Inputbar defaultValue={currUser.getRFC()}
                    disabled={editField?.id !== 'rfc'}
                    filters={[{ regExp: Global.REGEXP_FILTER_ALL_SYMBOLS }]}
                    forceChangeRef={editField?.id === 'rfc' && inputRollback}
                    forceChangeValue={currUser.getRFC()}
                    isValid={input => Global.REGEXP_RFC.test(input) && currUser.testRFC(input)}
                    maxLength={13}
                    minLength={13}
                    onBlur={input => input?.trim()}
                    onChange={inputHandleOnChange}
                    onEnter={() => { }}
                    placeholder={{ default: 'RFC', onIsValidFail: 'El RFC no coincide' }}
                    reference={refCompos.current.find(e => e.id === 'rfc').compo}
                    required={editField?.id === 'rfc'}
                    textTransform='uppercase' />
                </div>
                <div className="child auto-width">
                  <Button rounded
                    disabled={editField && (editField.id !== 'rfc' || disableSubmit)}
                    empty
                    icon={editField?.id === 'rfc' ? Icons.SaveIcon : Icons.EditIcon}
                    onClick={() => {
                      if (editField?.id === 'rfc')
                        saveFieldBtnHandleOnClick(val => currUser.setRFC(val));
                      else
                        editFieldBtnHandleOnClick('rfc', currUser.getRFC());
                    }}
                    reduced
                    typeRender={editField?.id === 'rfc' ? 'complete' : ''} />
                </div>
                {editField?.id === 'rfc' && <div className="child auto-width">
                  <Button rounded
                    empty
                    icon={Icons.CloseIcon}
                    onClick={cancelEditBtnHandleOnClick}
                    reduced
                    typeRender='error' />
                </div>}
              </div>
            </div>
            <div className="flex-box wrap m3">
              {/* Account info */}
              <div className="box shadowless">
                <h4 className="highlight">Información de la cuenta</h4>
                {/* Email */}
                <div className="flex-box m3">
                  <div className="child">
                    <Inputbar defaultValue={currUser.getEmail()}
                      disabled={editField?.id !== 'email'}
                      filters={[{ regExp: Global.REGEXP_FILTER_EMAIL }]}
                      forceChangeRef={editField?.id === 'email' && inputRollback}
                      forceChangeValue={currUser.getEmail()}
                      isValid={input => Global.REGEXP_EMAIL.test(input)}
                      maxLength={255}
                      onChange={inputHandleOnChange}
                      onEnter={() => { }}
                      placeholder={{ default: 'Correo electrónico', onIsValidFail: 'Correo inválido' }}
                      reference={refCompos.current.find(e => e.id === 'email').compo}
                      required={editField?.id === 'email'}
                      textTransform='lowercase' />
                  </div>
                  <div className="child auto-width">
                    <Button rounded
                      disabled={editField && (editField.id !== 'email' || disableSubmit)}
                      empty
                      icon={editField?.id === 'email' ? Icons.SaveIcon : Icons.EditIcon}
                      onClick={() => {
                        if (editField?.id === 'email')
                          saveFieldBtnHandleOnClick(val => currUser.setEmail(val));
                        else
                          editFieldBtnHandleOnClick('email', currUser.getEmail());
                      }}
                      reduced
                      typeRender={editField?.id === 'email' ? 'complete' : ''} />
                  </div>
                  {editField?.id === 'email' && <div className="child auto-width">
                    <Button rounded
                      empty
                      icon={Icons.CloseIcon}
                      onClick={cancelEditBtnHandleOnClick}
                      reduced
                      typeRender='error' />
                  </div>}
                </div>
                {/* Phone */}
                <div className="flex-box m3">
                  <div className="child auto-width">
                    <Selectbar disabled={editField?.id !== 'phone'}
                      defaultValue={`${currUser.getPhone().code}_${currUser.getPhone().prefix}`}
                      forceChangeRef={editField?.id === 'phone' && prefixRollback}
                      forceChangeValue={editField?.id !== 'phone'
                        ? `${currUser.getPhone().code}_${currUser.getPhone().prefix}`
                        : undefined}
                      onChange={option => phoneHandleOnChange(option, 'prefix')}
                      options={prefixes.map(p => {
                        return { displayValue: `${p.name} ${p.dial_code}`, value: `${p.code}_${p.dial_code}` }
                      })}
                      placeholder='Prefijo'
                      required={editField?.id === 'phone'}
                      width={115} />
                  </div>
                  <div className="child">
                    <Inputbar defaultValue={currUser.getPhone().number}
                      disabled={editField?.id !== 'phone'}
                      filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                      forceChangeRef={editField?.id === 'phone' && inputRollback}
                      forceChangeValue={currUser.getPhone().number}
                      inputMode='tel'
                      maxLength={10}
                      minLength={10}
                      onChange={input => phoneHandleOnChange(input, 'number')}
                      onEnter={() => { }}
                      placeholder={{ default: 'Teléfono', onMinLengthFail: 'Ingresa 10 dígitos' }}
                      reference={refCompos.current.find(e => e.id === 'phone').compo}
                      required={editField?.id === 'phone'} />
                  </div>
                  <div className="child auto-width">
                    <Button rounded
                      disabled={editField && (editField.id !== 'phone' || disableSubmit)}
                      empty
                      icon={editField?.id === 'phone' ? Icons.SaveIcon : Icons.EditIcon}
                      onClick={() => {
                        if (editField?.id === 'phone')
                          saveFieldBtnHandleOnClick(number => currUser.setPhone({ number }));
                        else
                          editFieldBtnHandleOnClick('phone', currUser.getFullPhone());
                      }}
                      reduced
                      typeRender={editField?.id === 'phone' ? 'complete' : ''} />
                  </div>
                  {editField?.id === 'phone' && <div className="child auto-width">
                    <Button rounded
                      empty
                      icon={Icons.CloseIcon}
                      onClick={cancelEditBtnHandleOnClick}
                      reduced
                      typeRender='error' />
                  </div>}
                </div>
                {/* Password change */}
                <div className="flex-box m3">
                  <Button empty
                    icon={Icons.KeyIcon}
                    onClick={() => setShowPopup('passcode')}
                    value='Cambiar contraseña' />
                </div>
              </div>
              {/* Pass info */}
              <div className="box shadowless">
                <h4 className="highlight">Permisos</h4>
                <div className="flex-box dir-column">
                  <div className="child">
                    <div className="box borderless">
                      <h5 className="overset">Cuenta</h5>
                      <div className="flex-box dir-column m3">
                        <div className="child jc-left">
                          <Switch disabled={editField && editField !== `switch-${Pass.UP_SINF}`}
                            defaultValue={currUser.getPass().getPass(Pass.UP_SINF)}
                            forceChangeValue={currUser.getPass().getPass(Pass.UP_SINF)}
                            action={newState => passHandleOnChange(newState, Pass.UP_SINF)}
                            placeholder={{ default: 'Actualizar info. personal' }} />
                        </div>
                        <div className="child jc-left">
                          <Switch disabled={editField && editField !== `switch-${Pass.UP_PICT}`}
                            defaultValue={currUser.getPass().getPass(Pass.UP_PICT)}
                            forceChangeValue={currUser.getPass().getPass(Pass.UP_PICT)}
                            action={newState => passHandleOnChange(newState, Pass.UP_PICT)}
                            placeholder={{ default: 'Actualizar foto de perfil' }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="child">
                    <div className="box borderless">
                      <h5 className="overset">Contratos</h5>
                      <div className="flex-box dir-column m3">
                        <div className="child jc-left">
                          <Switch disabled={editField && editField !== `switch-${Pass.IN_CONT}`}
                            defaultValue={currUser.getPass().getPass(Pass.IN_CONT)}
                            forceChangeValue={currUser.getPass().getPass(Pass.IN_CONT)}
                            action={newState => passHandleOnChange(newState, Pass.IN_CONT)}
                            placeholder={{ default: 'Crear contratos' }} />
                        </div>
                        <div className="child jc-left">
                          <Switch disabled={editField && editField !== `switch-${Pass.DE_CONT}`}
                            defaultValue={currUser.getPass().getPass(Pass.DE_CONT)}
                            forceChangeValue={currUser.getPass().getPass(Pass.DE_CONT)}
                            action={newState => passHandleOnChange(newState, Pass.DE_CONT)}
                            placeholder={{ default: 'Cancelar contratos' }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="child">
                    <div className="box borderless">
                      <h5 className="overset">Propiedades</h5>
                      <div className="flex-box dir-column m3">
                        <div className="child jc-left">
                          <Switch disabled={editField && editField !== `switch-${Pass.IN_ESTA}`}
                            defaultValue={currUser.getPass().getPass(Pass.IN_ESTA)}
                            forceChangeValue={currUser.getPass().getPass(Pass.IN_ESTA)}
                            action={newState => passHandleOnChange(newState, Pass.IN_ESTA)}
                            placeholder={{ default: 'Crear propiedades' }} />
                        </div>
                        <div className="child jc-left">
                          <Switch disabled={editField && editField !== `switch-${Pass.UP_ESTA}`}
                            defaultValue={currUser.getPass().getPass(Pass.UP_ESTA)}
                            action={newState => passHandleOnChange(newState, Pass.UP_ESTA)}
                            placeholder={{ default: 'Actualizar propiedades' }} />
                        </div>
                        <div className="child jc-left">
                          <Switch disabled={editField && editField !== `switch-${Pass.DE_ESTA}`}
                            defaultValue={currUser.getPass().getPass(Pass.DE_ESTA)}
                            forceChangeValue={currUser.getPass().getPass(Pass.DE_ESTA)}
                            action={newState => passHandleOnChange(newState, Pass.DE_ESTA)}
                            placeholder={{ default: 'Eliminar propiedades' }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="child">
                    <div className="box borderless">
                      <h5 className="overset">Subastas</h5>
                      <div className="flex-box dir-column m3">
                        <div className="child jc-left">
                          <Switch disabled={editField && editField !== `switch-${Pass.IN_BIDD}`}
                            defaultValue={currUser.getPass().getPass(Pass.IN_BIDD)}
                            forceChangeValue={currUser.getPass().getPass(Pass.IN_BIDD)}
                            action={newState => passHandleOnChange(newState, Pass.IN_BIDD)}
                            placeholder={{ default: 'Iniciar subastas' }} />
                        </div>
                        <div className="child jc-left">
                          <Switch disabled={editField && editField !== `switch-${Pass.DE_BIDD}`}
                            defaultValue={currUser.getPass().getPass(Pass.DE_BIDD)}
                            forceChangeValue={currUser.getPass().getPass(Pass.DE_BIDD)}
                            action={newState => passHandleOnChange(newState, Pass.DE_BIDD)}
                            placeholder={{ default: 'Finalizar subastas' }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
      {showPopup === 'create' && <CreateSubUser onHide={() => setShowPopup()}
        onUserCreation={user => setUsers([...users, user])}
        prefixes={prefixes} />}
      {showPopup === 'delete' && <Dialog
        action={() => DAOServ.post('delete_subuser', { tst: currSession.tst, iduser: currUser.getId() }, 'JSON')}
        confirmBtn={{ icon: Icons.DeleteIcon, onWaitValue: 'Eliminando...', type: 'error', value: 'Sí, eliminar' }}
        confirmImg={Icons.DeleteIcon}
        id='delete-user-dialog'
        message={`Estás a punto de realizar una acción irreversible. La cuenta de ${currUser?.getFirstName()}`
          + ' ya no será accesible de ninguna forma. Las propiedades creadas y modificadas por esta cuenta'
          + ' no se verán afectadas. ¿Quieres continuar?'}
        onHide={() => setShowPopup()}
        onReject={err => err && pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' })}
        onResolve={deleteUserHandleOnResolve}
        rejectBtn={{ value: 'No, cancelar' }}
        renderButtonsEmpty
        renderButtonsRounded
        renderButtonsSwitched />}
      {showPopup === 'passcode' && <SetField action={updatePassword}
        onHide={() => setShowPopup()}
        options={{
          confirmation: {
            default: 'Ingresa nuevamente la contraseña',
            onIsValidFail: 'La contraseña no coincide'
          }, filters: [{ regExp: Global.REGEXP_FILTER_FORBIDDEN_SYMBOLS }],
          maxLength: User.PASSWORD_MAX_LENGTH,
          minLength: User.PASSWORD_MIN_LENGTH,
          placeholder: { default: 'Nueva contraseña', onMinLengthFail: 'Ingresa de 8 a 30 caracteres' },
          required: true,
          type: 'password'
        }} hint='Ingresa una contraseña que el usuario no vaya a olvidar, pero que no sea fácil de adivinar'
        onReject={err => pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' })}
        onResolve={() => pushMessageHint({ message: 'Contraseña actualizada', type: 'completed' })} />}
      {showPopup === 'wizard' && <PassToolWizard onHide={() => setShowPopup()}
        onResolve={() => setCurrUser()}
        users={users} />}
    </div>
  );
}

export default UserManagement;